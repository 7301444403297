import {map, filter} from 'lodash';
import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  files: []
};

const slice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Files
    getFilesSuccess(state, action) {
      state.isLoading = false;
      state.files = action.payload;
    },

    // CREATE FILE
    createFilesuccess(state, action) {
      const newFile = action.payload;
      state.isLoading = false;
      state.files = [...state.files, newFile];
    },

    // UPDATE FILE
    updateFilesuccess(state, action) {
      const file = action.payload;
      const updateFile = map(state.files, (_file) => {
        if (_file.id === file.id) {
          return file;
        }
        return _file;
      });

      state.isLoading = false;
      state.files = updateFile;
    },

    // DELETE FILE
    deleteFilesuccess(state, action) {
      const {fileId} = action.payload;
      const deleteFile = filter(state.files, (user) => user.id !== fileId);
      state.isLoading = false;
      state.files = deleteFile;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFiles(search) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('file-vault', {params: {search}});
      dispatch(slice.actions.getFilesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createFile(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('file-vault/upload', payload);
      dispatch(slice.actions.createFilesuccess(response.data));

      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateFile(fileId, payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const {data} = await axios.put(`file-vault/${fileId}`, payload);
      dispatch(slice.actions.updateFilesuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteFile(fileId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.delete(`file-vault/${fileId}`);
      dispatch(slice.actions.deleteFilesuccess({fileId}));
      return result;
    } catch (error) {
      dispatch(slice.actions.hasError(error));

      return error;
    }
  };
}
