import PropTypes from 'prop-types';
import {createContext} from 'react';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';

// ----------------------------------------------------------------------

const initialState = {};

const ColumnSettingsContext = createContext(initialState);

ColumnSettingsProvider.propTypes = {
  children: PropTypes.node
};

function ColumnSettingsProvider({children}) {
  const [settings, setSettings] = useLocalStorage('columnSettings', initialState);

  const onChangeSettings = (tableKey, toChange) => {
    setSettings({
      ...settings,
      [tableKey]: {...toChange}
    });
  };

  const getColumnSettings = (tableKey) => settings[tableKey];

  const useColumnState = (tableKey) => {
    if (!settings[tableKey]) {
      setSettings({...settings, [tableKey]: {}});
    }
    const columnState = getColumnSettings(tableKey);
    const setColumnState = (toChange) => onChangeSettings(tableKey, toChange);
    return [columnState, setColumnState];
  };

  return (
    <ColumnSettingsContext.Provider
      value={{
        getColumnSettings,
        onChangeSettings,
        useColumnState
      }}
    >
      {children}
    </ColumnSettingsContext.Provider>
  );
}

export {ColumnSettingsProvider, ColumnSettingsContext};
