import {Icon} from '@iconify/react';
import {useSnackbar} from 'notistack';
import {useRef, useState} from 'react';
import briefcaseFill from '@iconify/icons-eva/briefcase-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import {useNavigate} from 'react-router-dom';
// mui
import {alpha} from '@mui/material/styles';
import {Button, Box, Divider, MenuItem, Typography} from '@mui/material';
// redux
import {useDispatch} from 'src/redux/store';
// routes
import {PATH_PAGE, PATH_DASHBOARD} from 'src/routes/paths';
// hooks
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// components
import {MAvatar, MIconButton} from 'src/components/@material-extend';
import MyAvatar from 'src/components/MyAvatar';
import MenuPopover from 'src/components/MenuPopover';
import {useSelector} from 'react-redux';
import createAvatar, {getFullName} from 'src/utils/createAvatar';

export default function AccountPopover() {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const {onToggleAdvisorView} = useSettings();
  const {enqueueSnackbar} = useSnackbar();
  const isMountedRef = useIsMountedRef();

  const [open, setOpen] = useState(false);
  const {user, logout} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const {list} = useSelector((state) => state.client);
  const selectedClients = list.filter((item) => item.show);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      dispatch({type: 'USER_LOGOUT'});
      navigate('/');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', {variant: 'error'});
    }
  };

  const handleClick = (item) => {
    const showClientData = isAdvisor && selectedClients.length === 1;

    if (showClientData) {
      onToggleAdvisorView(false);
      navigate(item.linkTo, {state: 'license'});
    } else {
      onToggleAdvisorView(true);
      navigate(item.linkTo, {state: item.state});
    }

    handleClose();
  };

  // ----------------------------------------------------------------------

  const MENU_OPTIONS = [
    {
      label: isAdvisor
        ? selectedClients.length === 1
          ? `${getFullName(selectedClients?.[0])} - Personal Info`
          : 'Advisor Settings'
        : `Account`,
      icon: personFill,
      linkTo: PATH_DASHBOARD.user,
      state: {tab: 'general'}
    },
    {
      label: 'Billing',
      icon: briefcaseFill,
      linkTo: PATH_DASHBOARD.user,
      state: {tab: 'billing'}
    },
    {
      label: 'Support',
      icon: settings2Fill,
      linkTo: PATH_PAGE.faqs
    }
  ];

  // ----------------------------------------------------------------------

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        {selectedClients.length === 1 ? (
          <MAvatar
            src={selectedClients?.[0]?.avatar}
            alt={selectedClients?.[0]?.firstName}
            color={
              selectedClients?.[0]?.avatar
                ? 'default'
                : createAvatar({firstName: selectedClients?.[0]?.firstName, lastName: selectedClients?.[0]?.lastName})
                    .color
            }
            sx={{boxShadow: '0px 2px 10px 2px rgb(0 0 0 / 30%)', border: '1px solid white'}}
          >
            {createAvatar({firstName: selectedClients?.[0]?.firstName, lastName: selectedClients?.[0]?.lastName}).name}
          </MAvatar>
        ) : (
          <MyAvatar />
        )}
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{width: 260}}>
        <Box sx={{my: 1.5, px: 2.5}}>
          <Typography variant="subtitle1" noWrap>
            {selectedClients.length === 1
              ? `${getFullName(selectedClients?.[0])}`
              : `${user.firstName} ${user.lastName}`}
          </Typography>
          <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
            {selectedClients.length === 1 ? `${selectedClients?.[0]?.email}` : `${user.email}`}
          </Typography>
        </Box>

        <Divider sx={{my: 1}} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem key={option.label} onClick={() => handleClick(option)} sx={{typography: 'body2', py: 1, px: 2.5}}>
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />
            {option.label}
          </MenuItem>
        ))}

        <Box sx={{p: 2, pt: 1.5}}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
