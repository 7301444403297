import {format, formatDistanceToNow, differenceInDays} from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  if (!date) return 'N/A';

  const dt = typeof date === 'string' ? new Date(date) : date;

  return format(dt, 'MM/dd/yyyy');
}

export function fDateZeroTime(date) {
  return date instanceof Date && !isNaN(date) ? new Date(date.setHours(0, 0, 0, 0)) : date;
}

export function fDateTime(date, fm = 'MM/dd/yyyy HH:mm') {
  return format(new Date(date), fm);
}
export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function diff(date1) {
  return differenceInDays(new Date(date1), new Date());
}
