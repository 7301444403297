import NumberFormat from 'react-number-format';
import {TextField} from '@mui/material';

export default function NumberFormatCustom(props) {
  const {
    onChange = function () {},
    required,
    value,
    label,
    name,
    disabled,
    fullWidth,
    placeholder,
    error,
    helperText
  } = props;

  return (
    <NumberFormat
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            values
          }
        });
      }}
      required={required}
      value={value}
      customInput={TextField}
      thousandSeparator
      prefix={props.prefix || ''}
      suffix={props.suffix || ''}
      label={label}
      disabled={disabled}
      fullWidth={fullWidth}
      placeholder={placeholder}
      error={error}
      helperText={helperText}
    />
  );
}
