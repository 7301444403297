import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
// mui
import {Stack, Typography, ToggleButtonGroup, ToggleButton, Tooltip} from '@mui/material';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// hook
import useAuth from 'src/hooks/useAuth';
// component
import SwitchAccountView from 'src/components/_dashboard/common/SwitchAccountView';
// -----------------------------------------------------------------------------

ChangeViewMode.propTypes = {
  contact: PropTypes.bool,
  label: PropTypes.string,
  mode: PropTypes.string,
  changeMode: PropTypes.func.isRequired,
  view: PropTypes.string,
  changeView: PropTypes.func
};

export default function ChangeViewMode({contact = false, label, mode = 'table', changeMode, view, changeView}) {
  const {user} = useAuth();
  const isAdvisor = user && user.role === 'Advisor';

  const {members} = useSelector((state) => state.familyGroup);
  const membersSelected = members.filter((item) => item.show && item.id !== user.userId).length;

  return (
    <Stack direction="row" sx={{justifyContent: 'space-between'}}>
      {label && <Typography variant="h3">{label}</Typography>}
      <Stack direction="row" spacing={1}>
        {!contact && (
          <>
            <ToggleButtonGroup value={mode} exclusive onChange={(event, mode) => !!mode && changeMode(mode)}>
              <ToggleButton value="gallery">
                <Tooltip title="Gallery">
                  <ViewModuleIcon fontSize={window.innerWidth > 600 ? 'medium' : 'small'} />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="table">
                <Tooltip title="Table">
                  <ViewListIcon fontSize={window.innerWidth > 600 ? 'medium' : 'small'} />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={(event, nextView) => !!nextView && changeView(nextView)}
            >
              <ToggleButton value="show">
                <Tooltip title="Show">
                  <VisibilityIcon fontSize={window.innerWidth > 600 ? 'medium' : 'small'} />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="hide">
                <Tooltip title="Hide">
                  <VisibilityOffIcon fontSize={window.innerWidth > 600 ? 'medium' : 'small'} />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </>
        )}
        {
          <Stack direction="row" spacing={1}>
            {contact && (
              <ToggleButtonGroup value={mode} exclusive onChange={(event, mode) => !!mode && changeMode(mode)}>
                <ToggleButton value="trusted">
                  <Tooltip title="Trusted">
                    <HealthAndSafetyIcon fontSize={window.innerWidth > 600 ? 'medium' : 'small'} />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="table">
                  <Tooltip title="Table">
                    <ViewListIcon fontSize={window.innerWidth > 600 ? 'medium' : 'small'} />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            {(isAdvisor || membersSelected > 0) && contact && <SwitchAccountView />}
          </Stack>
        }
      </Stack>
    </Stack>
  );
}
