import React, {useState} from 'react';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
// mui
import {
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Typography,
  Box
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// redux
import {useDispatch} from 'src/redux/store';
import {getFamilyData} from 'src/redux/slices/family';
// components
import {FormikErrorNotification} from 'src/components/_dashboard/common';
// utils
import {fToNow} from 'src/utils/formatTime';
// icons
import {Icon} from '@iconify/react';
import clockFill from '@iconify/icons-eva/clock-fill';
import {respondToInvite} from 'src/redux/slices/familyGroup';
import Label from 'src/components/Label';
// theme
import {useTheme} from '@mui/material/styles';
import useAuth from 'src/hooks/useAuth';
import {respondToContactInvite} from 'src/redux/slices/contact';
import {verifyEmailAddress} from 'src/api/profile';
// ----------------------------------------------------------------------

NotificationDialog.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  notification: PropTypes.object,
  refreshNotifications: PropTypes.func
};

export default function NotificationDialog({handleClose, open, notification, refreshNotifications}) {
  const theme = useTheme();
  const isLight = theme?.palette?.mode === 'light';
  const {user} = useAuth();

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(null);

  const type = notification?.type;
  const acceptanceStatus = notification?.meta?.acceptanceStatus;

  const ChoiceSchema = Yup.object().shape({
    familyGroupId: Yup.string().required('Family group id not found'),
    acceptStatus: Yup.bool().required('Accept status not found')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      familyGroupId:
        (type === 'inviteContact' ? notification?.meta?.contactInviteId : notification?.meta?.familygroupId) || null,
      acceptStatus: null
    },
    validationSchema: type === 'verify' ? null : ChoiceSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        setSubmitting(true);
        let res = null;
        if (type === 'inviteContact') {
          res = await dispatch(respondToContactInvite(values.familyGroupId, values.acceptStatus));
        } else if (type === 'invite') {
          res = await dispatch(respondToInvite(false, values, user.userId));
        } else if (type === 'verify') {
          res = await verifyEmailAddress();
        }

        setSubmitting(false);

        if (res && res?.data) {
          enqueueSnackbar(
            type === 'verify'
              ? `Verfication Link Sent`
              : `Invite ${values.acceptStatus ? 'Accepted' : 'Declined'} successfully.`,
            {
              variant: 'success'
            }
          );
          handleClose();
          refreshNotifications();
          dispatch(getFamilyData());
        } else {
          enqueueSnackbar('An error occured.', {variant: 'error'});
        }

        setLoading(null);
        resetForm();
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setLoading(null);
      }
    }
  });

  const {setFieldValue, handleSubmit, isSubmitting} = formik;

  const handleChoice = async (acceptStatus) => {
    setLoading(acceptStatus);
    await setFieldValue('acceptStatus', acceptStatus);
    handleSubmit();
  };

  return (
    <FormikProvider value={formik}>
      <FormikErrorNotification />
      <Form>
        <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
          <Stack sx={{display: 'flex', flexDirection: 'row', width: '100%', mb: 2}}>
            <DialogTitle>{notification?.title}</DialogTitle>
            <Typography
              variant="caption"
              sx={{
                mt: 4,
                mr: 4,
                marginLeft: 'auto',
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled',
                textTransform: 'capitalize',
                cursor: 'default'
              }}
            >
              <Box component={Icon} icon={clockFill} sx={{mr: 0.5, width: 16, height: 16}} />
              {fToNow(notification?.createdAt || new Date())}
            </Typography>
          </Stack>

          <Divider />
          <DialogContent>
            <Typography sx={{cursor: 'default'}}>{notification?.description}</Typography>
          </DialogContent>

          {/* show accept/decline/close buttons */}
          <DialogActions>
            {['inviteContact', 'invite', 'verify'].includes(type) &&
            (acceptanceStatus === 'pending' || !acceptanceStatus) ? (
              type === 'verify' ? (
                <LoadingButton
                  variant="contained"
                  onClick={() => handleChoice(true)}
                  loading={loading === true}
                  disabled={isSubmitting || loading !== null}
                >
                  Verify
                </LoadingButton>
              ) : (
                <>
                  <LoadingButton
                    variant="contained"
                    color="error"
                    onClick={() => handleChoice(false)}
                    loading={loading === false}
                    disabled={isSubmitting || loading !== null}
                  >
                    Decline
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    onClick={() => handleChoice(true)}
                    loading={loading === true}
                    disabled={isSubmitting || loading !== null}
                  >
                    Accept
                  </LoadingButton>
                </>
              )
            ) : (
              <Stack direction={'row'} width={'100%'}>
                {['inviteContact', 'invite'].includes(type) && (
                  <Label
                    variant={isLight ? 'ghost' : 'filled'}
                    color={acceptanceStatus === 'accepted' ? 'success' : 'error'}
                    sx={{
                      textTransform: 'capitalize',
                      marginRight: 'auto',
                      marginBottom: 'auto',
                      marginTop: 'auto'
                    }}
                  >
                    {acceptanceStatus}
                  </Label>
                )}
                <Button onClick={handleClose} variant="contained" sx={{marginLeft: 'auto'}}>
                  Close
                </Button>
              </Stack>
            )}
          </DialogActions>
        </Dialog>
      </Form>
    </FormikProvider>
  );
}
