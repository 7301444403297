import React, {useMemo} from 'react';
// mui
import {Autocomplete, Box, Checkbox, Stack, TextField, Tooltip} from '@mui/material';
// hooks
import useAuth from 'src/hooks/useAuth';
// redux
import {useSelector} from 'src/redux/store';
// utils
import {getFullName} from 'src/utils/createAvatar';
import {MIconButton} from 'src/components/@material-extend';
import DeleteIcon from '@mui/icons-material/Delete';
// constant
import {FAM_ROLES} from 'src/constants/user';

// ----------------------------------------------------------------------

const ContactSelect = ({members, isUserSuperAdmin, isEdit, member, index, handleMemberChange, handleMemberDelete}) => {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';
  const {isLoading: cLoading, contacts} = useSelector((state) => state.contact);
  const {contacts: advisorContacts, list} = useSelector((state) => state.client);
  const selectedClient = list.find((client) => client.show);

  const contactList = useMemo(() => {
    let data = isAdvisor
      ? [
          {...selectedClient, contactType: 'individual'},
          ...advisorContacts.filter((item) => item.ownerId === selectedClient.id)
        ]
      : [
          {
            id: 'self',
            userId: 'self',
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            contactType: 'individual'
          },
          ...contacts
        ];

    const advisorContactType = ['individual', 'family'];

    return data
      .filter(
        (item) =>
          advisorContactType.includes(item.contactType) && (item.id === 'self' || item.acceptStatus === 'accepted')
      )
      .map((item) => ({...item, userId: item.userId || item.id}));
  }, [contacts, user, isAdvisor, advisorContacts, selectedClient]);

  const getLabel = (val) => {
    const value = val?.value ?? val;
    const label = FAM_ROLES.find((role) => role.value === value)?.label;
    return label;
  };

  const currentUserPermission = members.find((m) => m?.id === user?.userId)?.permission;
  const isUserAdmin = currentUserPermission === 'admin';
  const isMemberAdmin = member?.permission === 'admin';

  const isReadOnly =
    index === 0 || (isEdit ? (isUserSuperAdmin ? false : isUserAdmin ? (isMemberAdmin ? true : false) : true) : false);
  const isReadOnlyDelete =
    index === 0 || (isEdit ? (isUserSuperAdmin ? false : isUserAdmin ? (isMemberAdmin ? true : false) : true) : false);
  const isReadOnlyRole = isEdit
    ? isUserSuperAdmin
      ? false
      : isUserAdmin
      ? isMemberAdmin
        ? true
        : false
      : true
    : false;

  return (
    <Stack spacing={1} mt={3} direction={'row'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      {/* members */}
      <Stack sx={{width: isAdvisor ? '48%' : '30%'}}>
        <Autocomplete
          readOnly={isReadOnly}
          disabled={isReadOnly}
          fullWidth
          onKeyPress={(e) => {
            e.preventDefault();
          }}
          loading={cLoading}
          options={contactList?.filter((c) => !members.some((m) => m.id === c.userId))}
          getOptionLabel={(option) => getFullName(option, true)}
          value={
            contactList.find((item) =>
              member?.id === user.userId ? item?.userId === 'self' : item?.userId === member?.id
            ) ??
            (isEdit && !!member?.id && {firstName: 'Unknown'})
          }
          onChange={(e, value) => handleMemberChange(index, 'id', value?.userId)}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {' '}
                {getFullName(option, true)}
              </li>
            );
          }}
          renderInput={(params) => <TextField {...params} label={'Members'} />}
        />
      </Stack>

      {/* permission */}
      <Stack sx={{width: isAdvisor ? '48%' : '30%'}}>
        <Autocomplete
          readOnly={isReadOnly}
          disabled={isReadOnly}
          onKeyPress={(e) => {
            e.preventDefault();
          }}
          fullWidth
          loading={cLoading}
          options={isEdit && !isUserSuperAdmin ? ['member'] : ['admin', 'member']}
          getOptionLabel={(option) => option?.charAt(0).toUpperCase() + option?.slice(1)}
          value={member?.permission}
          onChange={(e, value) => handleMemberChange(index, 'permission', value)}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option?.charAt(0).toUpperCase() + option?.slice(1)}
              </li>
            );
          }}
          renderInput={(params) => <TextField {...params} label={'Permission'} />}
        />
      </Stack>

      {/* role */}
      <Stack hidden={isAdvisor} sx={{width: isAdvisor ? '0%' : '34%'}}>
        <Autocomplete
          readOnly={isReadOnlyRole}
          disabled={isReadOnlyRole}
          hidden={isAdvisor}
          onKeyPress={(e) => {
            e.preventDefault();
          }}
          fullWidth
          multiple
          loading={cLoading}
          options={FAM_ROLES.map((role) => role?.value)}
          value={member?.role}
          getOptionLabel={(option) => getLabel(option)}
          onChange={(e, value) => {
            handleMemberChange(index, 'role', value);
          }}
          renderOption={(props, option, {selected}) => {
            return (
              <li {...props} key={option.id}>
                <Checkbox checked={selected} />
                {getLabel(option)}
              </li>
            );
          }}
          renderInput={(params) => <TextField {...params} label={'Role'} />}
        />
      </Stack>

      {/* delete */}
      <Stack sx={{width: '4%'}}>
        <Tooltip title={isReadOnlyDelete ? 'No access' : 'Remove member'}>
          <Box>
            <MIconButton onClick={() => handleMemberDelete(index)} disabled={isReadOnlyDelete}>
              <DeleteIcon color={isReadOnlyDelete ? '' : 'error'} sx={{height: 20}} />
            </MIconButton>
          </Box>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default ContactSelect;
