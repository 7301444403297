import {Outlet, useLocation} from 'react-router-dom';
// components
import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const {pathname} = useLocation();

  return (
    <>
      <MainNavbar reverseMode={pathname === '/'} />
      <div>
        <Outlet />
      </div>
      <MainFooter />
    </>
  );
}
