const attributesByType = {
  realEstate: ['street', 'apt', 'city', 'state', 'zipCode'],
  collection: ['description'],
  automobile: ['year', 'make', 'amModel'],
  watercraft: ['year', 'maker', 'wcModel'],
  aircraft: ['year', 'maker', 'acModel'],
  equine: ['name', 'breed', 'regNumber'],
  board: ['name', 'industry'],
  entity: ['name', 'entitiesType'],
  club: ['name', 'website'],
  trust: ['name', 'entityType'],
  insurance: ['policyNumber', 'premium', 'carrier', 'broker']
};

export const getNickName = (data, type) => {
  if (!!data.nickName) {
    return data.nickName;
  }

  if (type === 'collection') {
    // NOTE: if asset/collection
    return data.description.split(' ').slice(0, 2).join(' ');
  }

  const tokens = attributesByType[type].map((field) => data[field]);
  return tokens.filter((t) => !!t).join(' ');
};
