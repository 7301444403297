import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import {Link as RouterLink} from 'react-router-dom';
// mui
import {alpha, styled} from '@mui/material/styles';
import {Box, Stack, AppBar, Toolbar, Button, IconButton} from '@mui/material';
// hooks
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
// routes
import {PATH_PAGE, PATH_DASHBOARD} from 'src/routes/paths';
// components
import {MHidden} from 'src/components/@material-extend';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({theme}) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({onOpenSidebar}) {
  const {isCollapse} = useCollapseDrawer();

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: {lg: `calc(100% - ${COLLAPSE_WIDTH}px)`}
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{mr: 1, color: 'text.primary'}}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <Searchbar />
        <Box sx={{flexGrow: 1}} />
        <Stack direction="row" alignItems="center" spacing={{xs: 0.5, sm: 1.5}}>
          <MHidden width="smDown">
            <Button to={PATH_PAGE.about} component={RouterLink}>
              About
            </Button>
            <Button to={PATH_PAGE.contact} component={RouterLink}>
              Contact
            </Button>
            <Button to={PATH_PAGE.faqs} component={RouterLink}>
              FAQ
            </Button>
            <Button to={PATH_DASHBOARD.blog.root} component={RouterLink}>
              Latest
            </Button>
          </MHidden>
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
