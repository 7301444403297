import {createContext, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
// utils
import axios from 'src/utils/axios';
import {isValidToken, setSession} from 'src/utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const {isAuthenticated, user} = action.payload;
    return {
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const {user} = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const {user} = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  UPDATE_PROFILE: (state, action) => {
    const newData = action.payload;
    const profile = {...state.user, ...newData};
    window.localStorage.setItem('profile', JSON.stringify(profile));

    return {
      ...state,
      user: profile
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  createSubscription: () => Promise.resolve(),
  updateProfileAvatar: () => {}
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const localProfile = window.localStorage.getItem('profile');

        if (accessToken && isValidToken(accessToken) && localProfile) {
          const user = JSON.parse(localProfile);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (payload) => {
    const response = await axios.post('auth/login', payload);
    const {token, profile} = response.data;
    const user = {
      ...profile,
      id: profile.userId
    };

    setSession(token, profile);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const register = async (data, query) => {
    const response = await axios.post('user/signup', data, {params: query});
    const {token, profile} = response.data;
    const user = {
      ...profile,
      paid: false,
      id: profile.userId,
      country: null,
      facebookUrl: '',
      instagramUrl: '',
      linkedinUrl: '',
      twitterUrl: ''
    };

    window.localStorage.setItem('accessToken', token);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null, null);
    dispatch({type: 'LOGOUT'});
  };

  const resetPassword = async (data) => {
    await axios.put('auth/reset-password', data);
  };

  const updateProfile = async (data) => {
    const response = await axios.put('profile/general-info', data);
    const profile = response.data;

    dispatch({
      type: 'UPDATE_PROFILE',
      payload: profile
    });
  };

  const updateProfileFamily = (data) => {
    dispatch({
      type: 'UPDATE_PROFILE',
      payload: data
    });
  };

  const updateProfileAvatar = (url) => {
    dispatch({
      type: 'UPDATE_PROFILE',
      payload: {avatar: url}
    });
  };

  const updatePaidField = () => {
    dispatch({
      type: 'UPDATE_PROFILE',
      payload: {paid: true}
    });
  };

  const createSubscription = async (payload) => {
    try {
      const {data} = await axios.post('payment/subscription', payload);
      if (data.status === 'active') {
        dispatch({
          type: 'UPDATE_PROFILE',
          payload: {paid: true}
        });
      }
      return {};
    } catch (err) {
      return err;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        updateProfileFamily,
        createSubscription,
        updateProfileAvatar,
        updatePaidField
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export {AuthContext, AuthProvider};
