import React from 'react';
// mui
import {Stack, ToggleButton, ToggleButtonGroup, Tooltip} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// hooks
import useSettings from 'src/hooks/useSettings';
import useAuth from 'src/hooks/useAuth';

const SwitchAccountView = () => {
  const {advisorView, onToggleAdvisorView} = useSettings();
  const {user} = useAuth();
  const isAdvisor = user && user.role === 'Advisor';

  return (
    <Stack direction="row" spacing={1} sx={{mb: 5}}>
      <ToggleButtonGroup
        value={advisorView ? 'advisor' : 'client'}
        exclusive
        onChange={(event, view) => {
          if (view === 'advisor') onToggleAdvisorView(true);
          if (view === 'client') onToggleAdvisorView(false);
        }}
      >
        <ToggleButton value="advisor">
          <Tooltip title={isAdvisor ? 'Advisor' : 'Client'}>
            <ManageAccountsIcon fontSize={window.innerWidth > 600 ? 'medium' : 'small'} />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="client">
          <Tooltip title={isAdvisor ? 'Client' : 'Member'}>
            <PersonIcon fontSize={window.innerWidth > 600 ? 'medium' : 'small'} />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default SwitchAccountView;
