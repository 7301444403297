import React from 'react';
import PropTypes from 'prop-types';
// redux
import {useSelector} from 'src/redux/store';
// components
import BDialog from './components/Boards/AddNew';
import EDialog from './components/Entities/AddNew';
import TDialog from './components/Trusts/AddNew';
import CDialog from './components/Clubs/AddNew';
import useAuth from 'src/hooks/useAuth';
// ----------------------------------------------------------------------

DashboardDialog.propTypes = {
  tab: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

export default function DashboardDialog({tab, open, handleClose}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  // NOTE: advisor stuff
  const {list} = useSelector((state) => state.client);
  const {members} = useSelector((state) => state.familyGroup);

  const clientId = (isAdvisor ? list : members).find((item) => item.show)?.id || '';
  const renderContent = () => {
    if (tab === 'board') {
      return <BDialog clientId={clientId} open={open} handleClose={handleClose} />;
    } else if (tab === 'entity') {
      return <EDialog clientId={clientId} open={open} handleClose={handleClose} />;
    } else if (tab === 'trust') {
      return <TDialog clientId={clientId} open={open} handleClose={handleClose} />;
    } else if (tab === 'club') {
      return <CDialog clientId={clientId} open={open} handleClose={handleClose} />;
    }

    return null;
  };

  return renderContent();
}
