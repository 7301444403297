import {useDispatch as useReduxDispatch, useSelector as useReduxSelector} from 'react-redux';
import {configureStore, getDefaultMiddleware, createSelector} from '@reduxjs/toolkit';
// reducer
import {rootReducer} from './rootReducer';

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  }),
  devTools: process.env.NODE_ENV !== 'production'
});

const useSelector = useReduxSelector;
const useDispatch = () => useReduxDispatch();

export {store, useSelector, useDispatch, createSelector};
