import React, {useState} from 'react';
import PropTypes from 'prop-types';
// mui
import {Box, Card, CardHeader, CardContent, FormControlLabel, Grid, Stack, Switch} from '@mui/material';
// components
import EmptyContent from 'src/components/EmptyContent';
import LabelWidget from 'src/components/_dashboard/common/LabelWidget';
import LCChart from '../common/LCChart';
import AnnualDPChart from './common/AnnualDPChart';
import Gallery from './components/Collection/Gallery';
import Table from './components/Collection/Table';
import ComingSoonCover from 'src/components/ComingSoonCover';
import FullScreenChart from '../common/FullScreenChart';
// utils
import {showOnlyActiveLabels} from 'src/utils/showOnlyActiveLabels';
import {isNullOrEmpty} from 'src/utils/checkEmpty';
// ----------------------------------------------------------------------

Collection.propTypes = {
  mode: PropTypes.string,
  view: PropTypes.string,
  data: PropTypes.array
};

const labels = [
  'Jewelry',
  'Fine Art',
  'Wine & Spirits',
  'Musical Instruments',
  'Coins  & Collectibles',
  'Furs',
  'Silver & Porcelain',
  'Other'
];

export default function Collection({mode, view = 'show', data = [], chartData}) {
  const [viewAll, setViewAll] = useState(false);

  const getValidData = () => {
    return viewAll ? data : data.filter((item) => item.status === 'active');
  };

  const chart1Data = [
    chartData.insuranceValueByClass ? chartData.insuranceValueByClass['jewelry-watches'] || 0 : 0,
    chartData.insuranceValueByClass ? chartData.insuranceValueByClass['art-antiques'] || 0 : 0,
    chartData.insuranceValueByClass ? chartData.insuranceValueByClass['wine-spirits'] || 0 : 0,
    chartData.insuranceValueByClass ? chartData.insuranceValueByClass['musical-instruments'] || 0 : 0,
    chartData.insuranceValueByClass ? chartData.insuranceValueByClass['coins-collectibles'] || 0 : 0,
    chartData.insuranceValueByClass?.furs || 0,
    chartData.insuranceValueByClass ? chartData.insuranceValueByClass['silver-porcelain'] || 0 : 0,
    chartData.insuranceValueByClass?.other || 0
  ];
  const customChartData = showOnlyActiveLabels(labels, chart1Data);

  let yearData = [];
  let annualPData = [];
  let aggregateCValue = [];
  const {annualPremium = [], aggregateCollectionValue = []} = chartData;

  annualPremium.forEach((item) => {
    yearData.push(item.year);
  });
  aggregateCollectionValue.forEach((item) => {
    if (!yearData.includes(item.year)) yearData.push(item.year);
  });
  yearData.sort();

  yearData.forEach((year) => {
    const targetPData = annualPremium.find((item) => item.year === year);
    const targetVData = aggregateCollectionValue.find((item) => item.year === year);
    annualPData.push(targetPData?.value || 0);
    aggregateCValue.push(targetVData?.value || 0);
  });

  const renderData = () =>
    mode === 'gallery' ? (
      <Gallery data={data} />
    ) : (
      <Box sx={{minHeight: 360}}>
        <FormControlLabel
          sx={{position: 'absolute', top: '46px', right: '46px', zIndex: 999}}
          control={<Switch size="small" checked={viewAll} onChange={(event) => setViewAll(event.target.checked)} />}
          label={viewAll?'View Active':'View All'}
          labelPlacement="start"
        />
        <Table data={getValidData()} />
      </Box>
    );

  return (
    <Stack spacing={2}>
      {view === 'show' && (
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{m: 1, height: 'calc(100% - 12px)', justifyContent: 'space-between'}}>
              <LabelWidget
                data={chartData.totalAnnualPremium}
                label="Total Annual Premium"
                type="currency"
                sx={{height: '30%'}}
              />
              <LabelWidget
                data={chartData.totalInsurancePolicies}
                label="Total Insurance Policies"
                sx={{height: '30%'}}
              />
              <LabelWidget data={chartData.totalCoveredRisks} label="Total Covered Risks" sx={{height: '30%'}} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{m: 1, height: 'calc(100% - 12px)', display: 'flex', flexDirection: 'column'}}>
              <CardHeader title="Insurance Values by Class" />
              <Box sx={{flex: 1}}>
                <Box sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  {isNullOrEmpty([customChartData.customDataChart]) && (
                    <ComingSoonCover noData={true} style={{marginTop: 35}} />
                  )}
                  <LCChart label={customChartData.customLabelsChart} data={customChartData.customDataChart} />
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{m: 1, height: 'calc(100% - 12px)', display: 'flex', flexDirection: 'column', overflow: 'visible'}}
            >
              <CardHeader title="Annual Premium" />
              <Box sx={{flex: 1}}>
                <Box height={'100%'}>
                  {isNullOrEmpty([annualPData, aggregateCValue]) && (
                    <ComingSoonCover noData={true} style={{marginTop: 35}} />
                  )}
                  <FullScreenChart>
                    <AnnualDPChart
                      categories={yearData}
                      label={['Annual Premium', 'Aggregate Collection Value']}
                      data={[annualPData, aggregateCValue]}
                    />
                  </FullScreenChart>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
      <Card>
        <CardContent>{data.length > 0 ? renderData() : <EmptyContent />}</CardContent>
      </Card>
    </Stack>
  );
}
