import {merge} from 'lodash';
import ReactApexChart from 'react-apexcharts';
// components
import BaseOptionChart from './BaseOptionChart';
import {isNullOrEmpty} from 'src/utils/checkEmpty';
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

const CHART_DATA = [10, 10];
const CHART_LABEL = ['Series-1', 'Series-2'];

export default function LCChart({label, data, type}) {
  const isDataEmpty = isNullOrEmpty(data);
  const inputData = isDataEmpty ? CHART_DATA : data;
  const inputLabel = isDataEmpty ? CHART_LABEL : label;

  const chartOptions = merge(BaseOptionChart(type), {
    labels: [...inputLabel],
    stroke: {show: false},
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          ...(isDataEmpty && {
            labels: {
              show: false,
              total: false
            }
          })
        }
      }
    }
  });

  return (
    <Box
      sx={{
        ...(isDataEmpty && {filter: 'grayscale(90%)', opacity: 0.2})
      }}
    >
      <ReactApexChart type="donut" series={[...inputData]} options={chartOptions} height={320} />
    </Box>
  );
}
