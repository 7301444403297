import React, {useRef, useState, useEffect} from 'react';
// mui
import {alpha, styled, useTheme} from '@mui/material/styles';
import {
  Button,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  CircularProgress,
  TextField,
  Switch
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// mui icons
import {ChevronRight} from '@mui/icons-material';
// mui icons
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
// redux
import {useDispatch, useSelector} from 'src/redux/store';
import {addFavoriteMember, getMembers} from 'src/redux/slices/familyGroup';
import {updateMembers} from 'src/redux/slices/familyGroup';
// utils
import {getFullName} from 'src/utils/createAvatar';
import {searchByName} from 'src/utils/searchByName';
// components
import UserAvatar from 'src/components/UserAvatar';
import MenuPopover from 'src/components/MenuPopover';
import EmptyContent from 'src/components/EmptyContent';
import {MAvatar} from 'src/components/@material-extend';
import {TreeItem, TreeView, treeItemClasses} from '@mui/lab';
import Label from 'src/components/Label';
// hooks
import useAuth from 'src/hooks/useAuth';
// ----------------------------------------------------------------------

const StyledTreeItem = styled(TreeItem)(({theme}) => ({
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2)
    }
  }
}));

const AccountStyle = styled('div')(({theme}) => ({
  height: '76px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

function MemberPopover() {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const {isLoading, familyGroups, members: allMembers} = useSelector((state) => state.familyGroup);

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const {user} = useAuth();
  const [showFavorites, setShowFavorites] = useState(false);
  const [searchTerm, setsearchTerm] = useState('');
  const list = allMembers?.filter?.(
    (client) => searchByName(searchTerm, client) && (showFavorites === true ? client?.isFavorite : true)
  );

  // get role
  const getRole = (id) => {
    const group = familyGroups?.find?.((f) => f?.id === id);
    const isSuperAdmin = !!group?.isSuperAdmin;
    const isAdmin = group?.members?.find((m) => m?.id === user?.userId)?.permission === 'admin';

    return {isSuperAdmin, isAdmin};
  };

  // familyGroups after grouping
  const groupedList = {};
  list.forEach((client) => {
    if (client?.familyGroup?.length === 0) {
      const groupName = 'No Family Group';
      const groupId = 'no-family-group';

      if (!groupedList[groupId]) {
        groupedList[groupId] = {
          groupName,
          clients: [],
          isSuperAdmin: getRole(groupId)?.isSuperAdmin,
          isAdmin: getRole(groupId)?.isAdmin
        };
      }
      groupedList[groupId].clients.push(client);
    } else {
      client?.familyGroup?.forEach((group) => {
        const groupName = group.groupName;
        const groupId = group.id;

        if (!groupedList[groupId]) {
          groupedList[groupId] = {
            groupName,
            clients: [],
            isSuperAdmin: getRole(groupId)?.isSuperAdmin,
            isAdmin: getRole(groupId)?.isAdmin
          };
        }
        groupedList[groupId].clients.push(client);
      });
    }
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getMembers());
  }, [dispatch, user]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getShowStatus = (id) => {
    const client = list.find((item) => item.id === id);
    return client && client.show;
  };

  const getAllShowStatus = () => {
    return list.every((item) => item.show);
  };

  const handleConnectedMember = (flag, id) => {
    let newMembers = allMembers.map((item) => (item.id === id ? {...item, show: flag} : item));
    dispatch(updateMembers(newMembers));
  };

  const handleAllMember = (flag) => {
    if (!allMembers?.length) return;
    let newMembers = allMembers.map((item) => ({...item, show: flag}));
    dispatch(updateMembers(newMembers));
  };

  const activeMembers = list.filter((item) => item.show);
  const firstActiveMember = activeMembers?.[0];

  // get group status
  const getGroupShowStatus = (id) => {
    let groupMembers = list.filter((item) => item?.familyGroup?.some?.((group) => group.id === id));
    return groupMembers.every((item) => item.show);
  };

  // handle group
  const handleGroup = (flag, id) => {
    let newMembers = allMembers.map((item) => {
      const hasGroup = item.familyGroup.some((group) => group.id === id);
      return hasGroup ? {...item, show: flag} : {...item};
    });

    dispatch(updateMembers(newMembers));
  };

  // manage name length
  const manageName = (str, len = 20) => {
    return str.length > len ? str.slice(0, len) + '...' : str;
  };

  // handle favorite
  const handleFavoriteClick = (flag, id) => {
    let newMembers = allMembers.map((item) => (item.id === id ? {...item, isFavorite: flag} : item));
    let membersData = {members: newMembers?.map((m) => ({id: m?.id, isFavorite: m?.isFavorite}))};

    dispatch(addFavoriteMember(membersData, newMembers));
  };

  return (
    familyGroups &&
    familyGroups?.length > 0 && (
      <>
        <Button
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            mt: 0,
            p: 0,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '12px',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[800], 0.2)
              }
            })
          }}
        >
          <AccountStyle>
            {activeMembers.length === 1 ? (
              <>
                <Stack direction="row">
                  <UserAvatar userId={activeMembers[0].id} isMember user={firstActiveMember} />
                  <Box sx={{ml: 2, textAlign: 'left'}}>
                    <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                      {firstActiveMember && firstActiveMember?.firstName + ' ' + firstActiveMember?.lastName}
                    </Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                      Member
                    </Typography>
                  </Box>
                </Stack>
              </>
            ) : (
              <Stack direction="row">
                <MAvatar
                  color="primary"
                  sx={{boxShadow: '0px 2px 10px 2px rgb(0 0 0 / 30%)', border: '1px solid white'}}
                >
                  {activeMembers.length}
                </MAvatar>
                <Box sx={{ml: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                    members selected
                  </Typography>
                </Box>
              </Stack>
            )}
            <ExpandMoreIcon sx={{color: 'grey'}} />
          </AccountStyle>
        </Button>

        <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{width: 240}}>
          {isLoading ? (
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100px'}}>
              <CircularProgress />
            </Box>
          ) : !familyGroups || !familyGroups.length ? (
            <EmptyContent title="There is no family group." />
          ) : (
            <>
              <Box sx={{my: 1.5, px: 2.5}}>
                <Typography variant="subtitle1" noWrap color="gray">
                  Family Members
                </Typography>
              </Box>

              {/* divider */}
              <Divider sx={{mt: 1}} />

              {/* favorites */}
              <FormControlLabel
                labelPlacement={'start'}
                defaultValue={false}
                control={<Switch />}
                label={<Typography variant="overline">Favorites</Typography>}
                checked={showFavorites}
                onChange={(e) => {
                  handleAllMember(false);
                  setShowFavorites(e.target.checked);
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  pr: 2,
                  py: 0.5
                }}
              />

              {/* divider */}
              <Divider sx={{mt: 0}} />

              {/* search */}
              <TextField
                value={searchTerm}
                onChange={(e) => setsearchTerm(e.target.value)}
                placeholder="Search members ..."
                size="small"
                sx={{m: 1, borderRadius: 1}}
                variant="outlined"
              />

              {/* divider */}
              <Divider />

              {/* select all */}
              {list?.length > 0 ? (
                <MenuItem sx={{typography: 'body2', py: 1, px: 2.5}}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={getAllShowStatus()} onChange={(e) => handleAllMember(e.target.checked)} />
                    }
                    label="Select / Deselect All"
                  />
                </MenuItem>
              ) : (
                <EmptyContent title="No member found." />
              )}

              {!showFavorites ? (
                <>
                  {/* family group tree */}
                  <TreeView
                    aria-label="controlled"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpanded={['root']}
                    defaultExpandIcon={<ChevronRight />}
                    defaultEndIcon={''}
                    sx={{maxHeight: 400, flexGrow: 1, overflowY: 'auto', width: '100%'}}
                  >
                    {
                      // groups
                      Object.keys(groupedList).filter((key) => key !== 'no-family-group').length > 0 &&
                        Object.keys(groupedList).map(
                          (group, index) =>
                            group !== 'no-family-group' && (
                              <StyledTreeItem
                                sx={{borderTop: '1px solid rgba(145, 158, 171, 0.24)'}}
                                key={`${group} ${index}`}
                                nodeId={`${group} ${index}`}
                                label={
                                  <FormControlLabel
                                    sx={{py: 1, px: 0, width: '100%', position: 'relative'}}
                                    control={
                                      <Checkbox
                                        checked={getGroupShowStatus(group)}
                                        onChange={(e) => handleGroup(e.target.checked, group)}
                                      />
                                    }
                                    label={
                                      <Stack
                                        direction={'row'}
                                        width={'100%'}
                                        sx={{display: 'flex', alignItems: 'center'}}
                                      >
                                        {manageName(groupedList[group].groupName)}
                                        <Label
                                          variant={isLight ? 'ghost' : 'filled'}
                                          color={
                                            groupedList[group].isSuperAdmin || groupedList[group].isAdmin
                                              ? 'success'
                                              : 'warning'
                                          }
                                          sx={{
                                            textTransform: 'capitalize',
                                            position: 'absolute',
                                            right: -12,
                                            fontSize: 10
                                          }}
                                        >
                                          {groupedList[group].isSuperAdmin
                                            ? 'Super Admin'
                                            : groupedList[group].isAdmin
                                            ? 'Admin'
                                            : 'Member'}
                                        </Label>
                                      </Stack>
                                    }
                                  />
                                }
                              >
                                {
                                  // clients
                                  groupedList[group]?.clients?.map((client) => (
                                    <StyledTreeItem
                                      sx={{
                                        backgroundColor: 'rgba(145, 158, 171, 0.2)',
                                        borderTop: '1px solid rgba(145, 158, 171, 0.24)',
                                        paddingLeft: 1.5
                                      }}
                                      key={`${group} ${client?.id}`}
                                      nodeId={`${group} ${client?.id}`}
                                      label={
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            pr: 1.5,
                                            pl: 1.3
                                          }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={getShowStatus(client?.id) || false}
                                                onChange={(e) => handleConnectedMember(e.target.checked, client?.id)}
                                              />
                                            }
                                            label={manageName(getFullName(client), 12)}
                                          />
                                          <Tooltip title={client?.email || 'no email'} arrow>
                                            <Box
                                              onClick={() => handleFavoriteClick(!client?.isFavorite, client?.id)}
                                              sx={{
                                                padding: '0px !important',
                                                margin: '0px !important',
                                                width: '20px',
                                                height: '20px'
                                              }}
                                            >
                                              {!!client?.isFavorite ? (
                                                <FavoriteIcon sx={{color: '#ed5353'}} fontSize="small" />
                                              ) : (
                                                <FavoriteBorderOutlinedIcon sx={{color: 'grey'}} fontSize="small" />
                                              )}
                                            </Box>
                                          </Tooltip>
                                        </Box>
                                      }
                                    />
                                  ))
                                }
                              </StyledTreeItem>
                            )
                        )
                    }
                  </TreeView>
                </>
              ) : (
                list.length > 0 &&
                list?.map?.((client, index) => (
                  <MenuItem
                    key={`${client.id} ${index}`}
                    sx={{
                      typography: 'body2',
                      py: 1,
                      pr: 2.5,
                      pl: 0.6,
                      borderTop: '1px solid rgba(145, 158, 171, 0.24);'
                    }}
                  >
                    <FormControlLabel
                      sx={{marginRight: 'auto', marginLeft: 2.3}}
                      control={
                        <Checkbox
                          checked={getShowStatus(client.id)}
                          onChange={(e) => handleConnectedMember(e.target.checked, client.id)}
                        />
                      }
                      label={manageName(getFullName(client))}
                    />
                    <Tooltip title={client.email || 'no email'} arrow>
                      <Box
                        onClick={() => handleFavoriteClick(!client?.isFavorite, client?.id)}
                        sx={{
                          padding: '0px !important',
                          margin: '0px !important',
                          width: '20px',
                          height: '20px'
                        }}
                      >
                        {!!client?.isFavorite ? (
                          <FavoriteIcon sx={{color: '#ed5353'}} fontSize="small" />
                        ) : (
                          <FavoriteBorderOutlinedIcon sx={{color: 'grey'}} fontSize="small" />
                        )}
                      </Box>
                    </Tooltip>
                  </MenuItem>
                ))
              )}
            </>
          )}
        </MenuPopover>
      </>
    )
  );
}

export default React.memo(MemberPopover);
