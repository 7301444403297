import {Link as RouterLink} from 'react-router-dom';
// mui
import {styled} from '@mui/material/styles';
import {Box, Card, Stack, Link, Container, Typography} from '@mui/material';
// routes
import {PATH_AUTH} from 'src/routes/paths';
// layouts
import AuthLayout from 'src/layouts/AuthLayout';
// components
import Page from 'src/components/Page';
import {MHidden} from 'src/components/@material-extend';
import {LoginForm} from 'src/components/authentication/login';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({theme}) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({theme}) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login | Opticus">
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
          Get started
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{px: 5, mt: 10, mb: 5}}>
            Your Life on Opticus
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{mb: 5}}>
            <Box sx={{flexGrow: 1}}>
              <Typography variant="h4" gutterBottom>
                Connect to Opticus
              </Typography>
              <Typography sx={{color: 'text.secondary'}}>Please verify your credentials below.</Typography>
            </Box>
          </Stack>
          <LoginForm />
          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{mt: 3}}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Get started
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
