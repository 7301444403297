// mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {MAvatar} from './@material-extend';
// redux
import {useSelector} from 'src/redux/store';
// utils
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function UserAvatar({userId, isMember = false, user, sx, withName = false, ...other}) {
  const {members} = useSelector((state) => state.familyGroup);
  const {list: clients} = useSelector((state) => state.client);
  const list = isMember ? members : clients;

  const client = list.find((client) => client.id === userId);
  const filterClient = isMember ? user : client;

  return (
    <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
      <MAvatar
        src={filterClient?.avatar}
        title={filterClient?.firstName + ' ' + filterClient?.lastName}
        alt={filterClient?.firstName}
        color={filterClient?.avatar ? 'default' : createAvatar(filterClient).color}
        sx={{boxShadow: '0px 2px 10px 2px rgb(0 0 0 / 30%)', border: '1px solid white', ...sx}}
        {...other}
      >
        {createAvatar(filterClient).name}
      </MAvatar>
      {withName && <Typography>{filterClient?.firstName + ' ' + filterClient?.lastName}</Typography>}
    </Stack>
  );
}
