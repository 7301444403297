// mui
import {TextField, Tooltip} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import InputAdornment from '@mui/material/InputAdornment';
// ----------------------------------------------------------------------

const TextFieldWithHover = ({info = '', ...other}) => {
  return (
    <TextField
      {...other}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{cursor: 'pointer'}}>
            <Tooltip title={info} arrow>
              <InfoIcon />
            </Tooltip>
          </InputAdornment>
        )
      }}
    />
  );
};

export default TextFieldWithHover;
