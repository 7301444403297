import {diff} from './formatTime';

export function getRemainingDays(expDate) {
  const expirationDate = expDate ? new Date(expDate) : null;

  return !expirationDate
    ? 'Infinite'
    : new Date(expDate).getTime() > new Date().getTime()
    ? Math.max(diff(expirationDate) - 1, 0)
    : 'Expired';
}
