import axios from 'src/utils/axios';
// ----------------------------------------------------------------------

export async function updateTaxDeadline(payload) {
  try {
    const response = await axios.post('/profile/tax', payload);
    return response;
  } catch (e) {
    return e;
  }
}

export async function getTaxDeadline() {
  try {
    const response = await axios.get('/profile/tax');
    return response;
  } catch (e) {
    return e;
  }
}

export async function getPassport() {
  try {
    const response = await axios.get('/profile/passport');
    return response.data;
  } catch (e) {
    return e;
  }
}

export async function setPassport(payload) {
  try {
    const response = await axios.post('/profile/passport', payload);
    return response;
  } catch (e) {
    return e;
  }
}

export async function getFamilyInfo() {
  try {
    const response = await axios.get('/profile/family');
    return response;
  } catch (e) {
    return e;
  }
}

export async function updateFamilyInfo(payload) {
  try {
    const response = await axios.post('/profile/family', payload);
    return response;
  } catch (e) {
    return e;
  }
}

export const getFinancialData = async () => {
  try {
    const response = await axios.get('profile/financials');
    return response;
  } catch (e) {
    return e;
  }
};

export const updateFinancialData = async (payload) => {
  try {
    const response = await axios.post('profile/financials', payload);
    return response;
  } catch (e) {
    return e;
  }
};

export async function getCaptainLicenses() {
  try {
    const response = await axios.get('/profile/captain-license');
    return response.data;
  } catch (e) {
    return e;
  }
}

export async function setCaptainLicences(payload) {
  try {
    const response = await axios.post('/profile/captain-license', payload);
    return response;
  } catch (e) {
    return e;
  }
}

export async function getDriverLicenses() {
  try {
    const response = await axios.get('/profile/driver-license');
    return response.data;
  } catch (e) {
    return e;
  }
}

export async function setDriverLicences(payload) {
  try {
    const response = await axios.post('/profile/driver-license', payload);
    return response;
  } catch (e) {
    return e;
  }
}

export async function getPilotLicenses() {
  try {
    const response = await axios.get('/profile/pilot-license');
    return response.data;
  } catch (e) {
    return e;
  }
}

export async function setPilotLicences(payload) {
  try {
    const response = await axios.post('/profile/pilot-license', payload);
    return response;
  } catch (e) {
    return e;
  }
}

// verify email address
export async function verifyEmailAddress() {
  try {
    const URL = `/profile/send-email-verification-link`;
    const response = await axios.get(URL);
    return response;
  } catch (error) {
    return error;
  }
}
