import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  posts: [],
  post: null
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPosts(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    setPostAction(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // Add new post
    addPostAction(state, action) {
      state.isLoading = false;
      state.posts = [...state.posts, action.payload];
    },

    // Update post
    updatePostAction(state, action) {
      state.isLoading = false;
      state.posts = state.posts.map((post) => (post.blogId === action.payload.id ? action.payload : post));
    },

    // Delete post
    deletePostAction(state, action) {
      state.isLoading = false;
      state.posts = state.posts.filter((post) => post.blogId !== action.payload);
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {setPostAction, addPostAction, updatePostAction, deletePostAction} = slice.actions;

// ----------------------------------------------------------------------

export function getAllPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/blog');
      if (response.status === 200 && !!response.data) dispatch(slice.actions.getPosts(response.data));

      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getPost(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/blog/post/${id}`);
      dispatch(setPostAction(response.data));

      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function addPost(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/blog/post/new`, payload);
      dispatch(addPostAction(response.data));

      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// --------------------------------------------------------------------

export function updatePost(id, payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/blog/post/${id}`, payload);
      dispatch(slice.actions.updatePostAction(response.data));

      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

//------------------------------------------------------------------

export function deletePost(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.delete(`/blog/post/${id}`);
      dispatch(deletePostAction(id));

      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

//------------------------------------------------------------------

export function addComment(blogId, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/blog/post/comment/${blogId}`, data);
      dispatch(setPostAction(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

//------------------------------------------------------------------

export function deleteComment(blogId, commentId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/blog/post/blog/${blogId}/comment/${commentId}`);
      dispatch(setPostAction(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

//------------------------------------------------------------------

export function addLike(blogId, data) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/blog/post/like/${blogId}`, data);
      dispatch(setPostAction(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

//------------------------------------------------------------------

export function removeLike(blogId, userId) {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`/blog/post/like/${blogId}?userId=${userId}`);
      dispatch(setPostAction(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
