import React from 'react';
import PropTypes from 'prop-types';
import {Box, Card, CardContent, Stack} from '@mui/material';

import EmptyContent from 'src/components/EmptyContent';
import Gallery from './components/Divested/Gallery';
import Table from './components/Divested/Table';

Divested.propTypes = {
  mode: PropTypes.string,
  view: PropTypes.string,
  data: PropTypes.array
};

export default function Divested({mode, view = 'show', data = []}) {
  const renderData = () =>
    mode === 'gallery' ? (
      <Gallery data={data} />
    ) : (
      <Box sx={{minHeight: 360}}>
        <Table data={data} />
      </Box>
    );

  return (
    <Stack spacing={2}>
      {/* {view === 'show' && (
        <Grid container>
          <Grid item xs={12} md={6} lg={4}>
            <Box sx={{m: 1}}>
              <LabelWidget label="Total Prior Assets" data={chartData.totalPriorAssetsValue} type="currency" />
            </Box>
          </Grid>
        </Grid>
      )} */}
      <Card>
        <CardContent>{data.length > 0 ? renderData() : <EmptyContent />}</CardContent>
      </Card>
    </Stack>
  );
}
