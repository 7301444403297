import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  ledger: []
};

const slice = createSlice({
  name: 'ledger',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LEDGER
    setLedger(state, action) {
      state.isLoading = false;
      state.ledger = action.payload;
    },

    // ADD NEW LEDGER
    addLedger(state, action) {
      state.isLoading = false;
      state.ledger = [...state.ledger, action.payload];
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {stopLoading} = slice.actions;

// ----------------------------------------------------------------------

export function getLedger() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/ledger');
      const ledger = response.data;
      dispatch(slice.actions.setLedger(ledger));

      return response;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      return err;
    }
  };
}

export function addLedger(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/ledger', payload);
      const ledger = response.data;

      dispatch(slice.actions.setLedger(ledger));
      return response;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      return err;
    }
  };
}

export function updateLedger(data) {
  return async (dispatch) => {
    const {id} = data;
    delete data.id;

    dispatch(slice.actions.startLoading());
    try {
      const res = await axios.put(`/ledger/${id}`, data);
      const response = await axios.get('/ledger');
      const ledger = response.data;

      dispatch(slice.actions.setLedger(ledger));
      return res;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      return err;
    }
  };
}
