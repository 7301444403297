import React, {useEffect, useState, useCallback} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import {debounce} from 'lodash';
// mui
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// api
import {addAddress, updateAddress, deleteAddress, getAutocompleteAddress, searchAddress} from 'src/api';
// hooks
import useAuth from 'src/hooks/useAuth';
// redux
import {useSelector} from 'src/redux/store';
// utils
import {getFullAddress} from 'src/utils/stringFormat';
// constants
import {COUNTRY_LIST} from 'src/constants/countries';
import {US_STATES} from 'src/constants/states';
// ----------------------------------------------------------------------

export default function AddressFields({
  isProfile = false, // boolean
  isRealEstate, // boolean
  addId, // string
  alignment, // string
  inputProps = {},
  zillowPublicRecords,
  zillowImportLoading, // boolean
  hazardHubRecords,
  hazardHubImportLoading, // boolean
  newClientId = null,
  handleChange = () => {}
}) {
  const {enqueueSnackbar} = useSnackbar();
  const {user} = useAuth();
  const {list} = useSelector((state) => state.client);
  const {members} = useSelector((state) => state.familyGroup);

  const [loading, setLoading] = useState(false);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);

  const [currentId, setCurrentId] = useState(addId); // Selected address Id
  const [showAutocomplete, setShowAutocomplete] = useState(false); // Show google address search bar
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // NOTE: Show delete modal for address
  const [addAsRealEstate, setAddAsRealEstate] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [autocompleteAddressList, setAutocompleteAddressList] = useState([]); // Google suggestion
  const [query, setQuery] = useState('');

  const isAdvisor = user.role === 'Advisor';
  const memberSelected = members.filter((m) => m.show);

  const cId = (isAdvisor ? list : members).find((item) => item.show)?.id || '';
  const clientId = !!newClientId ? newClientId : cId === user.userId ? null : cId;

  // Current address details
  const addressDetails = addressList.find((item) => item.id === currentId) || null;

  // fetch all records at the beginning
  useEffect(() => {
    const fetchAllAddresses = async () => {
      try {
        setLoading(true);
        const result = isProfile
          ? await searchAddress('', false, false, '', false)
          : await searchAddress('', isRealEstate, isAdvisor, clientId, memberSelected.length === 1);
        if (!result.message) {
          setAddressList(result || []);
        } else {
          enqueueSnackbar(result.message || 'Could not fetch addresses', {variant: 'error'});
        }
      } catch (error) {
        enqueueSnackbar('Could not fetch addresses', {variant: 'error'});
      } finally {
        setLoading(false);
      }
    };

    fetchAllAddresses();
    // eslint-disable-next-line
  }, []);

  // show selected address info
  useEffect(() => {
    const targetAdd = addressList?.find?.((item) => item.id === currentId);

    if (targetAdd) {
      setValues(targetAdd, false);
    }
    // eslint-disable-next-line
  }, [currentId, addressList]);

  const AddSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      name: '', // check this field if its necessary
      street: '',
      apt: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'US'
    },
    validationSchema: AddSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true);
      try {
        const response = isProfile
          ? await addAddress(values, false, false, null, !false)
          : await addAddress(
              values,
              isRealEstate || addAsRealEstate,
              isAdvisor,
              clientId,
              !!clientId && memberSelected.length === 1
            );
        if (response.id) {
          setAddressList((old) => [...old, {...values, ...response}]);
          setCurrentId(response.id);
          handleChange(response.id, values);
          setShowAutocomplete(false);
        }
        enqueueSnackbar('Address added successfully', {variant: 'success'});
      } catch (error) {
        enqueueSnackbar('Can not add new address', {variant: 'error'});
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, getFieldProps, setFieldValue, handleSubmit, isSubmitting, setValues, setSubmitting} =
    formik;

  const handleSaveAddress = () => {
    if (isRealEstate) {
      handleSubmit();
    } else {
      setShowModal(true);
    }
  };

  const handleUpdateAddress = async () => {
    setSubmitting(true);
    try {
      const response = isProfile
        ? await updateAddress(currentId, values, false, null, false)
        : await updateAddress(currentId, values, isAdvisor, clientId, !!clientId && memberSelected.length === 1);
      if (response.id) {
        setAddressList((old) => [...old.filter((item) => item.id !== currentId), {...values, ...response}]);
        setShowAutocomplete(false);
        enqueueSnackbar('Address updated successfully', {variant: 'success'});
      } else {
        enqueueSnackbar('Could not update current address', {variant: 'error'});
      }
    } catch (error) {
      enqueueSnackbar('Could not update current address', {variant: 'error'});
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteAddress = async () => {
    try {
      setSubmitting(true);
      const response = isProfile
        ? await deleteAddress(currentId, false, null, false)
        : await deleteAddress(currentId, isAdvisor, clientId, !!clientId && memberSelected.length === 1);
      if (response.id) {
        setAddressList((old) => old.filter((item) => item.id !== response.id));
        setCurrentId(null);
        setShowAutocomplete(false);
        setShowDeleteModal(false);
        enqueueSnackbar('Address deleted successfully', {variant: 'success'});
      } else {
        enqueueSnackbar('Could not delete current address', {variant: 'error'});
      }
    } catch (error) {
      enqueueSnackbar('Could not delete current address', {variant: 'error'});
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleAutocompleteAddress = (value) => {
    setFieldValue('street', `${value?.streetNumber || ''} ${value?.streetName || ''}`);
    setFieldValue('city', value?.city || '');
    setFieldValue('state', value?.administrativeLevels?.level1long || '');
    setFieldValue('zipCode', value?.zipcode || '');
    setFieldValue('country', value?.countryCode || '');
  };

  const handleGoogleChange = async (value) => {
    if (!value) {
      setAutocompleteAddressList([]);
      return;
    }

    try {
      setAutocompleteLoading(true);
      const aData = await getAutocompleteAddress(value);
      if (!!aData?.data?.length) {
        setAutocompleteAddressList(aData?.data);
      }
    } catch (error) {
      console.log({error});
    } finally {
      setAutocompleteLoading(false);
    }
  };

  // eslint-disable-next-line
  const debouncedAutocompleteHandler = useCallback(debounce(handleGoogleChange, 300), []);

  const handleGoogleQueryChange = (e) => {
    setQuery(e.target.value);
    debouncedAutocompleteHandler(e.target.value.trim());
  };

  return (
    <>
      <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{alignItems: 'center'}}>
        <Autocomplete
          fullWidth
          loading={loading}
          options={[{id: 'new'}, ...addressList]}
          getOptionLabel={(option) =>
            option.id === 'new'
              ? 'Create New Address'
              : !!option.id
              ? getFullAddress(option.street, option.apt, option.city, option.state, option.country, option.zipCode)
              : ''
          }
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          value={addressDetails}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.id === 'new'
                ? 'Create New Address'
                : !!option.id
                ? getFullAddress(option.street, option.apt, option.city, option.state, option.country, option.zipCode)
                : ''}
            </li>
          )}
          onChange={(_e, value) => {
            if (value.id === 'new') {
              setCurrentId(null);
              setValues({
                name: '',
                street: '',
                apt: '',
                city: '',
                state: '',
                zipCode: '',
                country: 'US'
              });
              setShowAutocomplete(true);
            } else {
              setCurrentId(value.id);
              handleChange(value.id, value);
              setShowAutocomplete(false);
            }
          }}
          disableClearable
          renderInput={(params) => (
            <TextField {...params} {...inputProps} autoComplete="off" label="Address" variant="outlined" />
          )}
        />
        <Box sx={{width: '100%'}}>
          <Stack spacing={1} direction="row" sx={{alignItems: 'center'}}>
            {currentId && !showAutocomplete && (
              <>
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setShowAutocomplete(true);
                    }}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton
                    size="small"
                    color="error"
                    disabled={isSubmitting}
                    onClick={() => {
                      setShowDeleteModal(true);
                    }}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              </>
            )}
            {(zillowPublicRecords || hazardHubRecords) && addressDetails?.id && (
              <LoadingButton
                sx={{marginTop: 1, marginBottom: 1, marginRight: 2}}
                loading={zillowImportLoading || hazardHubImportLoading}
                variant="contained"
                onClick={() => {
                  if (zillowPublicRecords) zillowPublicRecords(addressDetails);
                  if (hazardHubRecords) hazardHubRecords(addressDetails);
                }}
              >
                Import public records
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </Stack>

      {showAutocomplete && (
        <Autocomplete
          sx={{my: 2}}
          loading={autocompleteLoading}
          fullWidth
          options={autocompleteAddressList}
          getOptionLabel={(option) => option?.formattedAddress || ''}
          filterOptions={(x) => x}
          onChange={(_e, value) => {
            handleAutocompleteAddress(value);
          }}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              {...inputProps}
              autoComplete="off"
              label="Enter Address"
              variant="outlined"
              value={query}
              onChange={handleGoogleQueryChange}
            />
          )}
        />
      )}

      {showAutocomplete && (
        <>
          <Stack direction={{xs: 'column', md: alignment === 'vertical' ? 'column' : 'row'}} spacing={2} marginY={2}>
            <TextField
              {...getFieldProps('street')}
              fullWidth
              type="text"
              label="Street"
              autoComplete="street-address"
              error={Boolean(touched.street && errors.street)}
              helperText={touched.street && errors.street}
            />
            <TextField
              {...getFieldProps('apt')}
              fullWidth
              type="text"
              label="Apt, Suit, Etc"
              error={Boolean(touched.apt && errors.apt)}
              helperText={touched.apt && errors.apt}
            />
            <TextField
              {...getFieldProps('city')}
              fullWidth
              type="text"
              label="City"
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
            />
          </Stack>
          <Stack direction={{xs: 'column', md: alignment === 'vertical' ? 'column' : 'row'}} spacing={2}>
            <TextField
              select={values.country === 'US'}
              fullWidth
              label="State"
              {...getFieldProps('state')}
              onChange={(e) => setFieldValue('state', e.target.value)}
            >
              {US_STATES.map((option) => (
                <MenuItem key={option.code} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              label="Zip/Code"
              inputProps={{maxLength: 5}}
              {...getFieldProps('zipCode')}
              error={Boolean(touched.zipCode && errors.zipCode)}
              helperText={touched.zipCode && errors.zipCode}
            />
            <Autocomplete
              fullWidth
              {...getFieldProps('country')}
              options={COUNTRY_LIST}
              getOptionLabel={(option) => option?.label || ''}
              isOptionEqualToValue={(option, value) => option?.code === value?.code}
              disableClearable
              value={COUNTRY_LIST.find((item) => item.code === values.country)}
              onChange={(e, value) => {
                setFieldValue('country', value?.code);
                setFieldValue('state', '');
              }}
              renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
            />
          </Stack>
          <Stack direction={{xs: 'column', md: 'row-reverse'}} spacing={2} mt={2}>
            {currentId ? (
              <LoadingButton variant="contained" loading={isSubmitting} onClick={handleUpdateAddress}>
                Update
              </LoadingButton>
            ) : (
              <LoadingButton variant="contained" loading={isSubmitting} onClick={handleSaveAddress}>
                Save
              </LoadingButton>
            )}
            <Button
              color="inherit"
              onClick={() => {
                setValues({
                  name: '',
                  street: '',
                  apt: '',
                  city: '',
                  state: '',
                  zipCode: '',
                  country: 'US'
                });
                setShowAutocomplete(false);
              }}
            >
              Cancel
            </Button>
          </Stack>
          <Dialog
            open={showModal}
            onClose={() => {
              setShowModal(false);
            }}
          >
            <DialogTitle>{'Save as new address?'}</DialogTitle>
            <DialogContent>
              <DialogContentText>Do you want it to save as real state or universal address?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowModal(false)}>Cancel</Button>
              <Button
                onClick={() => {
                  setAddAsRealEstate(true);
                  handleSubmit();
                  setShowModal(false);
                }}
                color="secondary"
                variant="contained"
              >
                Real Estate
              </Button>
              <Button
                onClick={() => {
                  setAddAsRealEstate(false);
                  handleSubmit();
                  setShowModal(false);
                }}
                color="primary"
                variant="contained"
              >
                Universal
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      <Dialog
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
      >
        <DialogTitle>Delete Address</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure to delete this address?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <LoadingButton variant="contained" color="error" loading={isSubmitting} onClick={handleDeleteAddress}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
