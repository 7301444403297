import {merge} from 'lodash';
import ReactApexChart from 'react-apexcharts';
//
import {fCurrency, fNumber} from 'src/utils/formatNumber';
import BaseOptionChart from './BaseOptionChart';
// utils
import {isNullOrEmpty} from 'src/utils/checkEmpty';
// mui
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

const CHART_LABEL = 'Series 1';
const CHART_CATEGORIES = ['2019', '2020', '2023'];
const CHART_DATA = [1200, 500, 100];

export default function AnnualPChart({categories, data, label, amount = false}) {
  const isDataEmpty = isNullOrEmpty(data) || isNullOrEmpty(categories);
  const [inputCategories, inputData, inputLabel] = isDataEmpty
    ? [CHART_CATEGORIES, CHART_DATA, CHART_LABEL]
    : [categories, data, label];

  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: {bar: {columnWidth: '16%'}},
    stroke: {show: false},
    xaxis: {
      categories: inputCategories
    },
    yaxis: {
      axisTicks: {
        show: true
      },
      axisBorder: {
        show: true
      },
      labels: {
        formatter: (val) => '$' + fNumber(val)
      }
    },
    tooltip: {
      y: {
        formatter: (val) => (amount ? val : fCurrency(val))
      }
    },
    legend: {show: false}
  });

  return (
    <Box sx={{flex: 1, ...(isDataEmpty && {filter: 'grayscale(90%)', opacity: 0.5})}}>
      <ReactApexChart
        type="bar"
        series={[{name: inputLabel, data: inputData}]}
        options={chartOptions}
        height={'100%'}
      />
    </Box>
  );
}
