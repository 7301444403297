import * as CryptoJS from 'crypto-js';

/**
 * data: string
 */
export const encrypt = (data = '') => CryptoJS.AES.encrypt(data, process.env.REACT_APP_ENCRYPT_KEY).toString();

/**
 * data: string(encrypted string)
 */
export const decrypt = (encryptedData = '') =>
  CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_ENCRYPT_KEY).toString(CryptoJS.enc.Utf8);
