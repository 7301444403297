// mui
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PolicyIcon from '@mui/icons-material/Policy';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ArticleIcon from '@mui/icons-material/Article';
import EventIcon from '@mui/icons-material/Event';
// constant
import {LEDGER_TYPE} from 'src/constants/ledger';
import {getAssetIcon} from 'src/constants/tab';
// ----------------------------------------------------------------------

export const getIcon = (type, subType) => {
  if (type === 'contact') {
    return <ContactPhoneIcon />;
  } else if (type === 'asset') {
    return getAssetIcon(subType) || <AccountBalanceWalletIcon />;
  } else if (type === 'policy') {
    return <PolicyIcon />;
  } else if (type === 'entity') {
    return <CorporateFareIcon />;
  } else if (type === 'claim') {
    return <ArticleIcon />;
  }

  return <EventIcon />;
};

export const getColor = (type) => {
  if (type === 'contact') {
    return 'primary';
  } else if (type === 'asset') {
    return 'secondary';
  } else if (type === 'policy') {
    return 'info';
  } else if (type === 'entity') {
    return 'success';
  } else if (type === 'claim') {
    return 'error';
  }

  return 'warning';
};

export const getLabel = (type) => {
  const target = LEDGER_TYPE.find((item) => item.value === type);
  return !target ? '' : target.label;
};
