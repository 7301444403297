import {useState} from 'react';
import {useSnackbar} from 'notistack';
// mui
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// api
import {startVerify} from 'src/api/canopy';
// ----------------------------------------------------------------------
const CanopyVerifyOption = ({pullInfo = {}, options = {}, handleNext = () => {}}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [option, setOption] = useState('');
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setOpen(false);
    setLoading(true);
    try {
      const res = await startVerify({...pullInfo, mfaOptionKey: option});
      if (res.data?.pullId) {
        handleNext();
      } else {
        enqueueSnackbar('Verify option submit failed.', {variant: 'error'});
        setLoading(false);
      }
    } catch (err) {
      console.log('Verify option Error:', err);
      setLoading(false);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Security Code Options</DialogTitle>
      <Divider />
      <DialogContent>
        <RadioGroup name="radio-buttons-group" value={option} onChange={(e) => setOption(e.target.value)}>
          {Object.entries(options).map(([key, label]) => (
            <FormControlLabel value={key} control={<Radio />} label={label} />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} onClick={handleClick} variant="contained">
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CanopyVerifyOption;
