import PropTypes from 'prop-types';
import {useRef, useState, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Icon} from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
// formik
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import {FormikProvider, Form, useFormik} from 'formik';
import {FormikErrorNotification} from 'src/components/_dashboard/common';
// api
import {getNotifications, updateNotification} from 'src/api';
// mui
import {alpha} from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  Stack
} from '@mui/material';
// utils
import {fToNow} from 'src/utils/formatTime';
// components
import Scrollbar from 'src/components/Scrollbar';
import MenuPopover from 'src/components/MenuPopover';
import {MIconButton} from 'src/components/@material-extend';
import NotificationDialog from './NotificationDialog';
import {LoadingButton} from '@mui/lab';
import {useDispatch} from 'react-redux';
import {respondToInvite} from 'src/redux/slices/familyGroup';
import Label from 'src/components/Label';
// theme
import {useTheme} from '@mui/material/styles';
// redux
import {getFamilyData} from 'src/redux/slices/family';
import useAuth from 'src/hooks/useAuth';
import {respondToContactInvite} from 'src/redux/slices/contact';
import {verifyEmailAddress} from 'src/api/profile';

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{color: 'text.secondary'}}>
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  return {
    avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
    title
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object,
  refreshNotifications: PropTypes.func
};

function NotificationItem({notification, refreshNotifications, ...props}) {
  const theme = useTheme();
  const {user} = useAuth();

  const isLight = theme?.palette?.mode === 'light';
  const {avatar, title} = renderContent(notification);
  const [loading, setLoading] = useState(null);

  const type = notification?.type;
  const acceptanceStatus = notification?.meta?.acceptanceStatus;

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const ChoiceSchema = Yup.object().shape({
    familyGroupId: Yup.string().required('Request id not found'),
    acceptStatus: Yup.bool().required('Choice not found')
  });

  const formik = useFormik({
    initialValues: {
      familyGroupId:
        (type === 'inviteContact' ? notification?.meta?.contactInviteId : notification?.meta?.familygroupId) || null,
      acceptStatus: null
    },
    validationSchema: type === 'verify' ? null : ChoiceSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        setSubmitting(true);
        let res = null;
        if (type === 'inviteContact') {
          res = await dispatch(respondToContactInvite(values.familyGroupId, values.acceptStatus));
        } else if (type === 'invite') {
          res = await dispatch(respondToInvite(false, values, user.userId));
        } else if (type === 'verify') {
          res = await verifyEmailAddress();
        }

        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar(
            type === 'verify'
              ? `Verfication Link Sent`
              : `Invite ${values.acceptStatus ? 'Accepted' : 'Declined'} successfully.`,
            {
              variant: 'success'
            }
          );
          refreshNotifications();
          dispatch(getFamilyData());
        } else {
          enqueueSnackbar('An error occured.', {variant: 'error'});
        }

        setLoading(null);
        resetForm();
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setLoading(null);
      }
    }
  });

  const {setFieldValue, handleSubmit, isSubmitting} = formik;

  const handleChoice = async (acceptStatus) => {
    setLoading(acceptStatus);
    await setFieldValue('acceptStatus', acceptStatus);
    handleSubmit();
  };

  return (
    <Stack
      key={notification?.id}
      sx={{
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        }),
        mt: '1px'
      }}
    >
      <ListItemButton
        to="#"
        component={RouterLink}
        sx={{
          py: 1.5,
          px: 2.5
        }}
        {...props}
      >
        <ListItemAvatar>
          <Avatar sx={{bgcolor: 'background.neutral'}}>{avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled'
              }}
            >
              <Box component={Icon} icon={clockFill} sx={{mr: 0.5, width: 16, height: 16}} />
              {fToNow(notification.createdAt || new Date())}
            </Typography>
          }
        />
      </ListItemButton>

      {/* show accept/decline/close buttons */}
      {['inviteContact', 'invite', 'verify'].includes(type) &&
        (acceptanceStatus === 'pending' || !acceptanceStatus ? (
          <FormikProvider value={formik}>
            <FormikErrorNotification />
            <Form>
              {type === 'verify' ? (
                <Stack direction={'row'} sx={{m: 2, display: 'flex', justifyContent: 'end'}}>
                  <LoadingButton
                    sx={{height: 27, marginLeft: 1}}
                    variant="outlined"
                    onClick={() => handleChoice(true)}
                    loading={loading === true}
                    disabled={isSubmitting || loading !== null}
                  >
                    Verify
                  </LoadingButton>
                </Stack>
              ) : (
                <Stack direction={'row'} sx={{m: 2, display: 'flex', justifyContent: 'end'}}>
                  <LoadingButton
                    sx={{height: 27}}
                    color="error"
                    onClick={() => handleChoice(false)}
                    loading={loading === false}
                    disabled={isSubmitting || loading !== null}
                  >
                    Decline
                  </LoadingButton>
                  <LoadingButton
                    sx={{height: 27, marginLeft: 1}}
                    variant="outlined"
                    onClick={() => handleChoice(true)}
                    loading={loading === true}
                    disabled={isSubmitting || loading !== null}
                  >
                    Accept
                  </LoadingButton>
                </Stack>
              )}
            </Form>
          </FormikProvider>
        ) : (
          <Label
            variant={isLight ? 'ghost' : 'filled'}
            color={acceptanceStatus === 'accepted' ? 'success' : 'error'}
            sx={{
              textTransform: 'capitalize',
              marginLeft: 'auto',
              my: 2,
              mr: 2
            }}
          >
            {acceptanceStatus}
          </Label>
        ))}
    </Stack>
  );
}

export default function NotificationsPopover() {
  const [notifications, setNotifications] = useState([]);
  const [notif, setNotif] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const getData = async () => {
    try {
      const res = await getNotifications();
      if (!!res?.data) {
        const sortedNotifications = res?.data?.sort((a, b) => b.createdAt - a.createdAt);
        setNotifications(sortedNotifications);
      }
    } catch (e) {}
  };

  useEffect(() => {
    getData();
  }, []);

  const UpdateNotification = async () => {
    try {
      const res = await updateNotification();
      if (res) setNotifications([]);
    } catch (e) {
      console.log(e);
    }
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const totalUnRead = notifications?.filter?.((item) => item?.isUnRead)?.length;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setNotif(null);
  };

  const handleNotificationClick = (notification) => {
    setNotif(notification);
    setShowModal(true);
  };

  return (
    <>
      <NotificationDialog
        handleClose={handleCloseModal}
        open={showModal}
        notification={notif}
        refreshNotifications={getData}
      />

      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{width: 360}}>
        <Box sx={{display: 'flex', alignItems: 'center', py: 2, px: 2.5}}>
          <Box sx={{flexGrow: 1}}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{color: 'text.secondary'}}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary" onClick={UpdateNotification}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>
        <Divider />
        <Scrollbar sx={{maxHeight: {xs: 340, sm: '50vh'}, mb: '8px'}}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{py: 1, px: 2.5, typography: 'overline'}}>
                New
              </ListSubheader>
            }
          >
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                onClick={() => handleNotificationClick(notification)}
                refreshNotifications={getData}
              />
            ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
