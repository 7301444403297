import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import pinFill from '@iconify/icons-eva/pin-fill';
import atFill from '@iconify/icons-eva/at-fill';
import phoneFill from '@iconify/icons-eva/phone-fill';
import calendarFill from '@iconify/icons-eva/calendar-fill';
import creditCardOutline from '@iconify/icons-eva/credit-card-outline';
import bookOpenOutline from '@iconify/icons-eva/book-open-outline';
import companyIcon from '@iconify/icons-mdi/company';
import Info from '@iconify/icons-eva/info-outline';
// mui
import {Card, CardContent, CardHeader, IconButton, Link, Stack, Tooltip, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
// utils
import {formatPhoneNumber, getFullAddress} from 'src/utils/stringFormat';
import createAvatar from 'src/utils/createAvatar';
import {fDate} from 'src/utils/formatTime';
// components
import {MAvatar} from 'src/components/@material-extend';
// constants
import {SOCIALS} from 'src/constants/user';

const IconStyle = styled(Icon)({
  width: 20,
  height: 20,
  flexShrink: 0
});

PersonalInfo.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string
};

PersonalInfo.defaultProps = {
  type: 'profile',
  data: {}
};

export default function PersonalInfo({data, type}) {
  const {
    avatar,
    title = '',
    suffix = '',
    firstName = '',
    middleName = '',
    lastName = '',
    email,
    phoneNumber,
    passportNumber,
    about,
    titleInFirm,
    employerFirm,
    street1,
    street2,
    stateOrRegion,
    zipCode,
    city,
    country = {},
    familyAndRelationship = {},
    // FOR contact info
    company = '',
    phone = '',
    dob = '',
    licenseNumber = '',
    stateOfIssuance = ''
  } = data;
  const addressLabel = getFullAddress(street1, street2, city, stateOrRegion, country?.name, zipCode);
  const firmInfo =
    titleInFirm && employerFirm
      ? `${titleInFirm} at ${employerFirm}`
      : titleInFirm || employerFirm
      ? `${titleInFirm}${employerFirm}`
      : '';
  const name = {firstName, lastName};

  return type === 'profile' ? (
    <Card sx={{m: 1, height: 'calc(100% - 24px)'}}>
      <CardHeader title="Personal Information" />
      <CardContent>
        <Stack spacing={3}>
          <Stack sx={{alignItems: 'center'}} spacing={1}>
            <MAvatar src={avatar} color={createAvatar(name).color} sx={{width: 100, height: 100}}>
              {createAvatar(name).name}
            </MAvatar>
            <Stack
              direction="row"
              sx={{
                justifyContent: 'center',
                my: 2
              }}
              spacing={2}
            >
              {SOCIALS.map((social) => (
                <Tooltip key={social.name} title={social.name}>
                  <IconButton
                    href={data[social.id]}
                    target="_blank"
                    component={Link}
                    disabled={!data[social.id]}
                    color={data[social.id] ? 'primary' : 'default'}
                  >
                    {social.icon}
                  </IconButton>
                </Tooltip>
              ))}
            </Stack>
            <Stack sx={{alignItems: 'center'}}>
              <Typography variant="body2">{`${title} ${firstName} ${middleName} ${lastName} ${suffix}`}</Typography>
              {!!firmInfo && <Typography variant="body2">{firmInfo}</Typography>}
            </Stack>
          </Stack>
          <Stack spacing={1}>
            {familyAndRelationship && familyAndRelationship.father && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="Father">
                  <IconStyle icon="bi:person-fill" />
                </Tooltip>
                <Typography variant="body2">{familyAndRelationship.father}</Typography>
              </Stack>
            )}
            {familyAndRelationship && familyAndRelationship.mother && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="Mother">
                  <IconStyle icon="bi:person-fill" />
                </Tooltip>
                <Typography variant="body2">{familyAndRelationship.mother}</Typography>
              </Stack>
            )}
            {addressLabel && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="Address">
                  <IconStyle icon={pinFill} />
                </Tooltip>
                <Typography variant="body2">{addressLabel}</Typography>
              </Stack>
            )}
            {email && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="Email">
                  <IconStyle icon={atFill} />
                </Tooltip>
                <Typography variant="body2">{email}</Typography>
              </Stack>
            )}
            {phoneNumber && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="Phone Number">
                  <IconStyle icon={phoneFill} />
                </Tooltip>
                <Typography variant="body2">{`${country && country.dialCode ? country.dialCode : ''}${formatPhoneNumber(
                  phoneNumber
                )}`}</Typography>
              </Stack>
            )}
            {passportNumber && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="Passport Number">
                  <IconStyle icon={bookOpenOutline} />
                </Tooltip>
                <Typography variant="body2">{passportNumber}</Typography>
              </Stack>
            )}
            {about && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="About">
                  <IconStyle icon={Info} />
                </Tooltip>
                <Typography variant="body2">{about}</Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  ) : (
    <Card sx={{m: 1, height: 'calc(100% - 24px)'}}>
      <CardContent>
        <Stack spacing={3}>
          <Stack sx={{alignItems: 'center'}} spacing={1}>
            <MAvatar src={avatar} color={createAvatar(name).color} sx={{width: 60, height: 60}}>
              {createAvatar(name).name}
            </MAvatar>
            <Stack sx={{alignItems: 'center'}}>
              <Typography variant="body2">{`${firstName} ${lastName}`}</Typography>
            </Stack>
          </Stack>
          <Stack spacing={1}>
            {email && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="Email">
                  <IconStyle icon={atFill} />
                </Tooltip>
                <Typography variant="body2">{email}</Typography>
              </Stack>
            )}
            {phone && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="Phone Number">
                  <IconStyle icon={phoneFill} />
                </Tooltip>
                <Typography variant="body2">{`${country && country.dialCode ? country.dialCode : ''}${formatPhoneNumber(
                  phone
                )}`}</Typography>
              </Stack>
            )}
            {company && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="Phone Number">
                  <IconStyle icon={companyIcon} />
                </Tooltip>
                <Typography variant="body2">{company}</Typography>
              </Stack>
            )}
            {dob && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="Date of Birth">
                  <IconStyle icon={calendarFill} />
                </Tooltip>
                <Typography variant="body2">{fDate(dob)}</Typography>
              </Stack>
            )}
            {licenseNumber && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="Driver License Number">
                  <IconStyle icon={creditCardOutline} />
                </Tooltip>
                <Typography variant="body2">{licenseNumber}</Typography>
              </Stack>
            )}
            {stateOfIssuance && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="State Of Issuance">
                  <IconStyle icon={Info} />
                </Tooltip>
                <Typography variant="body2">{stateOfIssuance}</Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
