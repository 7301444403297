// mui
import {useTheme} from '@mui/material/styles';
import {Stack} from '@mui/material';
// component
import Label from 'src/components/Label';
// ----------------------------------------------------------------------

const ExpirationLabel = ({status, label}) => {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <Stack sx={{justifyContent: 'center'}}>
      <Label
        variant={isLight ? 'ghost' : 'filled'}
        color={status === 'far' ? 'success' : status === 'near' ? 'warning' : 'error'}
        sx={{mx: 'auto'}}
      >
        {label}
      </Label>
    </Stack>
  );
};

export default ExpirationLabel;
