import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
// mui
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
  CircularProgress
} from '@mui/material';
// components
import EmptyContent from 'src/components/EmptyContent';
import Gallery from './components/RealEstate/Gallery';
import Table from './components/General/Table';
import LabelWidget from 'src/components/_dashboard/common/LabelWidget';
import FullScreenChart from '../common/FullScreenChart';
import ComingSoonCover from 'src/components/ComingSoonCover';
// utils
import { isNullOrEmpty } from 'src/utils/checkEmpty';
import { filterDCByClient } from 'src/utils/advisor';
// ----------------------------------------------------------------------
// chart components
import TotalLCoverChart from '../general-app/chart-components/TotalInsuranceChart';
import TotalInsuranceChart from '../general-app/chart-components/TotalInsuranceChart';
import TotalInsuranceColumnChart from '../general-app/chart-components/TotalInsuranceColumnChart';
// redux
import { useSelector } from 'src/redux/store';
// hooks
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
// api
import { getDashboardData } from 'src/api';
import { getAdvisorDashboardData } from 'src/api/advisor';

General.propTypes = {
  mode: PropTypes.string,
  view: PropTypes.string,
  data: PropTypes.array,
  chartData: PropTypes.object
};

export default function General({ mode, view = 'show', data = [], chartData = {} }) {
  const { user } = useAuth();
  const isAdvisor = user.role === 'Advisor';
  const [viewAll, setViewAll] = useState(false);
  const { trendShow, onChangeTrend } = useSettings();
  const [loading, setLoading] = useState(false);
  const { list, isLoading } = useSelector((state) => state.client);
  const [userData, setUserData] = useState(null);
  const { members } = useSelector((state) => state.familyGroup);
  const smCounts = members.filter((m) => m.show).length; // NOTE: Selected Family Member counts
  const { enqueueSnackbar } = useSnackbar();
  const [_data, setData] = useState(null);

  const getValidData = () => {
    return viewAll ? data : data.filter((item) => item.status === 'active');
  };

  const calculateInsuranceStats = (data) => {
    const activeStates = new Set();
    const insuranceCompanies = new Set();
    const brokers = new Set();
    data.forEach(insurance => {
      if (insurance.status === 'active') {
        activeStates.add(insurance);
        insuranceCompanies.add(insurance.issuingCompany);
        brokers.add(insurance.broker);
      }

    });

    return {
      totalActiveStates: activeStates.size,
      totalInsuranceCompanies: insuranceCompanies.size,
      totalBrokers: brokers.size
    };
  };

  const insuranceStats = calculateInsuranceStats(data);

  useEffect(() => {
    let isMounted = true;

    const getData = async () => {
      setLoading(true);
      try {
        if (user.role === 'Advisor' || smCounts > 0) {
          const response = await getAdvisorDashboardData(user.role !== 'Advisor');
          if (isMounted) {
            setData(response?.data);
          }
        } else {
          const response = await getDashboardData();
          if (isMounted) {
            setUserData(response?.data);
          }
        }
      } catch (e) {
        if (isMounted) {
          console.warn(e);
          enqueueSnackbar(e?.message || 'Get dashboard info error.', { variant: 'error' });
        }
      } finally {
        setLoading(false);
      }
    };

    getData();

    return () => (isMounted = false);
  }, [enqueueSnackbar, user, smCounts]);


  const advisorData = useMemo(() => {
    const selectedList = list.filter((item) => item.show);
    let familyCount = 0;
    let avClients = 0;
    // NOTE: grouped clients
    const groupedList = {};
    selectedList.forEach((client) => {
      if (client?.familyGroup?.length === 0) {
        const groupName = 'No Family Group';
        const groupId = 'no-family-group';

        if (!groupedList[groupId]) {
          groupedList[groupId] = { groupName, clients: [] };
        }
        groupedList[groupId].clients.push(client);
      } else {
        client?.familyGroup?.forEach((group) => {
          const groupName = group.groupName;
          const groupId = group.id;

          if (!groupedList[groupId]) {
            groupedList[groupId] = { groupName, clients: [] };
          }
          groupedList[groupId].clients.push(client);
        });
      }
    });

    if (!!Object.keys(groupedList).length) {
      const noFamilyClients = groupedList['no-family-group']?.clients?.length;
      delete groupedList['no-family-group'];

      familyCount = Object.keys(groupedList).length + noFamilyClients;
      const totalClients =
        Object.values(groupedList).reduce((sum, family) => sum + family.clients.length, 0) + noFamilyClients;
      avClients = totalClients / (familyCount || 1);
    }

    return { totalClients: selectedList.length, totalFamilies: familyCount, averageClients: avClients };
  }, [list]);

  const renderData = () =>
    mode === 'gallery' ? (
      <Gallery data={data} />
    ) : (
      <Box sx={{ minHeight: 360 }}>
        <FormControlLabel
          sx={{ position: 'absolute', top: '46px', right: '46px', zIndex: 999 }}
          control={<Switch size="small" checked={viewAll} onChange={(event) => setViewAll(event.target.checked)} />}
          label={viewAll ? 'View Active' : 'View All'}
          labelPlacement="start"
        />
        <Table data={getValidData()} />
      </Box>
    );

  const _chartData = isAdvisor
    ? filterDCByClient(JSON.parse(JSON.stringify(_data)) || [], JSON.parse(JSON.stringify(list)))
    : members.some((m) => m.show)
      ? filterDCByClient(JSON.parse(JSON.stringify(_data)) || [], JSON.parse(JSON.stringify(members)))
      : userData || {};


  return (
    <Stack spacing={2}>
      {view === 'show' && (
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{m:0, height: 'calc(100% - 12px)', justifyContent: 'space-between' }}>
              <Grid container sx={{ height: '30%', justifyContent: 'space-between' }}>
                <LabelWidget
                  data={chartData.totalAnnualPremium}
                  label="Total Annual Premium"
                  type="currency"
                  sx={{ height: '100%', width: '49%' }}
                />
                <LabelWidget
                  data={chartData.totalInsurancePolcies}
                  label="Total Insurance Policies"
                  sx={{ height: '100%', width: '49%' }}

                />
              </Grid>
              <Grid container sx={{ height: '30%', justifyContent: 'space-between' }}>
                <LabelWidget data={chartData.totalCoveredRisks} label="Total Covered Risks" sx={{ height: '100%', width: '49%' }} />
                <LabelWidget data={insuranceStats.totalActiveStates} label="Total Active States" sx={{ height: '100%', width: '49%' }} />
              </Grid>
              <Grid container sx={{ height: '30%', justifyContent: 'space-between' }}>
                <LabelWidget
                  data={insuranceStats.totalInsuranceCompanies}
                  label="Total Insurance Companies"
                  sx={{ height: '100%', width: '49%' }}
                />
                <LabelWidget data={insuranceStats.totalBrokers} label="Total Brokers" sx={{ height: '100%', width: '49%' }} />
              </Grid>

            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ m: 1, height: 'calc(100% - 12px)', display: 'flex', flexDirection: 'column' }}>
              <CardHeader title="Liability Coverage" />
              <Box sx={{ flex: 1 }}>
                <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {isNullOrEmpty(chartData.liabilityCoverage) && (
                    <ComingSoonCover noData={true} style={{ marginTop: 35 }} />
                  )}
                  <TotalLCoverChart data={chartData.liabilityCoverage} />
                </Box>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                m: 1,
                height: { xs: 'auto', sm: 'calc(100% - 12px)' },
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'visible'
              }}
            >
              {!loading && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 999999,
                    py: 1,
                    px: 2,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Typography fontSize={'10px'} color={'text.secondary'}>
                    Trend
                  </Typography>
                  <Switch size="small" checked={trendShow} onChange={(e) => onChangeTrend(e.target.checked)} />
                </Box>
              )}

              {((isNullOrEmpty(_chartData.InsurancePremium) && !trendShow) ||
                (isNullOrEmpty(_chartData.InsurancePremiumHistory) && trendShow) ||
                (isNullOrEmpty(_chartData.AggregatedPropertyValue) && trendShow)) &&
                !loading && <ComingSoonCover noData={true} style={{ marginTop: 35 }} />}

              <CardHeader
                title={isAdvisor ? 'Total Insurance Premiums Under Advisement' : 'Total Insurance Premiums By Type'}
              />

              {trendShow ? (
                <FullScreenChart
                  withTrend
                  isEmpty={
                    isNullOrEmpty(_chartData.InsurancePremiumHistory) ||
                    isNullOrEmpty(chartData.AggregatedPropertyValue)
                  }
                >
                  <TotalInsuranceColumnChart
                    data1={_chartData.InsurancePremiumHistory}
                    data2={_chartData.AggregatedPropertyValue}
                    label={['Annual Premium', 'Aggregate Property Value']}
                    loading={loading}
                  />
                </FullScreenChart>
              ) : (
                <TotalInsuranceChart data={_chartData.InsurancePremium} loading={loading} />
              )}
            </Card>
          </Grid>

          {isAdvisor && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{ m: 1, height: { xs: 'auto', sm: 'calc(100% - 12px)' }, display: 'flex', flexDirection: 'column' }}
                >
                  {isNullOrEmpty(_chartData.totalPropertyCoverage) && !loading && (
                    <ComingSoonCover noData={true} style={{ marginTop: 20 }} />
                  )}
                  <CardHeader title="Total Property Coverage Under Advisement" />
                  <TotalLCoverChart data={_chartData.totalPropertyCoverage} loading={loading} />
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                {isLoading ? (
                  <Card
                    sx={{ m: 1, height: { xs: 'auto', sm: 'calc(100% - 12px)' }, display: 'flex', flexDirection: 'column' }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                      <CircularProgress />
                    </Box>
                  </Card>
                ) : (
                  <Stack sx={{ m: 1, height: 'calc(100% - 12px)', justifyContent: 'space-between' }}>
                    <LabelWidget label="Total Clients" data={advisorData.totalClients} sx={{ height: '30%' }} />
                    <LabelWidget label="Total Families" data={advisorData.totalFamilies} sx={{ height: '30%' }} />
                    <LabelWidget
                      label="Average client per family"
                      data={advisorData.averageClients}
                      sx={{ height: '30%' }}
                    />
                  </Stack>
                )}
              </Grid>
            </>
          )}

        </Grid>
      )}
      <Card>
        <CardContent>{data.length > 0 ? renderData() : <EmptyContent />}</CardContent>
      </Card>
    </Stack>
  );
}
