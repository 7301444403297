import {useNavigate} from 'react-router-dom';
import {Link as ScrollLink} from 'react-scroll';
import {Link as RouterLink} from 'react-router-dom';
// mui
import {styled} from '@mui/material/styles';
import {Grid, Link, Divider, Container, Typography, Stack} from '@mui/material';
// routes
import {PATH_PAGE, PATH_DASHBOARD} from 'src/routes/paths';
// components
import LogoWithName from 'src/components/LogoWithName';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Opticus',
    children: [
      {name: 'About', href: PATH_PAGE.about},
      {name: 'Contact', href: PATH_PAGE.contact},
      {name: 'FAQ', href: PATH_PAGE.faqs},
      {name: 'Latest', href: PATH_DASHBOARD.blog.root}
    ]
  },
  {
    headline: 'Legal',
    children: [
      {name: 'Terms and Condition', href: PATH_PAGE.terms},
      {name: 'Privacy Policy', href: PATH_PAGE.privacy}
    ]
  },
  {
    headline: 'Contact',
    children: [{name: 'support@opticuslm.com', href: '#'}]
  }
];

const RootStyle = styled('div')(({theme}) => ({
  position: 'relative',
  background: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[900]
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const navigate = useNavigate();

  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{pt: 10}}>
        <Grid
          container
          justifyContent={{xs: 'center', md: 'space-between'}}
          sx={{textAlign: {xs: 'center', md: 'left'}}}
        >
          <Grid item xs={12} sx={{mb: 3}}>
            <ScrollLink to="move_top" spy smooth>
              <LogoWithName sx={{mx: {xs: 'auto', md: 'inherit'}}} />
            </ScrollLink>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{pr: {md: 5}}}>
              Opticus Life management brings clarity & efficiency to managing private risk.
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{xs: 'column', md: 'row'}} justifyContent="space-between">
              {LINKS.map((list) => {
                const {headline, children} = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{display: 'block'}}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} sx={{alignItems: 'center', pb: 5, mt: 10}}>
          <Typography
            component="p"
            variant="body2"
            sx={{
              fontSize: 13,
              textAlign: {xs: 'center', md: 'left'}
            }}
          >
            {`© 2021. All rights reserved`}
          </Typography>
          <Typography
            component="p"
            variant="body2"
            sx={{
              fontSize: 13,
              textAlign: {xs: 'center', md: 'left'}
            }}
          >
            {` | `}
          </Typography>
          <Typography
            component="p"
            variant="body2"
            sx={{
              fontSize: 13,
              textAlign: {xs: 'center', md: 'left'},
              cursor: 'pointer'
            }}
            onClick={() => navigate(PATH_PAGE.privacy)}
          >
            Legal
          </Typography>
          <Typography
            component="p"
            variant="body2"
            sx={{
              fontSize: 13,
              textAlign: {xs: 'center', md: 'left'}
            }}
          >
            {` | `}
          </Typography>
          <Typography
            component="p"
            variant="body2"
            sx={{
              fontSize: 13,
              textAlign: {xs: 'center', md: 'left'},
              cursor: 'pointer'
            }}
            onClick={() => navigate(PATH_PAGE.contact)}
          >
            Contact
          </Typography>
        </Stack>
      </Container>
    </RootStyle>
  );
}
