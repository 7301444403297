import axios from 'src/utils/axios';
// ----------------------------------------------------------------------
// get providers list
export async function getCarriers() {
  return await axios.get('canopy/carriers');
}

// Canopy carrier login
export async function carrierLogin(payload) {
  return await axios.post('/canopy/carrier-login', payload);
}

// Start carrier data pull (call many times)
/**
 * payload {pullId, pullJwt}
 */
export async function carrierPull(payload) {
  return await axios.post('/canopy/startPull', payload);
}

// Select mfa option
/**
 * payload {pullId, pullJwt, mfaOptionKey}
 */
export async function startVerify(payload) {
  return await axios.post('/canopy/start-verify', payload);
}

// Send mfa code
/**
 * payload {pullId, pullJwt, mfaValue}
 */
export async function confirmCode(payload) {
  return await axios.post('/canopy/confirm-code', payload);
}

// save pull insurance
/**
 * payload {pullId, policy: []}
 */
export async function saveCanopyInsurance(payload) {
  const res = await axios.post(`canopy/pull-data`, payload);
  return res?.data;
}

// -----------------------------------------------------

// get canopy credentials
export async function getAllCanopyCredentials() {
  return await axios.get(`canopy/credentials/all`);
}

// get canopy credential
export async function getCanopyCredential(carrierId) {
  return await axios.get(`canopy/credentials/${carrierId}`);
}

// update canopy credentials
export async function addUpdateCanopyCredentials(data) {
  return await axios.put(`canopy/credentials`, data);
}

// delete canopy credentials
export async function deleteCanopyCredentials(carrierId) {
  return await axios.delete(`canopy/credentials/${carrierId}`);
}
