import {combineReducers} from 'redux';
// slices
import contactReducer from './slices/contact';
import assetReducer from './slices/asset';
import insuranceReducer from './slices/insurance';
import orgReducer from './slices/organization';
import blogReducer from './slices/blog';
import ledgerReducer from './slices/ledger';
import calendarReducer from './slices/calendar';
import fileReducer from './slices/file';
import searchReducer from './slices/search';
import clientReducer from './slices/client';
import familyGroup from './slices/familyGroup';
import family from './slices/family';

// ----------------------------------------------------------------------

const appReducer = combineReducers({
  contact: contactReducer,
  asset: assetReducer,
  insurance: insuranceReducer,
  organization: orgReducer,
  blog: blogReducer,
  ledger: ledgerReducer,
  calendar: calendarReducer,
  file: fileReducer,
  search: searchReducer,
  client: clientReducer,
  familyGroup: familyGroup,
  family: family
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export {rootReducer};
