import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  initiated: false,
  error: null,
  contacts: []
};

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACTS
    setContacts(state, action) {
      state.isLoading = false;
      state.initiated = true;
      state.contacts = action.payload;
    },

    // ADD NEW CONTACT
    addContact(state, action) {
      state.isLoading = false;
      state.contacts = [...state.contacts, action.payload];
    },

    // UPDATE CONTACT
    updateContactAction(state, action) {
      state.isLoading = false;
      state.contacts = [...state.contacts.filter((item) => item.id !== action.payload.id), action.payload];
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {stopLoading, updateContactAction} = slice.actions;

// ----------------------------------------------------------------------

export function getContacts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/user-contacts');
      const contacts = response.data;
      dispatch(slice.actions.setContacts(contacts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addContact(data) {
  return async (dispatch) => {
    const payload = {...data};
    dispatch(slice.actions.startLoading());

    const result = await axios.post('/user-contacts', payload);
    const response = await axios.get('/user-contacts');
    const contacts = response.data;
    dispatch(slice.actions.setContacts(contacts));

    return result;
  };
}

export function updateContact(data, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const result = await axios.put(`/user-contacts/${id}`, data);

    const response = await axios.get('/user-contacts');
    dispatch(slice.actions.setContacts(response.data));

    return result;
  };
}

export function deleteContact(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const result = await axios.delete(`/user-contacts/${id}`);

    const response = await axios.get('/user-contacts');
    const contacts = response.data;
    dispatch(slice.actions.setContacts(contacts));
    return result;
  };
}

export function respondToContactInvite(contactId, acceptStatus) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const result = await axios.put(`/user-contacts/invite-accpet/${contactId}?acceptStatus=${acceptStatus}`);

    dispatch(getContacts());
    return result;
  };
}
