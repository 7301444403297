import {Icon} from '@iconify/react';
import awardFill from '@iconify/icons-eva/award-fill';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import phoneOutline from '@iconify/icons-eva/phone-outline';
import shieldFill from '@iconify/icons-eva/shield-fill';
// routes
import {PATH_PAGE, PATH_DASHBOARD} from 'src/routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'About',
    icon: <Icon icon={alertCircleFill} {...ICON_SIZE} />,
    path: PATH_PAGE.about
  },
  {
    title: 'Contact',
    icon: <Icon icon={phoneOutline} {...ICON_SIZE} />,
    path: PATH_PAGE.contact
  },
  {
    title: 'FAQ',
    icon: <Icon icon={shieldFill} {...ICON_SIZE} />,
    path: PATH_PAGE.faqs
  },
  {
    title: 'Latest',
    icon: <Icon icon={awardFill} {...ICON_SIZE} />,
    path: PATH_DASHBOARD.blog.root
  }
];

export default menuConfig;
