import {Icon} from '@iconify/react';
import arrowIosUpwardFill from '@iconify/icons-eva/arrow-ios-upward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// mui
import {Paper, CardActionArea, Stack} from '@mui/material';
// hooks
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

export default function SettingExpand() {
  const {themeExpand, onToggleExpand} = useSettings();

  return (
    <CardActionArea sx={{color: 'primary.main', borderRadius: 1}}>
      <Paper
        onClick={onToggleExpand}
        sx={{
          p: 2.5,
          bgcolor: 'background.neutral'
        }}
      >
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-end"
          sx={{
            px: 1,
            pt: 1,
            mx: 'auto',
            width: 1,
            height: 40,
            borderRadius: 1,
            color: 'action.active',
            bgcolor: 'background.default',
            transition: (theme) => theme.transitions.create('height'),
            boxShadow: (theme) => theme.customShadows.z12,
            ...(themeExpand && {
              height: 60,
              color: 'primary.main'
            })
          }}
        >
          <Icon icon={themeExpand ? arrowIosUpwardFill : arrowIosDownwardFill} width={20} height={20} />
        </Stack>
      </Paper>
    </CardActionArea>
  );
}
