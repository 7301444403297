export const SUFFIX_LIST = ['Sr.', 'Jr.', 'II', 'III', 'IV', 'V'];
export const TITLE_LIST = [
  'Dr.',
  'Esq.',
  'Hon.',
  'Lady',
  'Lord',
  'Mr.',
  'Mrs.',
  'Ms.',
  'Msgr.',
  'Prof.',
  'Rev',
  'Rt.',
  'St.'
];
export const CONTACT_LIST = ['Email', 'Phone'];
// Calendar -------------------------------------------------------
export const NOTIFICATION_DAYS = [
  {value: 30, label: '30'},
  {value: 7, label: '7'},
  {value: 1, label: '1'}
];
// Assets ---------------------------------------------------------
export const ASSET_STATUS = [
  {value: 'active', label: 'Active'},
  {value: 'pending', label: 'Pending'},
  {value: 'divested', label: 'Divested'}
];
export const RE_TYPE = [
  {value: 'land', label: 'Land'},
  {value: 'residential', label: 'Residential'},
  {value: 'commercial', label: 'Commercial'},
  {value: 'industrial', label: 'Industrial'},
  {value: 'rented', label: 'Rented/Non-owned'}
];
export const OCCUPANCY_TYPE = {
  land: [
    {value: 'vacant', label: 'Vacant'},
    {value: 'hunting', label: 'Hunting'},
    {value: 'conservation', label: 'Conservation'}
  ],
  residential: [
    {value: 'primary-residence', label: 'Primary Residence'},
    {value: 'secondary-residence', label: 'Secondary Residence'},
    {value: 'seasonal-residence', label: 'Seasonal Residence'},
    {value: 'other-family-member-occupied', label: 'Other family member occupied'},
    {value: 'rented-to-others', label: 'Rented to others'}
  ],
  commercial: [
    {value: 'owner-occupied', label: 'Owner Occupied'},
    {value: 'leased-to-others', label: 'Leased to others'}
  ],
  industrial: [{value: 'industrial-operation', label: 'Industrial Operation'}]
};

export const getOccupancyTypeLabel = (key) => {
  const target = Object.values(OCCUPANCY_TYPE)
    .flat()
    .find((item) => item.value === key);
  return target ? target.label : ''; // Unknown
};

export const getRETypeLabel = (key) => {
  const target = RE_TYPE.find((item) => item.value === key);
  return target ? target.label : ''; // Unknown
};
export const RE_CONSTRUCTION_TYPE = [
  {value: 'modified-fire-resistive', label: 'Modified Fire Resistive'},
  {value: 'masonry-non-combustible', label: 'Masonry Non-Combustible'},
  {value: 'fire-resistive', label: 'Fire Resistive'},
  {value: 'frame', label: 'Frame'},
  {value: 'joisted-masonry', label: 'Joisted Masonry'},
  {value: 'non-cumbustible', label: 'Non-Cumbustible'}
];
export const getREConTypeLabel = (key) => {
  const target = RE_CONSTRUCTION_TYPE.find((item) => item.value === key);
  return target ? target.label : ''; // Unknown
};
export const RE_HOME_USAGE = [
  {value: 'primary', label: 'Primary'},
  {value: 'secondary', label: 'Secondary'},
  {value: 'rental', label: 'Rental'}
];
export const SAFETY_FEATURES = [
  {value: 'central-station-fire-alarm', label: 'Central Station Fire Alarm'},
  {value: 'central-station-burglar-alarm', label: 'Central Station Burglar Alarm'},
  {value: 'alarm-signal-continuity', label: 'Alarm Signal Continuity'},
  {value: 'firehouse-within-5miles', label: 'Firehouse within 5miles'},
  {value: 'closed-circuit-television', label: 'Closed Circuit Television'},
  {value: 'low-temperature-monitor', label: 'Low Temperature Monitor'},
  {value: 'hydrant-<-1,000feet', label: 'Hydrant < 1,000feet'},
  {value: 'perimeter-security', label: 'Perimeter Security'},
  {value: 'generator-permanent', label: 'Generator - Permanent'},
  {value: 'smoke-detectors', label: 'Smoke Detectors'},
  {value: '24-hour-security-guard', label: '24 Hour Security Guard'},
  {value: 'sump-pump-with-battery-back-up', label: 'Sump pump with battery back-up'},
  {value: 'sprinklers-internal', label: 'Sprinklers - Internal'},
  {value: 'biometric-security', label: 'Biometric Security'},
  {value: 'water-flow-alarm', label: 'Water Flow Alarm'},
  {value: 'sprinklers-external', label: 'Sprinklers - External'},
  {value: 'gated-community', label: 'Gated Community'},
  {value: 'water-flow-automatic-shutoff', label: 'Water Flow Automatic Shutoff'},
  {value: 'fire-response-plan', label: 'Fire Response Plan'},
  {value: 'caretaker-full-time', label: 'Caretaker - Full Time'},
  {value: 'caretaker-part-time', label: 'Caretaker - Part Time'},
  {value: 'lightning-protection', label: 'Lightning Protection'}
];

// hazard features
export const HAZARD_FEATURES = [
  {
    label: 'FIRE HAZARD ANALYSIS',
    values: [
      {value: 'aais_fire_protection_class_params', label: 'Fire Protection Score'},
      {value: 'nearest_fire_station', label: 'Nearest Fire Department'},
      {value: 'hydrant_params', label: 'Distance to Fire Hydrant'},
      {value: 'enhanced_wildfire', label: 'Wildfire Score'},
      {value: 'drought', label: 'Drought Score'},
      {value: 'drought_frequency_index', label: 'Drought Frequency'}
    ]
  },
  {
    label: 'FLOOD HAZARD ANALYSIS',
    values: [
      {value: 'coast_distance', label: 'Distance to Coastline'},
      {value: 'fema_all_flood', label: 'FEMA Flood Grade'},
      {value: 'fema_claims', label: 'Fema Claims'},
      {value: 'surge_max', label: 'SurgeMax'}
    ]
  },
  {
    label: 'STORM HAZARD ANALYSIS',
    values: [
      {value: 'enhanced_lightning_params', label: 'Thunderstorm'},
      {value: 'enhanced_lightning_params', label: 'Lightning'},
      {value: 'enhanced_wind_params', label: 'Wind'},
      {value: 'enhanced_hail_params', label: 'Hail'},
      {value: 'enhanced_hurricane_params', label: 'Hurricane'},
      {value: 'enhanced_tornado_params', label: 'Tornado'},
      {value: 'wind_born_debris', label: 'Wind Borne Debris Exposure'}
    ]
  },
  {
    label: 'EARTH MOVEMENT ANALYSIS',
    values: [
      {value: 'earthquake', label: 'Earthquake Rating'},
      {value: 'designated_fault', label: 'Designated Fault Status'},
      {value: 'fault_earthquake', label: 'Fault Rating'},
      {value: 'sinkhole', label: 'Sinkhole'},
      {value: 'sinkhole_risk_params', label: 'Sinkhole Susceptibility'},
      {value: 'landslide', label: 'Landslide'},
      {value: 'tsunami', label: 'Tsunami'}
    ]
  },
  {
    label: 'ANCILLARY RISK ANALYSIS',
    values: [
      {value: 'nuclear_site_nearest', label: 'Nuclear'},
      {value: 'volcano_nearest', label: 'Volcano'},
      {value: 'radon', label: 'Radon'},
      {value: 'superfund', label: 'Superfund'},
      {value: 'brownfield', label: 'Brownfield'},
      {value: 'crime', label: 'Crime'}
    ]
  }
];

// crimes
export const CRIMES = [
  {value: 'murder', label: 'Murder'},
  {value: 'forcible_rape', label: 'Forcible Rape'},
  {value: 'forcible_robbery', label: 'Forcible Robbery'},
  {value: 'aggravated_assault', label: 'Aggravated Assault'},
  {value: 'burglary', label: 'Burglary'},
  {value: 'larceny', label: 'Larceny'},
  {value: 'motor_vehicle_theft', label: 'Motor Vehicle Theft'}
];

// fema
export const FEMA_FEATURES = [
  {value: 'Fld_Zone', label: 'Flood Zone'},
  {value: 'dfirm_id', label: 'Date of Zone Determination'},
  {value: '', label: 'Flood Elevation'},
  {value: 'static_bfe', label: 'Base Flood Elevation'},
  {value: '', label: 'Map Effective Date'},
  {value: '', label: 'Map Number'},
  {value: '', label: 'Map Link'},
  {value: '', label: 'Building Elevation'},
  {value: '', label: 'Date of Elevation Certificate'}
];

export const HURRICANE_PROTECTION = [
  {value: 'impact-glass', label: 'Impact Glass'},
  {value: 'shutters,roll-down', label: 'Shutters Roll down'},
  {value: 'shutters,traditional', label: 'Shutters Traditional'},
  {value: 'storm-panel/fabric', label: 'Storm Panel/Fabric'},
  {value: 'none', label: 'None'}
];
export const getHuProtectionLabel = (key) => {
  const target = HURRICANE_PROTECTION.find((item) => item.value === key);
  return target ? target.label : ''; // Unknown
};
export const ROOF_SHAPE = [
  {value: 'hip', label: 'Hip'},
  {value: 'flat', label: 'Flat'},
  {value: 'other', label: 'Other'}
];
export const getRoofShapeLabel = (key) => {
  const target = ROOF_SHAPE.find((item) => item.value === key);
  return target ? target.label : ''; // Unknown
};
export const ROOF_CONNECTION = [
  {value: 'toe-nails', label: 'Toe Nails'},
  {value: 'clips', label: 'Clips'},
  {value: 'single-straps', label: 'Single Straps'},
  {value: 'double-wraps', label: 'Double Straps'},
  {value: 'structural-anchor', label: 'Structural Anchor'}
];
export const getRoofConnectionLabel = (key) => {
  const target = ROOF_CONNECTION.find((item) => item.value === key);
  return target ? target.label : ''; // Unknown
};
export const EARTHQUAKE_PROTECTION = [
  {value: 'modern-code', label: 'Modern code'},
  {value: 'none', label: 'None'},
  {value: 'retrofit', label: 'Retrofit'}
];
export const FOUNDATION_TYPE = [
  {value: 'slab', label: 'Slab'},
  {value: 'cantilever', label: 'Cantilever'},
  {value: 'caisson', label: 'Caisson'},
  {value: 'cripple-wall', label: 'Cripple Wall'},
  {value: 'stem-wall', label: 'Stem Wall'},
  {value: 'post&pier', label: 'Post & Pier'},
  {value: 'raised-wall-masonry', label: 'Raised Wall Masonry'},
  {value: 'other', label: 'Other'}
];
export const getFoundationTypeLabel = (key) => {
  const target = FOUNDATION_TYPE.find((item) => item.value === key);
  return target ? target.label : ''; // Unknown
};
export const FLOOD_ZONE = [
  {value: 'high-risk,-coastal(v,-v1-v10)', label: 'High Risk, Coastal(V, V1-V10)'},
  {value: 'none', label: 'None'},
  {value: 'high-risk,-surface/inland(a,-a1-a10,-ae)', label: 'High Risk, Surface/Inland(A, A1-A10, AE)'}
];
export const getFloodZoneLabel = (key) => {
  const target = FLOOD_ZONE.find((item) => item.value === key);
  return target ? target.label : ''; // Unknown
};
// Assets - Aircraft
export const AC_TYPE = [
  {value: 'single-engine', label: 'Single Engine'},
  {value: 'twin-engine', label: 'Twin Engine'},
  {value: 'turboprop', label: 'Turboprop'},
  {value: 'jet', label: 'Jet'}
];
export const AC_MODEL = [
  {value: 'b1900', label: 'Beechcraft 1900'},
  {value: 'corvette', label: 'SN.601 Corvette'},
  {value: 'bb58', label: 'Beechcraft Baron / 58 Baron'},
  {value: 'alenia600', label: 'Alenia ATR 72-600'},
  {value: 'aa-55', label: 'Beechcraft Baron / 55 Baron'},
  {value: 'alenia500', label: 'Alenia ATR 72-500'}
];
export const AC_STORE_TYPE = [
  {value: 'tied', label: 'Tied-down'},
  {value: 'secure', label: 'Secure Building'},
  {value: 'untied', label: 'Outside, un-tied'}
];
// Assets - Automobile
export const AU_TYPE = [
  {value: 'van', label: 'Van'},
  {value: 'sedan', label: 'Sedan'},
  {value: 'sports-car', label: 'Sports Car'},
  {value: 'diesel', label: 'Diesel'},
  {value: 'luxury-car', label: 'Luxury Car'},
  {value: 'coupe', label: 'Coupe'},
  {value: 'wagon', label: 'Wagon'},
  {value: 'truck', label: 'Truck'},
  {value: 'convertible', label: 'Convertible'},
  {value: 'suv', label: 'SUV'},
  {value: 'crossover', label: 'Crossover'},
  {value: 'hybrid/electric', label: 'Hybrid/Electric'},
  {value: 'golf-cart', label: 'Golf Cart'},
  {value: 'atv', label: 'ATV'},
  {value: 'motorcycle/moped', label: 'Motorcycle/Moped'}
];
// Assets - Watercraft
export const WA_TYPE = [
  {value: 'banana-boats', label: 'Banana'},
  {value: 'bowrider-boats', label: 'Bowrider'},
  {value: 'cabin-cruiser-boats', label: 'Cabin Cruiser'},
  {value: 'catamaran-boats', label: 'Catamaran'},
  {value: 'centre-console-boats', label: 'Centre Console'},
  {value: 'cuddy-cabins-boats', label: 'Cuddy Cabins'},
  {value: 'deck-boats', label: 'Deck'},
  {value: 'dinghy-boats', label: 'Dinghy'},
  {value: 'fishing-boats', label: 'Fishing'},
  {value: 'game-boats', label: 'Game'},
  {value: 'houseboats', label: 'House'},
  {value: 'jet-boats', label: 'Jet'},
  {value: 'lifeboats', label: 'Life'},
  {value: 'motor-yacht-boats', label: 'Motor Yacht'},
  {value: 'personal-watercraft-(pwc)-boats', label: 'Personal Watercraft (PWC)'},
  {value: 'pontoon-boat', label: 'Pontoon'},
  {value: 'runabout-boats', label: 'Runabout'},
  {value: 'sedan-bridge-boat', label: 'Sedan Bridge'},
  {value: 'trawler-boats', label: 'Trawler'},
  {value: 'wakeboard/-ski-boats', label: 'Wakeboard/ Ski'}
];
export const getAsWALabel = (key) => {
  const target = WA_TYPE.find((item) => item.value === key);
  return target ? target.label : ''; // Unknown
};
export const WA_MODEL = [
  {maker: 'Bennington Pontoons', model: '20 LCR'},
  {maker: 'Bennington Pontoons', model: '20 LSB'},
  {maker: 'Alumacraft Boat Co', model: '1232'},
  {maker: 'Alumacraft Boat Co', model: '1032'}
];
export const WA_MOORING_TYPE = [
  {value: 'building', label: 'Building'},
  {value: 'docked', label: 'Docked'},
  {value: 'anchored', label: 'Anchored'},
  {value: 'lifted', label: 'Lifted'},
  {value: 'trailer', label: 'Trailer'}
];
// Assets - Collection
export const CO_CLASS = [
  {value: 'coins-collectibles', label: 'Coins & Collectibles'},
  {value: 'wine-spirits', label: 'Wine & Spirits'},
  {value: 'art-antiques', label: 'Art & Antiques'},
  {value: 'jewelry-watches', label: 'Jewelry & Watches'},
  {value: 'musical-instruments', label: 'Musical Instruments'},
  {value: 'silver-porcelain', label: 'Silver & Porcelain'},
  {value: 'furs', label: 'Furs'},
  {value: 'other', label: 'Other'}
];
// Assets - Equine
export const EQ_BREED_TYPE = [
  {value: 'gelderland', label: 'Gelderland'},
  {value: 'nez-perce', label: 'Nez Perce'},
  {value: 'american-saddlebred', label: 'American Saddlebred'},
  {value: 'andalusian', label: 'Andalusian'},
  {value: 'ardennais', label: 'Ardennais'},
  {value: 'hanoverian', label: 'Hanoverian'},
  {value: 'appaloosa', label: 'Appaloosa'},
  {value: 'knabstrupper', label: 'Knabstrupper'},
  {value: 'orlov-trotter', label: 'Orlov Trotter'},
  {value: 'florida-cracker', label: 'Florida Cracker'},
  {value: 'shire', label: 'Shire'},
  {value: 'thoroughbred', label: 'Thoroughbred'},
  {value: 'dole-gudbrandsda', label: 'Dole Gudbrandsda'},
  {value: 'fjord', label: 'Fjord'},
  {value: 'percheron', label: 'Percheron'},
  {value: 'shetland-pony', label: 'Shetland Pony'},
  {value: 'morgan', label: 'Morgan'},
  {value: 'dutch-warmblood', label: 'Dutch Warmblood'},
  {value: 'lusitano', label: 'Lusitano'},
  {value: 'mustang', label: 'Mustang'},
  {value: 'other', label: 'Other'},
  {value: 'azteca', label: 'Azteca'},
  {value: 'canadian', label: 'Canadian'},
  {value: 'anglo-arabian', label: 'Anglo-Arabian'},
  {value: 'barb', label: 'Barb'},
  {value: 'gronigen', label: 'Gronigen'},
  {value: 'clydesdale', label: 'Clydesdale'},
  {value: 'falabella', label: 'Falabella'},
  {value: 'akhal-teke', label: 'Akhal-Teke'},
  {value: 'cob', label: 'Cob'},
  {value: 'exmoor-pony', label: 'Exmoor Pony'},
  {value: 'belgian', label: 'Belgian'},
  {value: 'arabian', label: 'Arabian'},
  {value: 'finnish', label: 'Finnish'},
  {value: 'brumby', label: 'Brumby'},
  {value: 'galiceno', label: 'Galiceno'},
  {value: 'dartmoor-pony', label: 'Dartmoor Pony'},
  {value: 'haflinger', label: 'Haflinger'},
  {value: 'standardbred', label: 'Standardbred'},
  {value: 'rocky-mountain', label: 'Rocky Mountain'},
  {value: 'carmargue', label: 'Carmargue'},
  {value: 'holsteiner', label: 'Holsteiner'},
  {value: 'hucul', label: 'Hucul'},
  {value: 'icelandic', label: 'Icelandic'},
  {value: 'tennese-walking', label: 'Tennese Walking'},
  {value: 'fresian', label: 'Fresian'},
  {value: 'gypsy', label: 'Gypsy'},
  {value: 'peruvian-paso', label: 'Peruvian Paso'},
  {value: 'trakehner', label: 'Trakehner'},
  {value: 'westphalian', label: 'Westphalian'}
];
export const EQ_CLASS = [
  {value: 'mare', label: 'Mare'},
  {value: 'yearling', label: 'Yearling'},
  {value: 'filly', label: 'Filly'},
  {value: 'foal', label: 'Foal'},
  {value: 'colt', label: 'Colt'},
  {value: 'gelding', label: 'Gelding'},
  {value: 'stallion', label: 'Stallion'}
];
export const EQ_USAGE = [
  {value: 'farm', label: 'Farm'},
  {value: 'ride', label: 'Ride'},
  {value: 'breed', label: 'Breed'},
  {value: 'polo', label: 'Polo'},
  {value: 'therapy', label: 'Therapy'},
  {value: 'race', label: 'Race'},
  {value: 'show', label: 'Show'}
];

// Organizations ---------------------------------------------------------
export const ORGANIZATION_STATUS = [
  {value: 'active', label: 'Active'},
  {value: 'pending', label: 'Pending'},
  {value: 'dormant', label: 'Dormant'}
];
// Asset
export function getOrgStatusLabel(key) {
  const target = ORGANIZATION_STATUS.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
// Organizations - Board
export const BD_ROLES = [
  {value: 'chair-or-president', label: 'Chair or President'},
  {value: 'secretary', label: 'Secretary'},
  {value: 'treasurer', label: 'Treasurer'},
  {value: 'vice-chair-or-vice-president', label: 'Vice chair or Vice President'}
];
export const BD_PROFIT_STATUS = [
  {value: 'non-profit', label: 'Non Profit'},
  {value: 'profit', label: 'For Profit'},
  {value: 'home-owner', label: 'Home owner Association'}
];
export const getProfLabel = (key) => {
  const target = BD_PROFIT_STATUS.find((item) => item.value === key);
  return target ? target.label : ''; // Unknown
};
// Organizations - Entity
export const EN_TYPE = [
  {value: 'llc', label: 'LLC'},
  {value: 's-corp', label: 'S-Corp'},
  {value: 'c-corp', label: 'C-Corp'},
  {value: 'llp', label: 'LLP'}
];
// Organizations - Club
export const CL_TYPE = [
  {value: 'professional-organization', label: 'Professional Organization'},
  {value: 'marina/harbour', label: 'Marina / Harbour'},
  {value: 'private-club', label: 'Private Club'},
  {value: 'other', label: 'Other'},
  {value: 'member-organization', label: 'Member Organization'}
];
// Organizations - Trust
export const TR_TYPE = [
  {value: 'trust', label: 'Trust'},
  {value: 'estate-of', label: 'Estate of'}
];

export const TR_SUB_TYPE = [
  {value: 'revcable_trust', label: 'Revocable Trust - General'},
  {value: 'irrevocable_trust', label: 'Irrevocable Trust - General'},
  {value: 'testamentary', label: 'Testamentary - General'},
  {value: 'asset_protection', label: 'Asset Protection - General'},
  {value: 'charitable_trust', label: 'Charitable Trust - General'},
  {value: 'constructive_trust', label: 'Constructive Trust - General'},
  {value: 'special_needs_trust', label: 'Special Needs Trust - General'},
  {value: 'spendthrift_trust', label: 'Spendthrift Trust - General'},
  {value: 'tax_by_pass_trust', label: 'Tax By-pass Trust - General'},
  {value: 'totten_trust', label: 'Totten Trust - General'},
  {value: 'blind_trust', label: 'Blind Trust - General'},
  {value: 'insurance_trust', label: 'Insurance Trust - General'},
  {value: 'spousal_lt_access_trust', label: 'Spousal Lifetime Access Trust (SLAT)'},
  {value: 'marital_trust', label: 'Marital Trust'},
  {value: 'gst', label: 'Generation Skipping Trust (GST)'},
  {value: 'cst', label: 'Credit Shelter Trust'},
  {value: 'qtip', label: 'Qualified Terminable Interest Property Trusts (QTIP)'},
  {value: 'grat', label: 'Grantor Retained Annuity Trust (GRAT)'},
  {value: 'dynasty_trust', label: 'Dynasty Trust'},
  {value: 'lit', label: 'Wealth Replacement Life Insurance Trust (LIT)'}
];

export const getClubType = (key) => {
  const target = CL_TYPE.find((item) => item.value === key);
  return target ? target.label : ''; // Unknown
};
// Insurance ---------------------------------------------------------------
export const INSURANCE_STATUS = [
  {value: 'active', label: 'Active'},
  {value: 'pending', label: 'Pending'},
  {value: 'dormant', label: 'Dormant'}
];

export const CLAIM_STATUS = [
  {value: 'open', label: 'Open'},
  {value: 'closed', label: 'Closed'}
];
// Insurance - Real Estate
export const IN_RE_TYPE = [
  {value: 'home-owners', label: 'Homeowners'},
  {value: 'landlord', label: 'Landlord'},
  {value: 'construction', label: 'Construction'},
  {value: 'tenants', label: 'Tenants'},
  {value: 'co-co', label: 'Condominium/Cooperative'},
  {value: 'fl-pri', label: 'Flood, Primary', noLCoverage: true},
  {value: 'fl-ex', label: 'Flood, Excess', noLCoverage: true},
  {value: 'li-only', label: 'Liability-Only'},
  {value: 'eq', label: 'Earthquake'},
  {value: 'wild-fire', label: 'Wildfire'},
  {value: 'hurricane', label: 'Hurricane'}
];
// Insurance - Collection
export const IN_CO_TYPE = [
  {value: 'coins-collectibles', label: 'Coins & Collectibles'},
  {value: 'wine-spirits', label: 'Wine & Spirits'},
  {value: 'art-antiques', label: 'Art & Antiques'},
  {value: 'jewelry-watches', label: 'Jewelry & Watches'},
  {value: 'musical-instruments', label: 'Musical Instruments'},
  {value: 'silver-porcelain', label: 'Silver & Porcelain'},
  {value: 'furs', label: 'Furs'},
  {value: 'other', label: 'Other'}
];

// Insurance - Automobile
export const IN_AU_TYPE = [
  {value: 'priv-pass', label: 'Private Passenger'},
  {value: 'co-spec', label: 'Collector/Specialty'},
  {value: 're-veh', label: 'Recreational Vehicle'},
  {value: 'motor', label: 'Motorcycle/ATV'},
  {value: 'NAMED_NON_OWNER', label: 'Hired & Non-Owned'},
  {value: 'COMMERCIAL_AUTO', label: 'Commercial'}
];
export const FP_TORT_OPTIONS = [
  {value: 'full-tort', label: 'Full Tort /No Threshold'},
  {value: 'limited-tort', label: 'Limited Tort/Threshold Applies'},
  {value: 'not', label: 'Not Applicable'}
];
export const getFPTOptionLabel = (key) => {
  const target = FP_TORT_OPTIONS.find((item) => item.value === key);
  return target ? target.label : ''; // Unknown
};
export const AU_DEDUCTIBLE_OPTION = [
  {value: 0, label: '$0'},
  {value: 50, label: '$50'},
  {value: 100, label: '$100'},
  {value: 200, label: '$200'},
  {value: 250, label: '$250'},
  {value: 500, label: '$500'},
  {value: 1000, label: '$1,000'},
  {value: 2000, label: '$2,000'},
  {value: 2500, label: '$2,500'},
  {value: 5000, label: '$5,000'},
  {value: 10000, label: '$10,000'}
];
export const AU_PAYMENT_OPTION = [
  {value: 'cash', label: 'Depreciated Cash Value'},
  {value: 'agreed', label: 'Agreed Value'},
  {value: 'replacement', label: 'Replacement Cost'}
];
export const getAUPaymentOptionLabel = (key) => {
  const target = AU_PAYMENT_OPTION.find((item) => item.value === key);
  return target ? target.label : ''; // Unknown
};
// Insurance - Aircraft
export const IN_AI_TYPE = [
  {value: 'public-liability', label: 'Public liability'},
  {value: 'combined-single-limit', label: 'Combined Single Limit'},
  {value: 'passenger-liability', label: 'Passenger liability'},
  {value: 'ground-risk-hull-insurance-not-in-motion', label: 'Ground risk hull insurance not in motion'}
];
export const AI_STORAGE_TYPE = [
  {value: 'tie-down', label: 'Tie-down'},
  {value: 'private', label: 'Hangar: Private'},
  {value: 'shared', label: 'Hangar: Shared'}
];
export const getAIStorageTypeLabel = (key) => {
  const target = AI_STORAGE_TYPE.find((item) => item.value === key);
  return target ? target.label : ''; // Unknown
};
// Insurance - Watercraft
export const IN_WA_TYPE = [
  {value: 'per-spec', label: 'Personal Watercraft /Specialty'},
  {value: 'bo-ya', label: 'Boat/Yacht'},
  {value: 'mg-ya', label: 'Mega Yacht'}
];
// Insurance - Umbrella
export const IN_UM_TYPE = [
  {value: 'per-um', label: 'Personal Umbrella'},
  {value: 'sec-layer', label: 'Second Layer'},
  {value: 'trd-layer', label: 'Third Layer'},
  {value: 'com-um', label: 'Commercial Umbrella'}
];
// Insurance - Equine
export const IN_EQ_TYPE = [
  {value: 'fm-ranch', label: 'Farm & Ranch'},
  {value: 'eq-mort', label: 'Equine Mortality'}
];
// Insurance - Claim
export const IN_TYPE = [
  {value: 'realEstate', label: 'Real Estate'},
  {value: 'collection', label: 'Collections'},
  {value: 'automobile', label: 'Automobiles'},
  {value: 'watercraft', label: 'Watercraft'},
  {value: 'aircraft', label: 'Aircraft'},
  {value: 'equine', label: 'Equine'},
  {value: 'misc', label: 'Misc'},
  {value: 'umbrella', label: 'Umbrella'}
];

export const CLAIM_TYPE = [
  {value: 'property', label: 'Property'},
  {value: 'liability', label: 'Liability'},
  {value: 'uninsured3Party', label: 'Uninsured 3rd Party'},
  {value: 'other', label: 'Other'}
];

// functions *******************************************************
// Insurance
export function getInStatusLabel(key) {
  const target = [...INSURANCE_STATUS, ...CLAIM_STATUS].find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
export function getInSubtypeLabel(key, tab) {
  let types = [];
  if (tab === 'realEstate') {
    types = [...IN_RE_TYPE];
  } else if (tab === 'collection') {
    types = [...IN_CO_TYPE];
  } else if (tab === 'automobile') {
    types = [...IN_AU_TYPE];
  } else if (tab === 'watercraft') {
    types = [...IN_WA_TYPE];
  } else if (tab === 'aircraft') {
    types = [...IN_AI_TYPE];
  } else if (tab === 'umbrella') {
    types = [...IN_UM_TYPE];
  } else if (tab === 'equine') {
    types = [...IN_EQ_TYPE];
  } else if (tab === 'claim') {
    types = [...IN_TYPE];
  }

  const target = types.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
export function getClaimTypeLabel(key) {
  const target = CLAIM_TYPE.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
export function getInRESubtypeLabel(key) {
  const target = IN_RE_TYPE.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
export function getInCOSubtypeLabel(key) {
  const target = IN_CO_TYPE.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
export function getInAUSubtypeLabel(key) {
  const target = IN_AU_TYPE.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
export function getInWASubtypeLabel(key) {
  const target = IN_WA_TYPE.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
export function getInAISubtypeLabel(key) {
  const target = IN_AI_TYPE.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
export function getInUMSubtypeLabel(key) {
  const target = IN_UM_TYPE.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
export function getInEQSubtypeLabel(key) {
  const target = IN_EQ_TYPE.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
// Asset
export function getAssetStatusLabel(key) {
  const target = ASSET_STATUS.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
export function getCraftModelLabel(key) {
  const target = AC_MODEL.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
export function getCraftTypeLabel(key) {
  const target = AC_TYPE.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
export function getVehicleTypeLabel(key) {
  const target = AU_TYPE.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
export function getCollectionClassLabel(key) {
  const target = CO_CLASS.find((item) => item.value === key);

  return target ? target.label : ''; // Unknown
}
