// utils
import {getHazardHubData, getZillowData} from 'src/api';

// get zillow public records
export const getZillowPublicRecords = async (
  values,
  setZillowImportLoading,
  address,
  enqueueSnackbar,
  setFieldValue,
  setIsImportedFromZillow,
  setZillowData = () => {}
) => {
  try {
    setZillowImportLoading(true);
    const {data} = await getZillowData(address);
    setZillowImportLoading(false);

    if (!data.results || !!data.results.error) {
      enqueueSnackbar('Get Zillow record error: ' + data?.results?.error ?? '', {variant: 'error'});
    } else {
      const {taxHistory, livingAreaValue, dateSold, price, priceHistory, yearBuilt, zestimate, big} = data.results?.[0];
      const soldPrice = priceHistory?.find((item) => item?.event === 'Sold')?.price || '';
      setFieldValue('marketValue', zestimate || price);
      setFieldValue('proTax', !!taxHistory?.length ? taxHistory[0]?.taxPaid || '' : '');
      setFieldValue('squareFootage', livingAreaValue);
      if (!!dateSold) setFieldValue('datePurchased', new Date(dateSold));
      setFieldValue('purchasePrice', soldPrice);
      setFieldValue('builtYear', yearBuilt);
      if (big) {
        setFieldValue('imageFiles', [
          ...values.imageFiles,
          ...big.slice(0, 10).map((item, index) => ({
            id: new Date().getTime() + index,
            documentTitle: 'zillow image',
            documentLink: item.url,
            documentType: 'realEstate',
            fileType: 'imageFiles',
            subType: 'realEstate'
          }))
        ]);
      }

      setZillowData({
        marketValue: zestimate || price,
        proTax: !!taxHistory?.length ? taxHistory[0]?.taxPaid || '' : '',
        squareFootage: livingAreaValue,
        datePurchased: new Date(dateSold),
        purchasePrice: soldPrice,
        builtYear: yearBuilt,
        imageFiles: big.slice(0, 10).map((item, index) => ({
          id: new Date().getTime() + index,
          documentTitle: 'zillow image',
          documentLink: item.url,
          documentType: 'realEstate',
          fileType: 'imageFiles',
          subType: 'realEstate'
        }))
      });
      setIsImportedFromZillow(true);
      enqueueSnackbar('Zillow Record Fetched Successfully.', {variant: 'success'});
    }
  } catch (e) {
    enqueueSnackbar(e.message || 'Get Zillow record error.', {variant: 'error'});
    setZillowImportLoading(false);
  }
};

// get hazardhub records
export const getHazardHubRecords = async (
  setHazardHubImportLoading,
  address,
  enqueueSnackbar,
  setFieldValue,
  setIsImportedFromHazardHub
) => {
  try {
    setHazardHubImportLoading(true);
    const {data} = await getHazardHubData(address);
    setHazardHubImportLoading(false);
    if (!!data.error) {
      enqueueSnackbar(data.error || 'Get HazardHub record error.', {variant: 'error'});
    } else {
      const hazardDataFromApi = {
        aais_fire_protection_class_params: {
          class: data.aais_fire_protection_class_params.class
        },
        nearest_fire_station: {
          distance: data.drive_distance_fire_station.distance,
          duration: data.drive_time_fire_station.duration,
          distance_unit: data.drive_distance_fire_station.units,
          duration_unit: data.drive_time_fire_station.units
        },
        hydrant_params: {
          distance: data.hydrant_params.distance_from_nearest_hydrant
        },
        enhanced_wildfire: {
          score: data.enhanced_wildfire.score,
          text: data.enhanced_wildfire.text
        },
        drought: {
          score: data.drought.score,
          text: data.drought.text
        },
        drought_frequency_index: {
          score: data.drought_frequency_index.score,
          text: data.drought_frequency_index.text
        },
        coast_distance: {
          distance: data.coast_distance.low_res_distance.value,
          distance_unit: data.coast_distance.low_res_distance?.units
        },
        fema_all_flood: {
          score: data.fema_all_flood.score,
          text: data.fema_all_flood.text
        },
        fema_claims: {
          total: data.fema_claims?.total
        },
        surge_max: {
          score: data.surge_max.score,
          text: data.surge_max.text
        },
        enhanced_lightning_params: {
          score: data.enhanced_lightning_params.score,
          text: data.enhanced_lightning_params.text,
          scale: data.enhanced_lightning_params.scale
        },
        enhanced_wind_params: {
          score: data.enhanced_wind_params.score,
          text: data.enhanced_wind_params.text,
          scale: data.enhanced_wind_params.scale
        },
        enhanced_hail_params: {
          score: data.enhanced_hail_params.score,
          text: data.enhanced_hail_params.text,
          scale: data.enhanced_hail_params.scale
        },
        enhanced_hurricane_params: {
          score: data.enhanced_hurricane_params.score,
          text: data.enhanced_hurricane_params.text,
          scale: data.enhanced_hurricane_params.scale
        },
        enhanced_tornado_params: {
          score: data.enhanced_tornado_params.score,
          text: data.enhanced_tornado_params.text,
          scale: data.enhanced_tornado_params.scale
        },
        wind_born_debris: {
          score: data.wind_born_debris.score,
          text: data.wind_born_debris.text
        },
        earthquake: {
          score: data.earthquake.score,
          text: data.earthquake.text
        },
        designated_fault: {
          score: data.designated_fault.score,
          text: data.designated_fault.text
        },
        fault_earthquake: {
          score: data.fault_earthquake.score,
          text: data.fault_earthquake.text
        },
        sinkhole: {
          score: data.sinkhole.score,
          text: data.sinkhole.text
        },
        sinkhole_risk_params: {
          score: data.sinkhole_risk_params.sinkhole_susceptibility_grade,
          text: data.sinkhole_risk_params.closed_depression_risk
        },
        landslide: {
          score: data.landslide.score,
          text: data.landslide.text
        },
        tsunami: {
          score: data.tsunami.score,
          text: data.tsunami.text
        },
        nuclear_site_nearest: {
          distance: data.nuclear_site_nearest.distance?.value ?? null,
          distance_unit: data.nuclear_site_nearest.distance?.units
        },
        volcano_nearest: {
          name: data.volcano_nearest.volcano_name?.value ?? null,
          distance: data.volcano_nearest.volcano_distance?.value ?? null
        },
        radon: {
          score: data.radon.score,
          text: data.radon.text
        },
        superfund: {
          score: data.superfund.score,
          text: data.superfund.text,
          sitename: data.superfund.site_name
        },
        brownfield: {
          score: data.brownfield.score,
          text: data.brownfield.text,
          sitename: data.brownfield.site_name
        },
        crime: {
          score: data.crime.score,
          text: data.crime.text
        },
        murder: {
          score: data.murder.score,
          text: data.murder.text
        },
        forcible_rape: {
          score: data.forcible_rape.score,
          text: data.forcible_rape.text
        },
        forcible_robbery: {
          score: data.forcible_robbery.score,
          text: data.forcible_robbery.text
        },
        aggravated_assault: {
          score: data.aggravated_assault.score,
          text: data.aggravated_assault.text
        },
        burglary: {
          score: data.burglary.score,
          text: data.burglary.text
        },
        larceny: {
          score: data.larceny.score,
          text: data.larceny.text
        },
        motor_vehicle_theft: {
          score: data.motor_vehicle_theft.score,
          text: data.motor_vehicle_theft.text
        }
      };
      setFieldValue('hazardData', hazardDataFromApi);
      setIsImportedFromHazardHub(true);
      enqueueSnackbar('HazardHub Record Fetched Successfully.', {variant: 'success'});
    }
  } catch (e) {
    enqueueSnackbar(e.message || 'Get HazardHub record error.', {variant: 'error'});
    setHazardHubImportLoading(false);
  }
};
