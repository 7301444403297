import React, { useState } from 'react';
import PropTypes from 'prop-types';
// mui
import { Box, Card, CardHeader, CardContent, FormControlLabel, Grid, Stack, Switch } from '@mui/material';
// utils
import { isNullOrEmpty } from 'src/utils/checkEmpty';
// components
import ComingSoonCover from 'src/components/ComingSoonCover';
import EmptyContent from 'src/components/EmptyContent';
import LabelWidget from 'src/components/_dashboard/common/LabelWidget';
import LCChart from '../common/LCChart';
import Gallery from './components/Automobile/Gallery';
import Table from './components/Automobile/Table';
import AnnualDPChart from './common/AnnualDPChart';
import FullScreenChart from '../common/FullScreenChart';
// ----------------------------------------------------------------------

Automobile.propTypes = {
  mode: PropTypes.string,
  view: PropTypes.string,
  data: PropTypes.array
};

export default function Automobile({ mode, view = 'show', data = [], chartData }) {
  const [viewAll, setViewAll] = useState(false);

  const getValidData = () => {
    return viewAll ? data : data.filter((item) => item.status === 'active');
  };

  let yearData = [];
  let annualPData = [];
  let aggregatePValue = [];
  const { annualPremium = [], totalPropertyCoverage = [] } = chartData;

  annualPremium.forEach((item) => {
    yearData.push(item.year);
  });
  totalPropertyCoverage.forEach((item) => {
    if (!yearData.includes(item.year)) yearData.push(item.year);
  });
  yearData.sort();

  yearData.forEach((year) => {
    const targetPData = annualPremium.find((item) => item.year === year);
    const targetVData = totalPropertyCoverage.find((item) => item.year === year);
    annualPData.push(targetPData?.value || 0);
    aggregatePValue.push(targetVData?.value || 0);
  });
  // console.log(chartData);

  const renderData = () =>
    mode === 'gallery' ? (
      <Gallery data={data} />
    ) : (
      <Box sx={{ minHeight: 360 }}>
        <FormControlLabel
          sx={{ position: 'absolute', top: '46px', right: '46px', zIndex: 999 }}
          control={<Switch size="small" checked={viewAll} onChange={(event) => setViewAll(event.target.checked)} />}
          label={viewAll ? 'View Active' : 'View All'}
          labelPlacement="start"
        />
        <Table data={getValidData()} />
      </Box>
    );

  return (
    <Stack spacing={2}>
      {view === 'show' && (
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{ m: 1, height: 'calc(100% - 12px)', justifyContent: 'space-between' }}>
              <LabelWidget
                data={chartData.totalAnnualPremium}
                label="Total Annual Premium"
                type="currency"
                sx={{ height: '30%' }}
              />
              <Grid container sx={{ height: '30%',justifyContent: 'space-between' }}>
                <LabelWidget
                  data={chartData.totalInsurancePolicies}
                  label="Total Insurance Policy"
                  sx={{ height: '100%', width: '49%' }}
                />
                <LabelWidget
                  data={chartData.totalActiveInsurancePolicies}
                  label="Total Active States"
                  sx={{ height: '100%', width: '49%' }}
                />
              </Grid>
              <LabelWidget data={chartData.totalCoveredRisks} label="Total Covered Risks" sx={{ height: '30%' }} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ m: 1, height: 'calc(100% - 12px)', display: 'flex', flexDirection: 'column' }}>
              <CardHeader title="Liability Coverage" />
              <Box sx={{ flex: 1 }}>
                {isNullOrEmpty([chartData.liabilityCoverage]) && (
                  <ComingSoonCover noData={true} style={{ marginTop: 35 }} />
                )}
                <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <LCChart label={['Automobiles']} data={[chartData.liabilityCoverage]} />
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{ m: 1, height: 'calc(100% - 12px)', display: 'flex', flexDirection: 'column', overflow: 'visible' }}
            >
              <CardHeader title="Annual Premium" />
              <Box sx={{ flex: 1 }}>
                <Box height={'100%'}>
                  {isNullOrEmpty([annualPData, aggregatePValue]) && (
                    <ComingSoonCover noData={true} style={{ marginTop: 35 }} />
                  )}
                  <FullScreenChart>
                    <AnnualDPChart
                      label={['Annual Premium', 'Aggregate Property Value']}
                      categories={yearData}
                      data={[annualPData, aggregatePValue]}
                    />
                  </FullScreenChart>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
      <Card>
        <CardContent>{data.length > 0 ? renderData() : <EmptyContent />}</CardContent>
      </Card>
    </Stack>
  );
}
