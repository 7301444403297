import React, {useState, useCallback} from 'react';
import {useSnackbar} from 'notistack';
import {debounce} from 'lodash';
// mui
import {Autocomplete, Stack, TextField} from '@mui/material';
import {getMakes, getModel} from 'src/api';
// api
// ----------------------------------------------------------------------

const MakeFields = ({make = null, setMake, model = null, setModel}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [makeLoading, setMakeLoading] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);

  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);

  const handleMakeSearchChange = async (value) => {
    try {
      if (!value) return;
      setMakeLoading(true);

      const makesData = await getMakes(value);
      if (!!makesData?.data.length) {
        setMakeList(makesData?.data);
      }

      setMakeLoading(false);
    } catch (error) {
      enqueueSnackbar('Could not fetch make data', {variant: 'error'});
      setMakeLoading(false);
    }
  };
  // eslint-disable-next-line
  const makeSearchHandler = useCallback(debounce(handleMakeSearchChange, 300), []);

  const handleModelSearchChange = async (value) => {
    try {
      setModelLoading(true);

      const modelsData = await getModel(value?.Make_ID, value?.Make_Name);
      if (!!modelsData?.data.length) {
        const modelsNames = modelsData?.data?.map?.((m) => m.Model_Name);
        setModelList(modelsNames);
      }

      setModelLoading(false);
    } catch (error) {
      enqueueSnackbar('Could not fetch model data', {variant: 'error'});
      setModelLoading(false);
    }
  };

  const handleMakeChange = (_e, value) => {
    setModel('');
    setMake(value?.Make_Name);

    setMakeList([]);
    setModelList([]);

    _e !== false && handleModelSearchChange(value);
  };

  return (
    <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{alignItems: 'center'}}>
      <Autocomplete
        fullWidth
        options={makeList || []}
        getOptionLabel={(o) => o?.Make_Name ?? o}
        loading={makeLoading}
        value={make}
        onChange={handleMakeChange}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            autoComplete="off"
            label="Make"
            variant="outlined"
            onChange={(e) => {
              setModel('');
              makeSearchHandler(e.target.value);
              handleMakeChange(false, {Make_Name: e.target.value});
            }}
          />
        )}
      />

      <Autocomplete
        fullWidth
        disabled={!make}
        options={modelList || []}
        loading={modelLoading || makeLoading}
        value={model}
        onChange={(_e, value) => {
          setModel(value);
        }}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            autoComplete="off"
            label="Model"
            variant="outlined"
            onChange={(e) => {
              setModel(e.target.value);
            }}
          />
        )}
      />
    </Stack>
  );
};

export default React.memo(MakeFields);
