import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  initiated: false,
  error: null,
  chartData: {board: {}, entity: {}, trust: {}, club: {}},
  org: {
    board: [],
    entity: [],
    trust: [],
    club: []
  }
};

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORGANIZATIONS
    setOrgs(state, action) {
      state.isLoading = false;
      state.initiated = true;
      let newOrgs = {};
      let chartData = {};
      for (const [key, value] of Object.entries(action.payload)) {
        newOrgs[key] = value.data;
        chartData[key] = value.topChartData;
      }

      state.org = newOrgs;
      state.chartData = chartData;
    },

    // GET Board ORGANIZATIONS
    setBOrgs(state, action) {
      state.isLoading = false;
      state.org.board = action.payload.data;
      state.chartData.board = action.payload.topChartData;
    },

    // GET Entity ORGANIZATIONS
    setEOrgs(state, action) {
      state.isLoading = false;
      state.org.entity = action.payload.data;
      state.chartData.entity = action.payload.topChartData;
    },

    // GET Trust and Estate ORGANIZATIONS
    setTOrgs(state, action) {
      state.isLoading = false;
      state.org.trust = action.payload.data;
      state.chartData.trust = action.payload.topChartData;
    },

    // GET Club ORGANIZATIONS
    setCOrgs(state, action) {
      state.isLoading = false;
      state.org.club = action.payload.data;
      state.chartData.club = action.payload.topChartData;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getOrgs(tab) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (tab === 'board') {
        const response = await axios.get('/organization/board');
        const shared = await axios.get('/shared/organization/board');
        const boards = [...response.data, ...shared.map((d) => ({...d, shared: true}))];

        dispatch(slice.actions.setBOrgs(boards));
      } else if (tab === 'entity') {
        const response = await axios.get('/organization/entity');
        const shared = await axios.get('/shared/organization/entity');
        const entity = [...response.data, ...shared.map((d) => ({...d, shared: true}))];

        dispatch(slice.actions.setEOrgs(entity));
      } else if (tab === 'trust-estate') {
        const response = await axios.get('/organization/trust-estate');
        const shared = await axios.get('/shared/organization/trust-estate');
        const trust = [...response.data, ...shared.map((d) => ({...d, shared: true}))];

        dispatch(slice.actions.setTOrgs(trust));
      } else if (tab === 'club') {
        const response = await axios.get('/organization/club');
        const shared = await axios.get('/shared/organization/club');
        const club = [...response.data, ...shared.map((d) => ({...d, shared: true}))];

        dispatch(slice.actions.setCOrgs(club));
      } else {
        const response = await axios.get('/organization/all');
        const orgs = response.data;

        dispatch(slice.actions.setOrgs(orgs));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addOrg(data) {
  return async (dispatch) => {
    const payload = {
      ...data
    };
    const tab = payload.type;
    dispatch(slice.actions.startLoading());
    try {
      if (tab === 'board') {
        const result = await axios.post('/organization/board', payload);
        const response = await axios.get('/organization/board');
        const boards = response.data;
        dispatch(slice.actions.setBOrgs(boards));

        return result;
      } else if (tab === 'entity') {
        const result = await axios.post('/organization/entity', payload);
        const response = await axios.get('/organization/entity');
        const entity = response.data;
        dispatch(slice.actions.setEOrgs(entity));

        return result;
      } else if (tab === 'trust-estate') {
        const result = await axios.post('/organization/trust-estate', payload);
        const response = await axios.get('/organization/trust-estate');
        const trust = response.data;
        dispatch(slice.actions.setTOrgs(trust));

        return result;
      } else if (tab === 'club') {
        const result = await axios.post('/organization/club', payload);
        const response = await axios.get('/organization/club');
        const club = response.data;
        dispatch(slice.actions.setCOrgs(club));

        return result;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));

      return error;
    }
  };
}

export function updateOrg(data, userId, isFamilyGroup) {
  return async (dispatch) => {
    const payload = {
      ...data
    };
    const tab = payload.type;
    dispatch(slice.actions.startLoading());
    try {
      let result;
      let response;
      let org = {
        data: [],
        topChartData: []
      };

      if (tab === 'board') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/organization/board?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/organization/board?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          org.data.push(response.data);
        } else {
          result = await axios.put('/organization/board', payload);
          response = await axios.get('/organization/board');
          org = {...response.data};
        }
        dispatch(slice.actions.setBOrgs(org));
        return result?.data;
      } else if (tab === 'entity') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/organization/entity?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/organization/entity?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          org.data.push(response.data);
        } else {
          result = await axios.put('/organization/entity', payload);
          response = await axios.get('/organization/entity');
          org = {...response.data};
        }

        const entity = response.data;
        dispatch(slice.actions.setEOrgs(entity));
        return result?.data;
      } else if (tab === 'trust-estate') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/organization/trust-estate?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/organization/trust-estate?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          org.data.push(response.data);
        } else {
          result = await axios.put('/organization/trust-estate', payload);
          response = await axios.get('/organization/trust-estate');
          org = {...response.data};
        }
        dispatch(slice.actions.setTOrgs(org));
        return result?.data;
      } else if (tab === 'club') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/organization/club?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/organization/club?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          org.data.push(response.data);
        } else {
          result = await axios.put('/organization/club', payload);
          response = await axios.get('/organization/club');
          org = {...response.data};
        }
        dispatch(slice.actions.setCOrgs(org));
        return result?.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));

      return error;
    }
  };
}

export function deleteOrg(data) {
  return async (dispatch) => {
    const payload = {
      ...data
    };
    const {tab, id, userId, isFamilyGroup} = payload;
    dispatch(slice.actions.startLoading());
    try {
      let response;
      let result;
      let org = {
        data: [],
        topChartData: []
      };

      if (tab === 'board') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/organization/board?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/organization/board?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          org.data.push(response.data);
        } else {
          result = await axios.delete(`/organization/board/${id}`);
          response = await axios.get('/organization/board');
          org = {...response.data};
        }
        dispatch(slice.actions.setBOrgs(org));
        return result?.data;
      } else if (tab === 'entity') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/organization/entity?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/organization/entity?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          org.data.push(response.data);
        } else {
          result = await axios.delete(`/organization/entity/${id}`);
          response = await axios.get('/organization/entity');
          org = {...response.data};
        }
        dispatch(slice.actions.setEOrgs(org));
        return result?.data;
      } else if (tab === 'trust-estate') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/organization/trust-estate?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/organization/trust-estate?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          org.data.push(response.data);
        } else {
          result = await axios.delete(`/organization/trust-estate/${id}`);
          response = await axios.get('/organization/trust-estate');
          org = {...response.data};
        }
        dispatch(slice.actions.setTOrgs(org));
        return result?.data;
      } else if (tab === 'club') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/organization/club?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/organization/club?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          org.data.push(response.data);
        } else {
          result = await axios.delete(`/organization/club/${id}`);
          response = await axios.get('/organization/club');
          org = {...response.data};
        }
        dispatch(slice.actions.setCOrgs(org));
        return result?.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
