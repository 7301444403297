// mui
import {Skeleton, Stack} from '@mui/material';
// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    <Skeleton width="25%" height={40} variant="rectangular" sx={{borderRadius: 2, mb: 2}} />
    <Stack direction={'row'} spacing={1}>
      <Skeleton width="100%" height={120} variant="rectangular" sx={{borderRadius: 2}} />
      <Skeleton width="100%" height={180} variant="rectangular" sx={{borderRadius: 2}} />
    </Stack>
  </>
);

export default SkeletonLoad;
