import React, {useState} from 'react';
// mui
import {Box, Dialog, DialogContent, IconButton, Stack, Tooltip} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import {useEffect} from 'react';

const FullScreenChart = ({isEmpty, withTrend = false, children}) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [matches, setMatches] = useState(window.innerWidth > 600);

  // screen size for media query
  useEffect(() => {
    const handleResize = () => {
      setMatches(window.innerWidth > 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Dialog
        open={fullScreen}
        fullWidth
        maxWidth={'lg'}
        onClose={() => setFullScreen(false)}
        PaperProps={{
          style: {
            overflow: 'visible'
          }
        }}
      >
        <Stack style={{position: 'relative', overflow: 'visible'}}>
          <IconButton
            onClick={() => setFullScreen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              zIndex: 10
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={{
              minHeight: '90vh',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible'
            }}
          >
            {children}
          </DialogContent>
        </Stack>
      </Dialog>

      <Stack
        style={{
          position: 'relative',
          height: matches ? '100%' : '320px',
          width: '100%'
        }}
      >
        {!isEmpty && (
          <Tooltip title="Full Screen">
            <Box
              sx={{
                zIndex: 100,
                position: 'absolute',
                top: withTrend ? '-10px' : '-40px',
                right: '24px',
                width: '24px',
                height: '24px'
              }}
            >
              <IconButton size="small" onClick={() => setFullScreen(true)} color="primary">
                <FullscreenIcon fontSize="small" />
              </IconButton>
            </Box>
          </Tooltip>
        )}
        {children}
      </Stack>
    </>
  );
};

export default FullScreenChart;
