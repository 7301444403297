import {createSlice} from '@reduxjs/toolkit';
import {getFamilyInfo, updateFamilyInfo} from 'src/api/profile';
import {GEN_LIST} from 'src/constants/user';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  family: {
    genealogy: GEN_LIST[0],
    adminAuthority: '',
    spouse: '',
    father: '',
    mother: '',
    familyGovernance: [],
    dateOfLastFamilyMeeting: null,
    familyDocuments: []
  }
};

const slice = createSlice({
  name: 'family',
  initialState,
  reducers: {
    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },
    // Stop loading
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Family
    getFamilySuccess(state, action) {
      state.isLoading = false;
      state.family = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {startLoading, stopLoading} = slice.actions;

// ----------------------------------------------------------------------

// get
export function getFamilyData() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await getFamilyInfo();
      if (response.status === 200 && !!response.data) {
        await dispatch(slice.actions.getFamilySuccess(response.data));
      } else {
        dispatch(stopLoading());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// update
export function updateFamilyData(payload) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await updateFamilyInfo(payload);
      await dispatch(slice.actions.getFamilySuccess(response.data));

      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
