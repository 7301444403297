import {useMemo} from 'react';
// mui
import {Autocomplete, Checkbox, TextField} from '@mui/material';
// hooks
import useAuth from 'src/hooks/useAuth';
// redux
import {useSelector} from 'src/redux/store';
// utils
import {getFullName} from 'src/utils/createAvatar';
// ----------------------------------------------------------------------
/**
 *
 * @param {isPrimary} boolean : flag for primary contacts
 * @returns
 */

const ContactSelect = ({values, keyValue, label, setFieldValue, clientId, isPrimary = false}) => {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';
  const {isLoading: cLoading, contacts} = useSelector((state) => state.contact);

  // NOTE: Advisor section
  const {contacts: advisorContacts, list} = useSelector((state) => state.client);
  const {members} = useSelector((state) => state.familyGroup);
  const msCounts = members.filter((m) => m.show); // NOTE: Selected family member counts

  const contactList = useMemo(() => {
    const selectedClient = (isAdvisor ? list : members).find((item) => item.id === clientId) || '';

    let data =
      isAdvisor || msCounts > 0
        ? [
            {
              id: 'self',
              userId: 'self',
              firstName: selectedClient?.firstName,
              lastName: selectedClient?.lastName,
              email: selectedClient?.email,
              contactType: 'individual'
            },
            ...advisorContacts.filter((item) => item.ownerId === clientId)
          ]
        : [
            {
              id: 'self',
              userId: 'self',
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              contactType: 'individual'
            },
            ...contacts
          ];

    const validContactType = isPrimary
      ? ['individual', 'family', 'institutions', 'advisor'] // NOTE: allow all contact type for primary contact
      : ['individual', 'family', 'institutions'];

    return data
      .filter((item) => validContactType.includes(item.contactType))
      .map((item) => ({...item, userId: item.userId || item.id}));
    // eslint-disable-next-line
  }, [contacts, user, isAdvisor, advisorContacts, clientId, msCounts]);

  return (
    <Autocomplete
      fullWidth
      multiple
      loading={cLoading}
      options={contactList}
      getOptionLabel={(option) => getFullName(option, true)}
      value={contactList.filter((item) => values.includes(item.userId))}
      onChange={(e, value) =>
        setFieldValue(
          keyValue,
          value.map((item) => item.userId)
        )
      }
      renderOption={(props, option, {selected}) => {
        return (
          <li {...props} key={option.id}>
            <Checkbox checked={selected} />
            {getFullName(option, true)}
          </li>
        );
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default ContactSelect;
