import {merge} from 'lodash';
import {useState} from 'react';
import ReactApexChart from 'react-apexcharts';
// components
import BaseOptionChart from 'src/components/_dashboard/common/BaseOptionChart';
// mui
import {Box} from '@mui/material';
// utils
import {isNullOrEmpty} from 'src/utils/checkEmpty';

// ----------------------------------------------------------------------

const CHART_DATA = [100, 100];
const CHART_LABELS = ['Series-1', 'Series-2'];

export default function RadialChart({data, labels, labelLineBreak, showTotal = false}) {
  const isDataEmpty = isNullOrEmpty(data);
  const inputData = isDataEmpty ? CHART_DATA : data;
  const inputLabels = isDataEmpty ? CHART_LABELS : labels;

  const [isSelected, setIsSelected] = useState(false);
  const totalDisplay = showTotal && !isSelected && !isDataEmpty;

  const chartOptions = merge(BaseOptionChart('percent', inputLabels, inputData, totalDisplay), {
    labels: inputLabels,
    plotOptions: {
      radialBar: {
        hollow: {size: '68%'},
        dataLabels: {
          name: {
            offsetY: labelLineBreak ? -16 : 0,
            fontSize: '14px',
            formatter: function (val, tt) {
              return val.toString().split('\n');
            }
          },
          value: {
            offsetY: labelLineBreak ? (labels[0].toString().split('\n').length > 3 ? -48 : 34) : 16
          }
        }
      }
    },
    chart: {
      events: {
        dataPointSelection: (e, x, y) => setIsSelected(!!y?.selectedDataPoints?.[0]?.[0])
      }
    }
  });

  return (
    <Box
      sx={{
        ...(isDataEmpty && {filter: 'grayscale(90%)', opacity: 0.5})
      }}
    >
      <ReactApexChart type="radialBar" series={inputData} options={chartOptions} />
    </Box>
  );
}
