import axios from 'src/utils/axios';
// ----------------------------------------------------------------------

// contact
export const getContactByID = async (id) => {
  try {
    const response = await axios.get(`/user-contacts/${id}`);

    return response.data;
  } catch (e) {
    return e;
  }
};

// Advisor client contact
export const getAdvisorContactByID = async (id, userId, isFamilyGroup) => {
  try {
    const response = await axios.get(`/advisor/user-contacts/${id}?userId=${userId}&isFamilyGroup=${isFamilyGroup}`);

    return response.data;
  } catch (e) {
    return e;
  }
};
