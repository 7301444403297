// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
import useAuth from './hooks/useAuth';
// components
import Settings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import NotistackProvider from './components/NotistackProvider';
import {BaseOptionChartStyle} from './components/charts/BaseOptionChart';
import LoadingScreen, {ProgressBarStyle} from './components/LoadingScreen';

// ----------------------------------------------------------------------

export default function App() {
  const {isInitialized} = useAuth();

  return (
    <ThemeConfig>
      <NotistackProvider>
        <GlobalStyles />
        <ProgressBarStyle />
        <BaseOptionChartStyle />
        <Settings />
        <ScrollToTop />
        {isInitialized ? <Router /> : <LoadingScreen />}
      </NotistackProvider>
    </ThemeConfig>
  );
}
