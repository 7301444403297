export const findDuplicatedLevel = (data, type, insurance, assets) => {
  if (type === 'policy') {
    if (!!data.type && !!insurance[data.type]) {
      const targetPolicy = insurance[data.type].find(
        (item) => item.status === 'active' && item.policyNumber === data.policyNumber
      );

      if (targetPolicy) {
        return {
          data: targetPolicy,
          level: 'error'
        };
      }
    }
  } else if (type === 'claim') {
    if (!!insurance.claim) {
      const targetClaim = insurance.claim.find((item) => item.policyNumber === data.carrierIdentifier);

      if (targetClaim) {
        return {
          data: targetClaim,
          level: 'error'
        };
      }
    }
  } else if (type === 'asset') {
    if (!!data.type && !!assets[data.type]) {
      let dupTarget = null;
      let warnTarget = null;
      const searchList = assets[data.type].filter((item) => item.assetStatus === 'active');
      if (data.type === 'realEstate') {
        dupTarget = searchList.find(
          (item) =>
            !!item.realEstateAddress &&
            item.realEstateAddress.street === data.address.street &&
            item.realEstateAddress.city === data.address.city &&
            item.realEstateAddress.state === data.address.state &&
            item.realEstateAddress.zipCode === data.address.zip
        );
        warnTarget = searchList.find(
          (item) =>
            !!item.realEstateAddress &&
            ((item.realEstateAddress.city === data.address.city &&
              item.realEstateAddress.state === data.address.state) ||
              item.realEstateAddress.zipCode === data.address.zip)
        );

        return !!dupTarget
          ? {data: dupTarget, level: 'error'}
          : !!warnTarget
          ? {data: warnTarget, level: 'warn'}
          : {data: null, level: ''};
      } else if (data.type === 'automobile') {
        dupTarget = searchList.find((item) => item.idNumber === data.idNumber);
        warnTarget = searchList.find(
          (item) => item.year === data.year && item.make === data.make && item.amModel === data.amModel
        );

        return !!dupTarget
          ? {data: dupTarget, level: 'error'}
          : !!warnTarget
          ? {data: warnTarget, level: 'warn'}
          : {data: null, level: ''};
      }
    }
  }

  return {data: null, level: ''};
};
