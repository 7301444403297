import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import {styled} from '@mui/material/styles';
import {Card, Button, Typography, Box, Grid} from '@mui/material';
// utils
import {fNumber} from 'src/utils/formatNumber';
// components
import {PlanPremiumIcon, PlanStarterIcon, PlanFreeIcon} from 'src/assets';
import Label from '../../Label';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({theme}) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5)
  }
}));

// ----------------------------------------------------------------------

PricingPlanCard.propTypes = {
  index: PropTypes.number,
  card: PropTypes.object,
  onPlanClick: PropTypes.func
};

export default function PricingPlanCard({card, index, onPlanClick}) {
  const {
    icon = index % 3 === 0 ? (
      <PlanFreeIcon isSecond={card.metadata.type !== 'Family'} />
    ) : index % 3 === 1 ? (
      <PlanStarterIcon isSecond={card.metadata.type !== 'Family'} />
    ) : (
      <PlanPremiumIcon isSecond={card.metadata.type !== 'Family'} />
    ),
    name,
    amount,
    interval,
    caption = '',
    metadata = null
  } = card;

  const renderListTitle = (key) => {
    let title = '';
    switch (key) {
      case 'administrators':
        title = 'Administrators';
        break;
      case 'familyMembers18plus':
        title = 'Individual/Family';
        break;
      case 'staff':
        title = 'Advisor/Staff';
        break;
      default:
        break;
    }

    return title;
  };

  return (
    <RootStyle>
      <Label
        color={card.metadata.type === 'Family' ? 'primary' : 'info'}
        sx={{
          top: 16,
          right: 16,
          position: 'absolute'
        }}
      >
        {metadata ? metadata.type || '' : ''}
      </Label>

      <Typography variant="overline" sx={{color: 'text.secondary'}}>
        {name}
      </Typography>

      <Box sx={{display: 'flex', justifyContent: 'flex-end', my: 2}}>
        <Typography variant="subtitle1" sx={{color: 'text.secondary'}}>
          $
        </Typography>
        <Typography variant="h2" sx={{mx: 1}}>
          {amount === 0 ? 'Free' : fNumber(amount / 100)}
        </Typography>

        <Typography
          gutterBottom
          component="span"
          variant="subtitle2"
          sx={{
            alignSelf: 'flex-end',
            color: 'text.secondary'
          }}
        >
          /{interval}
        </Typography>
      </Box>

      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
          textTransform: 'capitalize'
        }}
      >
        {caption}
      </Typography>

      <Box sx={{width: 80, height: 80, mt: 3}}>{icon}</Box>

      <Grid container spacing={2} sx={{my: 5, width: 1}}>
        {metadata &&
          Object.keys(metadata)
            .filter((key) => key !== 'type')
            .map((key, index) => (
              <Grid
                item
                key={`${key}-${index}`}
                display={'flex'}
                flexDirection={'row'}
                alignItems="center"
                xs={12}
                sx={{
                  typography: 'body2',
                  color: 'text.primary'
                }}
              >
                <Box component={Icon} icon={checkmarkFill} sx={{width: 20, height: 20}} />
                <Typography variant="body2" ml={1.5}>
                  {metadata[key]} {renderListTitle(key)}
                </Typography>
              </Grid>
            ))}
      </Grid>

      <Button
        fullWidth
        size="large"
        variant="contained"
        color={card.metadata.type === 'Family' ? 'primary' : 'success'}
        onClick={() => onPlanClick(card)}
      >
        Choose
      </Button>
    </RootStyle>
  );
}
