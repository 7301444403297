import {useState, useEffect} from 'react';
import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router-dom';
// mui
import {styled} from '@mui/material/styles';
import {Box, Grid, Switch, Container, Typography, Stack, Button} from '@mui/material';
// hook
import useAuth from 'src/hooks/useAuth';
// route
import {PATH_PAGE, PATH_AUTH} from 'src/routes/paths';
// utils
import axios from 'src/utils/axios';
// components
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import {PricingPlanCard} from 'src/components/_external-pages/pricing';
import {InviteCard} from 'src/components/_external-pages/invites';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function Pricing() {
  const {isAuthenticated, user} = useAuth();
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [type, setType] = useState('year');
  const [loading, setLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const [invitesLoading, setInvitesLoading] = useState(false);
  const [accepted, setAccepted] = useState(null);
  const [invites, setInvites] = useState([]);
  const [showInvites, setShowInvites] = useState(true);

  let familIndex = 0;
  let otherIndex = 0;

  useEffect(() => {
    // get invites
    const getInvites = async () => {
      setInvitesLoading(true);

      await axios
        .get(`/user-contacts/pending-invite?email=${user?.email}`)
        .then((res) => {
          if (!!res?.data) {
            const invitesData = res?.data;
            setInvites(invitesData);
          } else {
            enqueueSnackbar('Unable to get invites data.', {variant: 'error'});
          }
          setInvitesLoading(false);
        })
        .catch(() => {
          enqueueSnackbar('Unable to get invites data.', {variant: 'error'});
        });
    };

    // get pricing plans
    const getPlans = async () => {
      await axios
        .get(`payment/plans`)
        .then((res) => {
          if (!!res?.data) {
            const plans = res?.data.sort((a, b) =>
              b.metadata.type === 'Family' && a.metadata.type !== 'Family' ? 1 : a.amount > b.amount ? 1 : -1
            );

            setPlans(plans);
          } else {
            enqueueSnackbar('Unable to get membership plan data.', {variant: 'error'});
          }
          setLoading(false);
        })
        .catch(() => {
          enqueueSnackbar('Unable to get membership plan data.', {variant: 'error'});
        });
    };

    getInvites();
    getPlans();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlanClick = (plan) => {
    if (isAuthenticated) {
      navigate(PATH_PAGE.payment, {state: {plan}});
    } else {
      navigate(PATH_AUTH.login);
    }
  };

  const onAcceptClick = async (invite) => {
    setAccepted(invite.id);

    await axios
      .put(`/user-contacts/invite-accpet/${invite.id}?acceptStatus=true`)
      .then((res) => {
        if (!!res) {
          setAccepted(null);
          enqueueSnackbar('Invite accept success', {variant: 'success'});
          localStorage.setItem('profile', JSON.stringify({...user, invited: true}));
          new Promise((resolve) => {
            localStorage.setItem('profile', JSON.stringify({...user, invited: true}));
            resolve();
          }).then(() => window.location.reload());
        }
      })
      .catch((e) => {
        enqueueSnackbar(e?.message ?? 'Unable to accept invite.', {variant: 'error'});
        setAccepted(null);
      });
  };

  return invitesLoading ? (
    <Box sx={{width: '100%', height: '100vh'}}>
      <LoadingScreen />
    </Box>
  ) : showInvites && invites?.length > 0 ? (
    // INVITES //
    <RootStyle title="Invites | Opticus">
      <Container maxWidth="lg">
        <Button
          size="medium"
          variant="contained"
          sx={{position: 'absolute', top: 0, right: 0, margin: 5}}
          onClick={() => setShowInvites(false)}
        >
          Create Paid Account
        </Button>

        <Typography variant="h3" align="center" paragraph>
          Your Invites
        </Typography>

        <Typography align="center" sx={{color: 'text.secondary'}}>
          Accept an Invite and make modern online conversation magic
        </Typography>

        <Stack alignItems={'center'} direction={'column'} container spacing={3} mt={10}>
          {invites.map((invite, index) => (
            <InviteCard
              key={index}
              accepted={accepted}
              invite={invite}
              onAcceptClick={onAcceptClick}
              setAccepted={setAccepted}
            />
          ))}
        </Stack>
      </Container>
    </RootStyle>
  ) : (
    // PRICING //
    <RootStyle title="Pricing | Opticus">
      <Container maxWidth="lg">
        {invites?.length > 0 && (
          <Button
            size="medium"
            variant="contained"
            sx={{position: 'absolute', top: 0, right: 0, margin: 5}}
            onClick={() => setShowInvites(true)}
          >
            Show Invites
          </Button>
        )}

        <Typography variant="h3" align="center" paragraph>
          Flexible plans for your
          <br /> community&apos;s size and needs
        </Typography>

        <Typography align="center" sx={{color: 'text.secondary'}}>
          Choose your plan and make modern online conversation magic
        </Typography>

        <Box sx={{my: 5}}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Typography variant="overline" sx={{mr: 1.5}}>
              MONTHLY
            </Typography>
            <Switch
              checked={type === 'year'}
              onChange={() => setType((oldType) => (oldType === 'year' ? 'month' : 'year'))}
            />
            <Typography variant="overline" sx={{ml: 1.5}}>
              YEARLY
            </Typography>
          </Stack>
        </Box>

        <Grid container spacing={3}>
          {loading || !plans.length ? (
            <Box sx={{width: '100%', height: '50vh'}}>
              <LoadingScreen />
            </Box>
          ) : (
            plans
              .filter((plan) => plan.interval === type)
              .map((card, idx) =>
                card.metadata.type === 'Family' ? (
                  <Grid item xs={12} md={6} key={card.priceId}>
                    <PricingPlanCard card={card} index={familIndex++} onPlanClick={handlePlanClick} />
                  </Grid>
                ) : (
                  <Grid item xs={12} md={4} key={card.priceId}>
                    <PricingPlanCard card={card} index={otherIndex++} onPlanClick={handlePlanClick} />
                  </Grid>
                )
              )
          )}
        </Grid>
      </Container>
    </RootStyle>
  );
}
