import React, {useState} from 'react';
import {useSnackbar} from 'notistack';
// mui
import {Stack, TextField} from '@mui/material';
import {getDecodedVin} from 'src/api';
import {LoadingButton} from '@mui/lab';
// api
// ----------------------------------------------------------------------

const VinField = ({vin = null, setVin, setVinData, name}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [vinLoading, setVinLoading] = useState(false);

  const handleVinSearchChange = async () => {
    try {
      setVinLoading(true);

      const vinData = await getDecodedVin(vin);
      if (!!vinData?.data?.Results) {
        setVinData(vinData?.data?.Results?.[0]);
      }

      setVinLoading(false);
    } catch (error) {
      enqueueSnackbar('Could not fetch vin data', {variant: 'error'});
      setVinLoading(false);
    }
  };

  return (
    <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{alignItems: 'center'}}>
      <TextField
        label={name}
        variant="outlined"
        value={vin}
        onChange={(e) => {
          setVin(e.target.value.toUpperCase());
        }}
        style={{flex: 1}}
      />

      <LoadingButton variant={'contained'} onClick={handleVinSearchChange} loading={vinLoading} disabled={!vin}>
        Validate
      </LoadingButton>
    </Stack>
  );
};

export default React.memo(VinField);
