import {useEffect, useState} from 'react';
// mui
import {Accordion} from '@mui/material';
// hook
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------
function ThemeAccordion({children}) {
  const {themeExpand} = useSettings();
  const [open, setOpen] = useState(themeExpand);
  useEffect(() => {
    setOpen(themeExpand);
  }, [themeExpand]);

  return (
    <Accordion defaultExpanded={themeExpand} expanded={open} onChange={() => setOpen((oldValue) => !oldValue)}>
      {children}
    </Accordion>
  );
}

export default ThemeAccordion;
