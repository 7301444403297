import {useEffect, useState, useRef} from 'react';
import {useSnackbar} from 'notistack';
import {Icon} from '@iconify/react';
import copyFill from '@iconify/icons-eva/copy-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {CopyToClipboard} from 'react-copy-to-clipboard';
// mui
import {Box, Button, CircularProgress, IconButton, Link, Stack, Tooltip, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// api
import {carrierLogin, getCanopyCredential} from 'src/api/canopy';
// utils
import {decrypt} from 'src/utils/security';
// ----------------------------------------------------------------------

const CanopyLogin = ({config, handleSetProvider, setPullInfo}) => {
  const {enqueueSnackbar} = useSnackbar();

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const thirdfieldRef = useRef(null);
  const [form, setForm] = useState(null);
  const [uiReady, setUIReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cLoading, setCLoading] = useState(false); // Credential fetching
  const [savedCredential, setSavedCredential] = useState(null);
  const [viewPw, setViewPw] = useState(false);

  useEffect(() => {
    const getCanopyCredentialData = async () => {
      setCLoading(true);
      try {
        const {status, data} = await getCanopyCredential(config.carrier_id);
        if (status === 200 && data.username && data.password) {
          setSavedCredential({
            username: data.username,
            password: decrypt(data.password) || ''
          });
        }
      } catch (err) {
        console.log({err});
      } finally {
        setCLoading(false);
      }
    };

    if (config?.carrier_id) getCanopyCredentialData();
  }, [config]);

  useEffect(() => {
    const initiateCanopyForm = async () => {
      const options = {
        carrierConfig: config,
        styles: {base: {fontSize: '16px', fontFamily: '"Public Sans", sans-serif'}}
      };
      const canopyForm = window.CanopyConnectComponents.form(options);
      try {
        canopyForm.on('ready', () => {
          setForm(canopyForm);
          setUIReady(true);
        });
        const {username, password, thirdfield} = canopyForm.components;
        if (username) {
          username.mount(usernameRef.current);
        }
        if (password) {
          password.mount(passwordRef.current);
        }
        if (thirdfield) {
          thirdfield.mount(thirdfieldRef.current);
        }
      } catch (err) {
        console.log({err});
      }
    };

    initiateCanopyForm();
  }, [config]);

  const onCopy = (field) => {
    enqueueSnackbar(field + ' Copied!', {variant: 'success'});
  };

  const handleClick = async () => {
    setLoading(true);
    const {errors, tokens} = await form.submit();

    if (!!tokens) {
      try {
        const payload = {
          username: tokens.username,
          password: tokens.password,
          thirdfield: tokens.thirdfield,
          carrierId: config.carrier_id
        };
        const res = await carrierLogin(payload);
        if (!!res && !!res.data) {
          enqueueSnackbar('Carrier login success', {variant: 'success'});
          const {pullId, pullJwt} = res.data;
          setPullInfo({pullId, pullJwt});
        } else {
          enqueueSnackbar('Carrier login error', {variant: 'error'});
          setLoading(false);
        }
      } catch (err) {
        enqueueSnackbar(err.message || 'Carrier login error', {variant: 'error'});
        setLoading(false);
      }
    } else {
      setError(errors);
      setLoading(false);
    }
  };

  return (
    <>
      {cLoading && (
        <Stack direction="row" sx={{alignItems: 'center', mb: 2}}>
          Fetching saved credential...
          <CircularProgress />
        </Stack>
      )}
      {!!savedCredential && (
        <Stack spacing={1} sx={{mb: 2}}>
          <Typography variant="h6">Saved Credential:</Typography>
          <Stack sx={{px: 1}}>
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <Typography sx={{width: '80px'}}>Username:</Typography>
              <Typography>{savedCredential.username}</Typography>
              <CopyToClipboard text={savedCredential.username} onCopy={() => onCopy('Username')}>
                <Tooltip title="Copy">
                  <IconButton>
                    <Icon icon={copyFill} width={24} height={24} />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Stack>
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <Typography sx={{width: '80px'}}>Password:</Typography>
              <Typography>
                {viewPw
                  ? savedCredential.password
                  : savedCredential.password
                      .split('')
                      .map(() => '*')
                      .join('')}
              </Typography>
              <IconButton onClick={() => setViewPw(!viewPw)} edge="end">
                <Icon icon={!viewPw ? eyeFill : eyeOffFill} />
              </IconButton>
              <CopyToClipboard text={savedCredential.password} onCopy={() => onCopy('Password')}>
                <Tooltip title="Copy">
                  <IconButton>
                    <Icon icon={copyFill} width={24} height={24} />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Stack>
          </Stack>
        </Stack>
      )}
      <Box sx={{mb: 2}}>
        {/* <Typography variant="body">Username:</Typography> */}
        <Box ref={usernameRef} sx={{height: '52px', borderRadius: '8px', p: '16px 12px', border: '1px solid #eee'}} />
        {error && error.username && (
          <Typography sx={{fontSize: '12px', color: 'red'}}>{error.username.error}</Typography>
        )}
      </Box>
      <Box sx={{mb: 2}}>
        {/* <Typography variant="body">Password:</Typography> */}
        <Box ref={passwordRef} sx={{height: '52px', borderRadius: '8px', p: '16px 12px', border: '1px solid #eee'}} />
        {error && error.password && (
          <Typography sx={{fontSize: '12px', color: 'red'}}>{error.password.error}</Typography>
        )}
      </Box>
      {(!form || (form && form.components.thirdfield)) && (
        <Box sx={{mb: 2, visibility: uiReady ? 'visible' : 'hidden', position: uiReady ? 'inherit' : 'absolute'}}>
          {/* <Typography variant="body">{!form ? '' : form.components.thirdfield?._config?.placeholder}:</Typography> */}
          <Box
            ref={thirdfieldRef}
            sx={{height: '52px', borderRadius: '8px', p: '16px 12px', border: '1px solid #eee'}}
          />
          {error && error.thirdfield && (
            <Typography sx={{fontSize: '12px', color: 'red'}}>{error.thirdfield.error}</Typography>
          )}
        </Box>
      )}
      <Stack direction="row" sx={{mt: 2, justifyContent: 'flex-end'}}>
        <Button onClick={() => handleSetProvider(null)} sx={{mr: 1}}>
          Back
        </Button>
        <LoadingButton onClick={handleClick} loading={loading} variant="contained">
          Connect
        </LoadingButton>
      </Stack>
      <Typography sx={{fontSize: '12px', color: '#333', mt: 1, mb: 4}}>
        By selecting "Connect" you agree to our{' '}
        <Box
          component={Link}
          sx={{cursor: 'pointer'}}
          onClick={() => window.open('https://opticuslm.com/privacy-policies', '_blank')}
        >
          User Privacy Policy
        </Box>
      </Typography>
    </>
  );
};

export default CanopyLogin;
