import PropTypes from 'prop-types';
// mui
import {Stack, Card, CardHeader, Typography, CardContent} from '@mui/material';

Note.propTypes = {
  data: PropTypes.string
};

export default function Note({data = ''}) {
  return (
    <Card sx={{m: 1, height: 'calc(100% - 24px)'}}>
      <CardHeader title="Notes" />
      <CardContent>
        <Stack spacing={3}>
          <Typography variant="body2">{data}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
