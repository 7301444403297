import React from 'react';
import {Box, Typography} from '@mui/material';
import {alpha, useTheme} from '@mui/material/styles';

export default function ComingSoonCover({label = 'Coming Soon', noData = false, style = {}}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: alpha(theme.palette.grey[200], 0.2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        zIndex: 999,
        borderRadius: 2
      }}
    >
      <Typography
        variant="h6"
        color={alpha(theme.palette.grey[500], 1)}
        style={{textAlign: 'center', paddingLeft: 5, ...style}}
      >
        {noData ? (
          <span style={{textAlign: 'center', width: '100%'}}>
            Please enter data <br /> to activate the chart
          </span>
        ) : (
          label
        )}
      </Typography>
    </Box>
  );
}
