import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {omit, values} from 'lodash';
// hook
import useAuth from 'src/hooks/useAuth';
// redux
import {useSelector} from 'src/redux/store';
// components
import RealEstateDialog from './components/RealEstate/AddNew';
import CDialog from './components/Collection/AddNew';
import ADialog from './components/Automobile/AddNew';
import WDialog from './components/Watercraft/AddNew';
import AiDialog from './components/Aircraft/AddNew';
import EDialog from './components/Equine/AddNew';
// ----------------------------------------------------------------------

AssetDialog.propTypes = {
  tab: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

export default function AssetDialog({tab, open, handleClose}) {
  const {user} = useAuth();
  const isAdvisor = user && user.role === 'Advisor';

  // NOTE: advisor stuff
  const {list, insurance: advisorIns} = useSelector((state) => state.client);
  const {members} = useSelector((state) => state.familyGroup);

  const cId = (isAdvisor ? list : members).find((item) => item.show)?.id || '';
  const clientId = cId === 'self' ? user.userId : cId;
  const {isLoading: inLoading, insurance} = useSelector((state) => state.insurance);

  const policies = useMemo(() => {
    const newInsurance = isAdvisor ? advisorIns : insurance;
    
    return values(omit(newInsurance, ['claim']))
      .flat()
      .filter(
        (policy) => policy && !policy.shared && policy.status === 'active' && (!clientId || clientId === policy.user)
      );
  }, [insurance, advisorIns, isAdvisor, clientId]);

  const renderContent = () => {
    if (tab === 'realEstate') {
      return (
        <RealEstateDialog
          clientId={clientId}
          open={open}
          handleClose={handleClose}
          inLoading={inLoading}
          policies={policies.filter((item) => item.type === 'realEstate')}
        />
      );
    } else if (tab === 'collection') {
      return (
        <CDialog
          clientId={clientId}
          open={open}
          handleClose={handleClose}
          inLoading={inLoading}
          policies={policies.filter((item) => item.type === 'collection')}
        />
      );
    } else if (tab === 'automobile') {
      return (
        <ADialog
          clientId={clientId}
          open={open}
          handleClose={handleClose}
          inLoading={inLoading}
          policies={policies.filter((item) => item.type === 'automobile')}
        />
      );
    } else if (tab === 'watercraft') {
      return (
        <WDialog
          clientId={clientId}
          open={open}
          handleClose={handleClose}
          inLoading={inLoading}
          policies={policies.filter((item) => item.type === 'watercraft')}
        />
      );
    } else if (tab === 'aircraft') {
      return (
        <AiDialog
          clientId={clientId}
          open={open}
          handleClose={handleClose}
          inLoading={inLoading}
          policies={policies.filter((item) => item.type === 'aircraft')}
        />
      );
    } else if (tab === 'equine') {
      return (
        <EDialog
          clientId={clientId}
          open={open}
          handleClose={handleClose}
          inLoading={inLoading}
          policies={policies.filter((item) => item.type === 'equine')}
        />
      );
    }

    return null;
  };

  return renderContent();
}
