import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  members: [],
  familyGroups: [],
  familyGroup: null,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'familyGroup',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Family Groups
    getFamilyGroupsSuccess(state, action) {
      state.isLoading = false;
      state.familyGroups = action.payload;
    },

    // SET MEMBERS
    setMembers(state, action) {
      state.isLoading = false;
      state.members = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// get family groups
export function getAllFamilyGroups(isAdvisor, currentUserId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // choose api endpoint
      const URL = isAdvisor ? '/advisor/profile/familyGroup' : '/profile/familyGroup';

      const response = await axios.get(URL);
      dispatch(slice.actions.getFamilyGroupsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// add
export function addFamilyGroup(userId, isAdvisor, payload, currentUserId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // choose api endpoint
      const URL = isAdvisor ? `/advisor/profile/familyGroup?userId=${userId}` : `/profile/familyGroup`;

      const response = await axios.post(URL, payload);
      await dispatch(getAllFamilyGroups(isAdvisor, currentUserId));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// remove
export function removeFamilyGroup(id, userId, isAdvisor, currentUserId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // choose api endpoint
      const URL = isAdvisor ? `/advisor/profile/familyGroup/${id}?userId=${userId}` : `/profile/familyGroup/${id}`;

      const response = await axios.delete(URL);
      await dispatch(getAllFamilyGroups(isAdvisor, currentUserId));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// update
export function updateFamilyGroup(userId, isAdvisor, payload, currentUserId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // choose api endpoint
      const URL = isAdvisor ? `/advisor/profile/familyGroup?userId=${userId}` : `/profile/familyGroup`;

      const response = await axios.put(URL, payload);
      await dispatch(getAllFamilyGroups(isAdvisor, currentUserId));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// respond to invite
export function respondToInvite(isAdvisor, payload, currentUserId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // choose api endpoint
      const URL = isAdvisor ? `/advisor/profile/familyGroup/invite` : `/profile/familyGroup/invite`;

      const response = await axios.put(URL, payload);
      await dispatch(getAllFamilyGroups(isAdvisor, currentUserId));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

// get all members
export function getMembers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/client/members');
      const list = response.data.map((item) => ({...item, show: false})).filter((item) => !!item.email);
      dispatch(slice.actions.setMembers(list));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// update members
export function updateMembers(list) {
  return (dispatch) => {
    dispatch(slice.actions.setMembers(list));
  };
}

// ----------------------------------------------------------------------

// add favorite member
export function addFavoriteMember(data, list) {
  return async (dispatch) => {
    try {
      dispatch(updateMembers(list));
      await axios.post('/client/favorite', data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
