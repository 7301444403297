import React, {useState} from 'react';
import PropTypes from 'prop-types';
// mui
import {Box, Card, CardHeader, CardContent, FormControlLabel, Grid, Stack, Switch} from '@mui/material';
// utils
import {isNullOrEmpty} from 'src/utils/checkEmpty';
// components
import EmptyContent from 'src/components/EmptyContent';
import LabelWidget from 'src/components/_dashboard/common/LabelWidget';
import LCChart from '../common/LCChart';
import AnnualDPChart from './common/AnnualDPChart';
import Gallery from './components/Umbrella/Gallery';
import Table from './components/Umbrella/Table';
import FullScreenChart from '../common/FullScreenChart';
import ComingSoonCover from 'src/components/ComingSoonCover';
// ----------------------------------------------------------------------

Umbrella.propTypes = {
  mode: PropTypes.string,
  view: PropTypes.string,
  data: PropTypes.array
};

export default function Umbrella({mode, view = 'show', data = [], chartData}) {
  const [viewAll, setViewAll] = useState(false);

  const getValidData = () => {
    return viewAll ? data : data.filter((item) => item.status === 'active');
  };

  let yearData = [];
  let annualPData = [];
  let aggregatePValue = []; // NOTE: Aggregate policy limit
  const {annualPremium = [], aggregatePolicyLimit = []} = chartData;

  annualPremium.forEach((item) => {
    yearData.push(item.year);
  });
  aggregatePolicyLimit.forEach((item) => {
    if (!yearData.includes(item.year)) yearData.push(item.year);
  });
  yearData.sort();

  yearData.forEach((year) => {
    const targetPData = annualPremium.find((item) => item.year === year);
    const targetPLData = aggregatePolicyLimit.find((item) => item.year === year);
    annualPData.push(targetPData?.value || 0);
    aggregatePValue.push(targetPLData?.value || 0);
  });

  const renderData = () =>
    mode === 'gallery' ? (
      <Gallery data={data} />
    ) : (
      <Box sx={{minHeight: 360}}>
        <FormControlLabel
          sx={{position: 'absolute', top: '46px', right: '46px', zIndex: 999}}
          control={<Switch size="small" checked={viewAll} onChange={(event) => setViewAll(event.target.checked)} />}
          label={viewAll?'View Active':'View All'}
          labelPlacement="start"
        />
        <Table data={getValidData()} />
      </Box>
    );

  return (
    <Stack spacing={2}>
      {view === 'show' && (
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{m: 1, height: 'calc(100% - 12px)', justifyContent: 'space-between'}}>
              <LabelWidget
                data={chartData.totalAnnualPremium}
                label="Total Annual Premium"
                type="currency"
                sx={{height: '30%'}}
              />
              <LabelWidget
                data={chartData.totalInsurancePolicies}
                label="Total Insurance Policies"
                sx={{height: '30%'}}
              />
              <LabelWidget data={chartData.totalCoveredRisks} label="Total Covered Risks" sx={{height: '30%'}} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{m: 1, height: 'calc(100% - 12px)', display: 'flex', flexDirection: 'column'}}>
              <CardHeader title="Liability Coverage" />
              <Box sx={{flex: 1}}>
                <Box sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  {isNullOrEmpty([chartData.liabilityCoverage]) && (
                    <ComingSoonCover noData={true} style={{marginTop: 35}} />
                  )}
                  <LCChart label={['Umbrella']} data={[chartData.liabilityCoverage || 0]} />
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{m: 1, height: 'calc(100% - 12px)', display: 'flex', flexDirection: 'column'}}>
              <CardHeader title="Annual Premium" />
              <Box sx={{flex: 1}}>
                <Box height={'100%'}>
                  {isNullOrEmpty([annualPData, aggregatePValue]) && (
                    <ComingSoonCover noData={true} style={{marginTop: 35}} />
                  )}
                  <FullScreenChart>
                    <AnnualDPChart
                      label={['Annual Premium', 'Aggregate Policy Limit']}
                      categories={yearData}
                      data={[annualPData, aggregatePValue]}
                    />
                  </FullScreenChart>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
      <Card>
        <CardContent>{data.length > 0 ? renderData() : <EmptyContent />}</CardContent>
      </Card>
    </Stack>
  );
}
