import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';
// mui
import {Stack} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
// hooks
import useAuth from 'src/hooks/useAuth';
import useColumnSettings from 'src/hooks/useColumnSettings';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// util
import {fCurrency} from 'src/utils/formatNumber';
import {getFormattedAddress} from 'src/utils/stringFormat';
// components
import Label from 'src/components/Label';
import UserAvatar from 'src/components/UserAvatar';
// constants
import {getOrgStatusLabel} from 'src/constants/dropdown';
// ----------------------------------------------------------------------

function RenderStatus(key) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  return (
    <Label
      variant={isLight ? 'ghost' : 'filled'}
      color={key === 'active' ? 'success' : key === 'pending' ? 'warning' : 'error'}
      sx={{textTransform: 'capitalize', mx: 'auto'}}
    >
      {getOrgStatusLabel(key)}
    </Label>
  );
}

// ----------------------------------------------------------------------
export default function DataGridCustom({data}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';
  const navigate = useNavigate();
  const rows = [...data];

  const {contacts} = useSelector((state) => state.contact);

  const {contacts: advisorContacts, list} = useSelector((state) => state.client);

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show);

  const selectedClient = (isAdvisor ? list : members).find((item) => item.show) || '';

  const contactList = useMemo(() => {
    let data = [
      {
        id: user.userId,
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        contactType: 'individual'
      },
      {
        id: 'self',
        userId: 'self',
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        contactType: 'individual'
      },
      ...advisorContacts.filter((item) => item.ownerId === selectedClient?.id),
      ...contacts
    ];

    const advisorContactType = ['individual', 'family'];

    return data
      .filter((item) => advisorContactType.includes(item.contactType))
      .map((item) => ({...item, userId: item.userId || item.id}));
  }, [contacts, user, advisorContacts, selectedClient]);

  const userObj = {
    field: 'user',
    headerName: 'User',
    width: 80,
    renderCell: (params) => {
      return <UserAvatar userId={params['row'].user} />;
    }
  };
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 120
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 150,
      renderCell: ({value: address}) => getFormattedAddress(address)
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => {
        const status = params.row.status;
        return RenderStatus(status);
      }
    },
    {
      field: 'value',
      type: 'number',
      headerName: 'Estate Value',
      width: 160,
      renderCell: (params) => {
        const price = params.row.estatePlanningValue;
        return fCurrency(price);
      }
    },
    {
      field: 'trustee',
      headerName: 'Trustee',
      width: 160,
      renderCell: (params) => {
        const users = contactList.filter((x) => params?.row?.trustee.includes(x.id));
        return (
          <Stack direction={'row'} gap={1}>
            {users.length > 0
              ? users.map((us, index) => <UserAvatar userId={params?.row?.trustee[index]} isMember={true} user={us} />)
              : null}
          </Stack>
        );
      }
    },
    {
      field: 'beneficiaries',
      headerName: 'Beneficiaries',
      width: 169,
      renderCell: (params) => {
        const users = contactList.filter((x) => params?.row?.beneficiaries.includes(x.id));
        return (
          <Stack direction={'row'} gap={1}>
            {users.length > 0
              ? users.map((us, index) => <UserAvatar userId={params?.row?.trustee[index]} isMember={true} user={us} />)
              : null}
          </Stack>
        );
      }
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 180
    }
  ];

  if (user.role === 'Advisor') columns.splice(0, 0, userObj);

  const {useColumnState} = useColumnSettings();
  const [columnSettings, setColumnSettings] = useColumnState('trustOrg');

  const handleRowClick = (params) => {
    const selectedID = params.row.id;

    if (user.role === 'Advisor' || memberSelected.length > 0) {
      const route = `${PATH_DASHBOARD.root}/org/trust-estate/${selectedID}/${params.row.user}`;
      navigate(route, {state: {view: 'trust'}});
    } else {
      const route = `${PATH_DASHBOARD.root}/org/trust-estate/${selectedID}`;
      navigate(route, {state: {view: 'trust'}});
    }
  };

  return (
    <DataGrid
      autoHeight
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      rows={rows}
      columns={columns}
      columnVisibilityModel={columnSettings}
      onColumnVisibilityModelChange={setColumnSettings}
      onRowClick={handleRowClick}
      pagination
      pageSize={5}
      rowsPerPageOptions={[5]}
      components={{
        Toolbar: GridToolbar
      }}
    />
  );
}
