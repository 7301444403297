// hooks
import useAuth from '../hooks/useAuth';
//
import {MAvatar} from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({sx, ...other}) {
  const {user} = useAuth();
  const userName = {firstName: user.firstName, lastName: user.lastName};

  return (
    <MAvatar
      src={user.avatar || '/default-avatar.png'}
      alt={user.firstName}
      color={user.avatar ? 'default' : createAvatar(userName).color}
      sx={{boxShadow: '0px 2px 10px 2px rgb(0 0 0 / 30%)', border: '1px solid white', ...sx}}
      {...other}
    >
      {createAvatar(userName).name}
    </MAvatar>
  );
}
