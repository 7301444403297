export function isNullOrEmpty(data) {
  if (data === null || data === undefined || data === 0) {
    return true;
  }

  if (typeof data === 'string') {
    return data.trim() === '';
  } else if (Array.isArray(data)) {
    return !data.length || data?.every((val) => !val || val?.length === 0);
  } else if (typeof data === 'object') {
    return !Object.keys(data).length || Object.keys(data).every((key) => !data[key] || data[key].length === 0);
  }
  return false;
}
