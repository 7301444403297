import React, {useState} from 'react';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
// mui
import {Box, Card, CardHeader, CardContent, FormControlLabel, Grid, Stack, Switch} from '@mui/material';
// components
import EmptyContent from 'src/components/EmptyContent';
import LabelWidget from 'src/components/_dashboard/common/LabelWidget';
import Gallery from './components/RealEstate/Gallery';
import Table from './components/RealEstate/Table';
import ComingSoonCover from 'src/components/ComingSoonCover';
import RadialChart from './common/RadialChart';
// utils
import {isNullOrEmpty} from 'src/utils/checkEmpty';
// ----------------------------------------------------------------------

RealEstate.propTypes = {
  mode: PropTypes.string,
  view: PropTypes.string,
  data: PropTypes.array
};

export default function RealEstate({mode, view = 'show', data, chartData}) {
  const [viewAll, setViewAll] = useState(false);

  const getValidData = () => {
    return viewAll ? data : data.filter((item) => item.assetStatus !== 'divested');
  };

  const renderData = () =>
    mode === 'gallery' ? (
      <Gallery data={data} />
    ) : (
      <Box sx={{minHeight: 360}}>
        <FormControlLabel
          sx={{position: 'absolute', top: '46px', right: '46px', zIndex: 999}}
          control={<Switch size="small" checked={viewAll} onChange={(event) => setViewAll(event.target.checked)} />}
          label={viewAll?'View Active':'View All'}
          labelPlacement="start"
        />
        <Table data={getValidData()} />
      </Box>
    );

  const loansData = [
    (
      ((chartData.debtsDistress?.loans + chartData.debtsDistress?.liens) / chartData.debtsDistress?.marketValue) *
      100
    ).toFixed(2)
  ];

  const propertData = [
    ((chartData.other?.totalPropertyInsurance / chartData.other?.marketValue) * 100).toFixed(2),
    ((chartData.other?.marketValue / chartData.other?.netWorth) * 100).toFixed(2)
  ];

  return (
    <Stack spacing={2}>
      {view === 'show' && !isEmpty(chartData) && (
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{m: 1, height: 'calc(100% - 12px)', justifyContent: 'space-between'}}>
              <LabelWidget label="Total Parcels" data={chartData.totalParcels} sx={{height: '30%'}} />
              <LabelWidget
                label="Aggregate Value of Holdings"
                data={chartData.aggregateValueOfHoldings}
                type="currency"
                sx={{height: '30%'}}
              />
              <LabelWidget
                label="Total Property Taxes"
                data={chartData.totalPropertyTaxes}
                type="currency"
                sx={{height: '30%'}}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{m: 1, height: 'calc(100% - 12px)', display: 'flex', flexDirection: 'column'}}>
              <CardHeader title="Debts & Distress" />
              <Box sx={{flex: 1}}>
                {isNullOrEmpty(loansData) && <ComingSoonCover noData={true} />}
                <RadialChart labels={['Loans & Liens \nvs \nMarket value']} labelLineBreak={true} data={loansData} />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{m: 1, height: 'calc(100% - 12px)', display: 'flex', flexDirection: 'column'}}>
              <CardHeader title="Other Data" />
              <Box sx={{flex: 1}}>
                {isNullOrEmpty(propertData) && <ComingSoonCover noData={true} />}
                <RadialChart
                  labels={['Total Property Insurance vs. Market Value', 'Market Value vs. Net Worth']}
                  data={propertData}
                  showTotal={true}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
      <Card>
        <CardContent>{data.length > 0 ? renderData() : <EmptyContent />}</CardContent>
      </Card>
    </Stack>
  );
}
