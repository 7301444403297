export const INITIAL_CHARTS_DATA = {
  aggregateClaimsPaid: 0,
  lifetimeLossRatio: 0,
  totalPremiumsToClaimsPayments: {
    totalPremiums: 0,
    totalClaimsPayments: 0,
    timePeriod: 0
  },
  claimsByType: {
    propertyDamage: 0,
    liability: 0,
    uninsuredThirdParty: 0,
    other: 0
  },
  claimsByClass: {
    realEstate: 0,
    collections: 0,
    automobiles: 0,
    watercraft: 0,
    aircraft: 0,
    misc: 0,
    umbrella: 0,
    equine: 0
  },
  totalLossRetained: 0,
  claimsInExperiencePeriod: [
    {
      ratio: 0,
      year: 2015
    },
    {
      ratio: 0,
      year: 2016
    },
    {
      ratio: 0,
      year: 2017
    },
    {
      ratio: 0,
      year: 2018
    },
    {
      ratio: 0,
      year: 2019
    },
    {
      ratio: 0,
      year: 2020
    },
    {
      ratio: 0,
      year: 2021
    }
  ]
};
