import PropTypes from 'prop-types';
// material
import {alpha, styled} from '@mui/material/styles';
import {Box, IconButton, Tooltip, Typography} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
// utils
import {getFileName, getFileThumb} from 'src/utils/getFileFormat';

const ThumbStyle = styled('div')(({theme, big}) => ({
  width: big ? 120 : 48,
  height: big ? 120 : 48,
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.background.paper,
  border: `solid 1px ${theme.palette.divider}`,
  color: theme.palette.text.disabled,
  '& img': {width: '100%', height: '100%'}
}));

const DownloadStyle = styled('div')(({theme}) => ({
  opacity: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  backdropFilter: 'blur(8px)',
  WebkitBackdropFilter: 'blur(8px)', // Fix on Mobile
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: alpha(theme.palette.grey[900], 0.64),
  transition: theme.transitions.create('opacity'),
  '&:hover': {opacity: 1},
  '& svg': {
    transition: theme.transitions.create('color'),
    color: alpha(theme.palette.common.white, 0.64),
    '&:hover': {color: theme.palette.common.white}
  }
}));

FileItem.propTypes = {
  fileUrl: PropTypes.string,
  onClick: PropTypes.func,
  deletable: PropTypes.bool
};

export default function FileItem({big = false, fileUrl, fileName = '', onClick = () => {}, deletable = false}) {
  return (
    <Tooltip title={fileName || getFileName(fileUrl)} placement="top">
      <Box key={fileUrl}>
        <ThumbStyle big={big}>
          {getFileThumb(fileUrl)}
          <DownloadStyle>
            {big ? (
              <IconButton onClick={onClick}>{<VisibilityIcon />}</IconButton>
            ) : (
              <IconButton onClick={onClick}>{deletable ? <DeleteIcon /> : <CloudDownloadIcon />}</IconButton>
            )}
          </DownloadStyle>
        </ThumbStyle>
        <Box
          sx={{
            mt: 0.5,
            maxWidth: big ? 110 : 56,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Typography noWrap variant="caption">
            {fileName || getFileName(fileUrl)}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
}
