import {merge} from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import {useTheme, styled} from '@mui/material/styles';
import {Card, Typography, Box} from '@mui/material';
// utils
import {fNumber, fShortenNumber, fCurrency} from 'src/utils/formatNumber';
import BaseOptionChart from './BaseOptionChart';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({theme, hidden}) => ({
  visibility: hidden ? 'hidden' : 'visible',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  padding: theme.spacing(3)
}));

// ----------------------------------------------------------------------

export default function BoardItem({label, data, percent, digit = false, currency = false, hidden = false}) {
  const theme = useTheme();

  const chartOptions = merge(BaseOptionChart('percent'), {
    chart: {sparkline: {enabled: true}},
    legend: {show: false},
    plotOptions: {
      radialBar: {
        hollow: {size: '78%'},
        track: {margin: 0},
        dataLabels: {
          name: {show: false},
          value: {
            offsetY: 6,
            color: theme.palette.common.white,
            fontSize: theme.typography.subtitle2.fontSize
          }
        }
      }
    }
  });

  return (
    <RootStyle hidden={hidden}>
      {!!percent && (
        <ReactApexChart type="radialBar" series={[percent]} options={chartOptions} width={86} height={86} />
      )}
      <Box>
        <Typography variant="h4">
          {currency ? fCurrency(data) : digit ? fShortenNumber(data) : fNumber(data)}
        </Typography>
        <Typography variant="body2" sx={{opacity: 0.72}}>
          {label || 'Insurance Policies'}
        </Typography>
      </Box>
    </RootStyle>
  );
}
