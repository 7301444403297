import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
// mui
import {styled} from '@mui/material/styles';
import {Box, Button, AppBar, Toolbar, Container} from '@mui/material';
// hooks
import useOffSetTop from 'src/hooks/useOffSetTop';
// routes
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import LogoWithName from 'src/components/LogoWithName';
import {MHidden} from 'src/components/@material-extend';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({theme}) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar({reverseMode = true}) {
  const isOffset = useOffSetTop(100);
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const isHome = pathname === '/';

  return (
    <AppBar sx={{boxShadow: 0, bgcolor: 'transparent'}}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: {md: APP_BAR_DESKTOP - 16}
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">{reverseMode ? <LogoWithName blackMode={!isOffset} /> : <LogoWithName />}</RouterLink>
          <Box sx={{flexGrow: 1}} />
          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
          <Button variant="contained" onClick={() => navigate(PATH_DASHBOARD.root)}>
            Dashboard
          </Button>
          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
