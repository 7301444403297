import {merge} from 'lodash';
import ReactApexChart from 'react-apexcharts';
// utils
import {fCurrency, fShortCurrency} from 'src/utils/formatNumber';
import {isNullOrEmpty} from 'src/utils/checkEmpty';
// component
import BaseOptionChart from './BaseOptionChart';
// mui
import {Box, FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {useState} from 'react';

// ----------------------------------------------------------------------

const CHART_LABEL = ['Series 1', 'Series 2'];
const CHART_CATEGORIES = [2019, 2020, 2021];
const CHART_DATA = [
  [1200, 500, 100],
  [1000, 3100, 1600]
];

export default function AnnualDPChart({categories, data, label, amount = false}) {
  const isDataEmpty = isNullOrEmpty(data) || isNullOrEmpty(categories);
  const [inputCategories, inputData, inputLabel] = isDataEmpty
    ? [CHART_CATEGORIES, CHART_DATA, CHART_LABEL]
    : [[...categories].sort((a, b) => parseInt(a) - parseInt(b)), data, label];

  const series = inputLabel?.map?.((lb, index) => ({name: lb, data: inputData[index], type: 'column'}));

  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: {bar: {columnWidth: '16%'}},
    stroke: {show: false},
    xaxis: {
      categories: inputCategories
    },
    yaxis: series.map((axe, idx) => ({
      seriesName: axe.name,
      opposite: !!idx % 2,
      axisTicks: {
        show: true
      },
      axisBorder: {
        show: true
      },
      title: {
        text: axe.name
      },
      labels: {
        formatter: (val) => fShortCurrency(val)
      }
    })),
    tooltip: {
      y: {
        formatter: (val) => (amount ? val : fCurrency(val))
      }
    },
    legend: {show: true}
  });

  const [selectedPeriod, setSelectedPeriod] = useState('AY');

  const [seriesData,setSeriesData]=useState(series);
  
  const [chartOptionsData,setChartOptionsData]=useState(chartOptions);
  
  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
    const data=series;
    const chartOpts=chartOptions;
    if(event.target.value==='3Y'){
      for(let item of data){
        const length=item.data.length;
        item.data=item.data.slice(length-3);
      }
      const len=chartOpts.xaxis.categories.length;
      chartOpts.xaxis.categories=chartOpts.xaxis.categories.slice(len-3);
    }
    else if(event.target.value==='5Y'){
      for(let item of data){
        const length=item.data.length;
        item.data=item.data.slice(length-5);
      }
      const len=chartOpts.xaxis.categories.length;
      chartOpts.xaxis.categories=chartOpts.xaxis.categories.slice(len-5);
    }
    setSeriesData(data);
    setChartOptionsData(chartOpts);
  };

  return (
    <Box sx={{flex: 1, ...(isDataEmpty && {filter: 'grayscale(90%)', opacity: 0.5})}}>
      <FormControl sx={{ mb: 2, minWidth: 120, position: 'absolute', top: -40, right: 45, zIndex:1 }} size="small">
        <InputLabel>Period</InputLabel>
        <Select sx={{height:30, width:120}} value={selectedPeriod} onChange={handlePeriodChange} label="Period">
          <MenuItem value="3Y">3 Years</MenuItem>
          <MenuItem value="5Y">5 Years</MenuItem>
          <MenuItem value="AY">All Years</MenuItem>
        </Select>
      </FormControl>
      <ReactApexChart type="bar" series={seriesData} options={chartOptionsData} height={'100%'} />
    </Box>
  );
}
