import PropTypes from 'prop-types';
// mui
import {styled} from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2, 2)
}));

// ----------------------------------------------------------------------

EmptyContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default function EmptyContent({title, description, icon, ...other}) {
  return (
    <RootStyle {...other}>
      <Stack spacing={1} direction="row" sx={{alignItems: 'center'}}>
        {!!icon && icon}
        <Stack spacing={1} sx={{alignItems: 'self-start'}}>
          <Typography variant="h5" sx={{color: 'text.secondary'}} gutterBottom>
            {title || 'No Data'}
          </Typography>
          {description && (
            <Typography variant="body2" sx={{color: 'text.secondary'}}>
              {description}
            </Typography>
          )}
        </Stack>
      </Stack>
    </RootStyle>
  );
}
