import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
// mui
import {Alert, Card, CircularProgress, Stack, Typography} from '@mui/material';
// api
import {getAdvisorContactByID, getContactByID} from 'src/api/contact';
// hooks
import useAuth from 'src/hooks/useAuth';
// redux
import {useSelector} from 'src/redux/store';
// utils
import createAvatar from 'src/utils/createAvatar';
import {fPercent} from 'src/utils/formatNumber';
// components
import {MAvatar} from 'src/components/@material-extend';
// ----------------------------------------------------------------------

ContactCard.propTypes = {
  id: PropTypes.string.isRequired,
  percentage: PropTypes.number,
  userId: PropTypes.string
};

ContactCard.defaultProps = {
  userId: ''
};

export default function ContactCard({id, userId, percentage}) {
  const navigate = useNavigate();
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const {members} = useSelector((state) => state.familyGroup);
  const {list: clients} = useSelector((state) => state.client);
  const msCounts = members.filter((m) => m.show).length; // Selected Family Member Counts

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async (id) => {
      setLoading(true);
      try {
        const data = !!userId ? await getAdvisorContactByID(id, userId, !isAdvisor) : await getContactByID(id);
        if (isMounted) {
          setData(data);
        }
      } catch (err) {
        console.log('Contact-Card: ', err);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    if (id === 'self') {
      if (isAdvisor || msCounts > 0) {
        const targetClient = clients.find((item) => item.id === userId);
        if (!!targetClient) {
          setData(targetClient);
        }
      } else {
        setData({id: 'self', userId: 'self', firstName: user.firstName, lastName: user.lastName, email: user.email});
      }
    } else {
      fetchData(id);
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user]);

  const handleClick = () => {
    navigate(
      id === 'self' ? `/dashboard/user` : !userId ? `/dashboard/contact/${id}` : `/dashboard/contact/${id}/${userId}`
    );
  };

  if (loading) {
    return <CircularProgress />;
  }

  return data && !data.statusCode && (data.firstName || data.lastName) ? (
    <Card sx={{cursor: 'pointer', px: 2, py: 1, maxWidth: '240px', m: '2px'}} onClick={handleClick}>
      <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
        <MAvatar color={createAvatar(data).color} sx={{width: 42, height: 42}}>
          {createAvatar(data).name || '?'}
        </MAvatar>
        <Typography variant="body2">{[data.firstName, data.lastName].filter((item) => !!item).join(' ')}</Typography>
      </Stack>
      {percentage && <Typography>{fPercent(percentage)}</Typography>}
    </Card>
  ) : (
    <Alert severity="warning">{`Deleted contact is still connected. (id: ${id})`}</Alert>
  );
}
