export const AU_MODEL = [
  {make: 'Rover', model: '214'},
  {make: 'Smart', model: 'Cabrio'},
  {make: 'Smart', model: 'Fortwo cabrio'},
  {make: 'Fiat', model: 'Barchetta'},
  {make: 'Rover', model: '200'},
  {make: 'MINI', model: 'Cooper S Cabrio'},
  {make: 'Rover', model: '75'},
  {make: 'Rover', model: '620'},
  {make: 'MINI', model: 'Mini One'},
  {make: 'Fiat', model: 'Ducato'},
  {make: 'Daewoo', model: 'Lacetti'},
  {make: 'Volvo', model: '360'},
  {make: 'Volvo', model: 'V90'},
  {make: 'Nissan', model: '350 Z'},
  {make: 'Nissan', model: 'NP300 Pickup'},
  {make: 'Hyundai', model: 'Trajet'},
  {make: 'Hyundai', model: 'Veloster'},
  {make: 'Ford', model: 'Edge'},
  {make: 'Chrysler', model: 'Crossfire'},
  {make: 'Dodge', model: 'RAM'},
  {make: 'Land Rover', model: 'Discovery'},
  {make: 'Kia', model: 'Optima'},
  {make: 'Audi', model: 'A6 Allroad'},
  {make: 'Audi', model: 'Q3'},
  {make: 'Audi', model: 'Q5'},
  {make: 'Audi', model: 'S6/RS6'},
  {make: 'Audi', model: 'SQ5'},
  {make: 'Mercedes-Benz', model: '124'},
  {make: 'Mercedes-Benz', model: '210 kombi'},
  {make: 'Mercedes-Benz', model: 'A L'},
  {make: 'Mercedes-Benz', model: 'GLK'},
  {make: 'Suzuki', model: 'Vitara'},
  {make: 'Volkswagen', model: 'Fox'},
  {make: 'Volkswagen', model: 'Passat'},
  {make: 'Volkswagen', model: 'Passat Alltrack'},
  {make: 'Volkswagen', model: 'Polo'},
  {make: 'Fiat', model: '1100'},
  {make: 'Fiat', model: 'Qubo'},
  {make: 'Fiat', model: 'Ulysse'},
  {make: 'Nissan', model: 'Almera'},
  {make: 'Nissan', model: 'e-NV200'},
  {make: 'Jeep', model: 'Commander'},
  {make: 'Jaguar', model: 'XJ6'},
  {make: 'Infiniti', model: 'Q'},
  {make: 'Hyundai', model: 'Lantra'},
  {make: 'Ford', model: 'C-Max'},
  {make: 'Ford', model: 'Cougar'},
  {make: 'Ford', model: 'Focus C-Max'},
  {make: 'Ford', model: 'Focus CC'},
  {make: 'Ford', model: 'Kuga'},
  {make: 'Ford', model: 'Mustang'},
  {make: 'Chrysler', model: 'Sebring'},
  {make: 'Chrysler', model: 'Town & Country'},
  {make: 'Chrysler', model: 'Voyager'},
  {make: 'Kia', model: 'Carens'},
  {make: 'Kia', model: 'Soul'},
  {make: 'Audi', model: '90'},
  {make: 'Audi', model: 'A6'},
  {make: 'Audi', model: 'S4/S4 Avant'},
  {make: 'Mercedes-Benz', model: 'Trieda G'},
  {make: 'Volkswagen', model: 'Jetta'},
  {make: 'Rover', model: '25'},
  {make: 'Fiat', model: 'Brava'},
  {make: 'Fiat', model: 'Linea'},
  {make: 'Volvo', model: '340'},
  {make: 'Volvo', model: 'V60'},
  {make: 'Volvo', model: 'XC90'},
  {make: 'Nissan', model: 'Cabstar E - T'},
  {make: 'Nissan', model: 'Cabstar TL2 Valnik'},
  {make: 'Nissan', model: 'Primastar'},
  {make: 'Jaguar', model: 'XF'},
  {make: 'Hyundai', model: 'H 350'},
  {make: 'Hyundai', model: 'H200'},
  {make: 'Hyundai', model: 'i30 CW'},
  {make: 'Ford', model: 'Cortina'},
  {make: 'Ford', model: 'F-150'},
  {make: 'Ford', model: 'Mondeo'},
  {make: 'Ford', model: 'Transit Courier'},
  {make: 'Ford', model: 'Transit Custom'},
  {make: 'Chrysler', model: 'Grand Voyager'},
  {make: 'Chrysler', model: 'Sebring Convertible'},
  {make: 'Dodge', model: 'Challenger'},
  {make: 'Dodge', model: 'Viper'},
  {make: 'Land Rover', model: 'Freelander'},
  {make: 'Kia', model: 'Cee`d'},
  {make: 'Kia', model: 'Picanto'},
  {make: 'Audi', model: '100'},
  {make: 'Audi', model: 'A4 Avant'},
  {make: 'Audi', model: 'A7'},
  {make: 'Audi', model: 'RS4/RS4 Avant'},
  {make: 'Audi', model: 'RS7'},
  {make: 'Mercedes-Benz', model: '190'},
  {make: 'Mercedes-Benz', model: 'GLC'},
  {make: 'Mercedes-Benz', model: 'MB 100'},
  {make: 'Suzuki', model: 'Alto'},
  {make: 'Suzuki', model: 'Baleno'},
  {make: 'Volkswagen', model: 'Life'},
  {make: 'Volkswagen', model: 'Passat CC'},
  {make: 'MINI', model: 'Countryman'},
  {make: 'MINI', model: 'One D'},
  {make: 'Fiat', model: 'Cinquecento'},
  {make: 'Fiat', model: 'Ducato Kombi'},
  {make: 'Fiat', model: 'Ducato Podvozok'},
  {make: 'Fiat', model: 'Punto Evo'},
  {make: 'Fiat', model: 'X1/9'},
  {make: 'Nissan', model: 'Insterstar'},
  {make: 'Nissan', model: 'Primera Combi'},
  {make: 'Jaguar', model: 'X-type Estate'},
  {make: 'Jaguar', model: 'XJ'},
  {make: 'Hyundai', model: 'ix55'},
  {make: 'Ford', model: 'Orion'},
  {make: 'Ford', model: 'Transit Van'},
  {make: 'Chrysler', model: '300 C'},
  {make: 'Land Rover', model: 'Range Rover'},
  {make: 'Kia', model: 'Rio sedan'},
  {make: 'Kia', model: 'Sportage'},
  {make: 'Audi', model: 'A5 Sportback'},
  {make: 'Audi', model: 'RS4 Cabriolet'},
  {make: 'Audi', model: 'S4 Cabriolet'},
  {make: 'Saab', model: '9-5'},
  {make: 'Mercedes-Benz', model: '200 - 300'},
  {make: 'Mercedes-Benz', model: 'Trieda E'},
  {make: 'Mercedes-Benz', model: 'SLR'},
  {make: 'Suzuki', model: 'Splash'},
  {make: 'Volkswagen', model: 'California'},
  {make: 'Volkswagen', model: 'Phaeton'},
  {make: 'Fiat', model: 'Croma'},
  {make: 'Fiat', model: 'Ducato Van'},
  {make: 'Fiat', model: 'Florino Combi'},
  {make: 'Fiat', model: 'Stilo'},
  {make: 'Daewoo', model: 'Nexia'},
  {make: 'Daewoo', model: 'Nubira kombi'},
  {make: 'Volvo', model: '850 kombi'},
  {make: 'Volvo', model: 'V40'},
  {make: 'Hyundai', model: 'Coupé'},
  {make: 'Hyundai', model: 'i10'},
  {make: 'Hyundai', model: 'Santa Fe'},
  {make: 'Ford', model: 'Transit'},
  {make: 'Ford', model: 'Transit Tourneo'},
  {make: 'Ford', model: 'Windstar'},
  {make: 'Land Rover', model: 'Range Rover Evoque'},
  {make: 'Audi', model: '80 Avant'},
  {make: 'Audi', model: 'A4 Cabriolet'},
  {make: 'Audi', model: 'A5'},
  {make: 'Audi', model: 'A6 Avant'},
  {make: 'Audi', model: 'Q7'},
  {make: 'Saab', model: '9-3 Coupé'},
  {make: 'Mercedes-Benz', model: '100 D'},
  {make: 'Mercedes-Benz', model: 'CL'},
  {make: 'Mercedes-Benz', model: 'Trieda R'},
  {make: 'Mercedes-Benz', model: 'SLK'},
  {make: 'Suzuki', model: 'Baleno kombi'},
  {make: 'Volkswagen', model: 'Caddy Van'},
  {make: 'Volkswagen', model: 'CrossTouran'},
  {make: 'Volkswagen', model: 'Lupo'},
  {make: 'Rover', model: '218'},
  {make: 'MINI', model: 'Cooper Clubman'},
  {make: 'MINI', model: 'Cooper D Clubman'},
  {make: 'Fiat', model: 'Multipla'},
  {make: 'Volvo', model: '850'},
  {make: 'Volvo', model: 'C70'},
  {make: 'Volvo', model: 'S70'},
  {make: 'Nissan', model: '370 Z'},
  {make: 'Nissan', model: 'Primera'},
  {make: 'Nissan', model: 'Serena'},
  {make: 'Jaguar', model: 'S-Type'},
  {make: 'Jaguar', model: 'Sovereign'},
  {make: 'Hyundai', model: 'Atos'},
  {make: 'Hyundai', model: 'H1'},
  {make: 'Ford', model: 'Escort'},
  {make: 'Ford', model: 'Escort Cabrio'},
  {make: 'Ford', model: 'Focus kombi'},
  {make: 'Ford', model: 'S-Max'},
  {make: 'Ford', model: 'Tourneo Custom'},
  {make: 'Chrysler', model: 'PT Cruiser'},
  {make: 'Dodge', model: 'Magnum'},
  {make: 'Kia', model: 'Venga'},
  {make: 'Audi', model: 'A3'},
  {make: 'Audi', model: 'A4 Allroad'},
  {make: 'Audi', model: 'TT Coupé'},
  {make: 'Saab', model: '900'},
  {make: 'Mercedes-Benz', model: '126'},
  {make: 'Mercedes-Benz', model: 'A'},
  {make: 'Mercedes-Benz', model: 'S Coupé'},
  {make: 'Suzuki', model: 'Grand Vitara'},
  {make: 'Volkswagen', model: 'Multivan'},
  {make: 'Volkswagen', model: 'T4 Caravelle'},
  {make: 'Volkswagen', model: 'T5 Transporter Shuttle'},
  {make: 'Volkswagen', model: 'Touareg'},
  {make: 'Smart', model: 'Forfour'},
  {make: 'Smart', model: 'Roadster'},
  {make: 'Rover', model: '414'},
  {make: 'Fiat', model: '500X'},
  {make: 'Fiat', model: 'Idea'},
  {make: 'Fiat', model: 'Punto Van'},
  {make: 'Fiat', model: 'Stilo Multiwagon'},
  {make: 'Daewoo', model: 'Tico'},
  {make: 'Nissan', model: 'Note'},
  {make: 'Nissan', model: 'Patrol'},
  {make: 'Nissan', model: 'X-Trail'},
  {make: 'Jaguar', model: 'XKR Convertible'},
  {make: 'Hyundai', model: 'Grandeur'},
  {make: 'Hyundai', model: 'H1 Bus'},
  {make: 'Hyundai', model: 'ix20'},
  {make: 'Ford', model: 'Galaxy'},
  {make: 'Ford', model: 'Ranger'},
  {make: 'Ford', model: 'Street Ka'},
  {make: 'Ford', model: 'Transit Connect LWB'},
  {make: 'Ford', model: 'Transit Van 350'},
  {make: 'Chrysler', model: 'Plymouth'},
  {make: 'Chrysler', model: 'Stratus Cabrio'},
  {make: 'Kia', model: 'K 2500'},
  {make: 'Audi', model: '80 Cabrio'},
  {make: 'Audi', model: 'A1'},
  {make: 'Audi', model: 'A5 Cabriolet'},
  {make: 'Mercedes-Benz', model: '200 E'},
  {make: 'Mercedes-Benz', model: '210 Van'},
  {make: 'Mercedes-Benz', model: '260 - 560 SEL'},
  {make: 'Mercedes-Benz', model: 'C Sportcoupé'},
  {make: 'Mercedes-Benz', model: 'CLA'},
  {make: 'Mercedes-Benz', model: 'E Cabrio'},
  {make: 'Mercedes-Benz', model: 'E Coupé'},
  {make: 'Mercedes-Benz', model: 'Trieda S'},
  {make: 'Mercedes-Benz', model: 'SLC'},
  {make: 'Mercedes-Benz', model: 'Sprinter'},
  {make: 'Volkswagen', model: 'Eos'},
  {make: 'Volkswagen', model: 'Golf Plus'},
  {make: 'Volkswagen', model: 'Scirocco'},
  {make: 'Rover', model: '416'},
  {make: 'MINI', model: 'Cooper S Clubman'},
  {make: 'Fiat', model: '126'},
  {make: 'Fiat', model: 'Doblo'},
  {make: 'Fiat', model: 'Doblo Cargo'},
  {make: 'Fiat', model: 'Doblo Cargo Combi'},
  {make: 'Fiat', model: 'Palio Weekend'},
  {make: 'Fiat', model: 'Scudo Kombi'},
  {make: 'Fiat', model: 'Seicento'},
  {make: 'Volvo', model: 'C70 Coupé'},
  {make: 'Nissan', model: 'Maxima'},
  {make: 'Ford', model: 'Transit Bus'},
  {make: 'Chrysler', model: 'Neon'},
  {make: 'Land Rover', model: '109'},
  {make: 'Kia', model: 'Carnival'},
  {make: 'Kia', model: 'Rio'},
  {make: 'Saab', model: '9-3 Cabriolet'},
  {make: 'Saab', model: '9000'},
  {make: 'Mercedes-Benz', model: '115'},
  {make: 'Mercedes-Benz', model: '310 kombi'},
  {make: 'Mercedes-Benz', model: 'E'},
  {make: 'Mercedes-Benz', model: 'GL'},
  {make: 'Volkswagen', model: 'Golf Variant'},
  {make: 'Volkswagen', model: 'T4 Multivan'},
  {make: 'Volkswagen', model: 'T5 Multivan'},
  {make: 'Fiat', model: 'Marea Weekend'},
  {make: 'Daewoo', model: 'Kalos'},
  {make: 'Daewoo', model: 'Lanos'},
  {make: 'Daewoo', model: 'Leganza'},
  {make: 'Daewoo', model: 'Matiz'},
  {make: 'Daewoo', model: 'Nubira'},
  {make: 'Volvo', model: 'S40'},
  {make: 'Nissan', model: '100 NX'},
  {make: 'Nissan', model: 'Maxima QX'},
  {make: 'Nissan', model: 'Vanette Cargo'},
  {make: 'Jaguar', model: 'X-Type'},
  {make: 'Jaguar', model: 'XE'},
  {make: 'Hyundai', model: 'H1 Van'},
  {make: 'Hyundai', model: 'Sonata'},
  {make: 'Hummer', model: 'H2'},
  {make: 'Hummer', model: 'H3'},
  {make: 'Ford', model: 'Transit Valnik'},
  {make: 'Chrysler', model: '300 M'},
  {make: 'Kia', model: 'Opirus'},
  {make: 'Kia', model: 'Pregio'},
  {make: 'Audi', model: 'R8'},
  {make: 'Audi', model: 'RS6 Avant'},
  {make: 'Saab', model: '9-3 SportCombi'},
  {make: 'Mercedes-Benz', model: 'AMG GT'},
  {make: 'Mercedes-Benz', model: 'S'},
  {make: 'Suzuki', model: 'SX4 Sedan'},
  {make: 'Volkswagen', model: 'Caravelle'},
  {make: 'Volkswagen', model: 'Golf'},
  {make: 'Volkswagen', model: 'Golf Sportvan'},
  {make: 'Volkswagen', model: 'New Beetle'},
  {make: 'Volkswagen', model: 'Tiguan'},
  {make: 'MINI', model: 'Cooper D'},
  {make: 'Smart', model: 'City-Coupé'},
  {make: 'Smart', model: 'Compact Pulse'},
  {make: 'Fiat', model: '850'},
  {make: 'Fiat', model: 'Punto Cabriolet'},
  {make: 'Volvo', model: 'C70 Cabrio'},
  {make: 'Volvo', model: 'S90'},
  {make: 'Volvo', model: 'V50'},
  {make: 'Nissan', model: 'Micra'},
  {make: 'Jaguar', model: 'XJ8'},
  {make: 'Hyundai', model: 'Accent'},
  {make: 'Ford', model: 'B-Max'},
  {make: 'Ford', model: 'Explorer'},
  {make: 'Ford', model: 'Ka'},
  {make: 'Dodge', model: 'Avenger'},
  {make: 'Dodge', model: 'Caliber'},
  {make: 'Dodge', model: 'Grand Caravan'},
  {make: 'Dodge', model: 'Journey'},
  {make: 'Kia', model: 'Magentis'},
  {make: 'Mercedes-Benz', model: 'Trieda B'},
  {make: 'Mercedes-Benz', model: 'SL'},
  {make: 'Suzuki', model: 'Liana'},
  {make: 'Volkswagen', model: 'Bora Variant'},
  {make: 'Volkswagen', model: 'Crafter Van'},
  {make: 'Volkswagen', model: 'New Beetle Cabrio'},
  {make: 'Volkswagen', model: 'Polo Van'},
  {make: 'Volkswagen', model: 'Sharan'},
  {make: 'MINI', model: 'Cooper'},
  {make: 'Fiat', model: 'Florino'},
  {make: 'Fiat', model: 'Punto'},
  {make: 'Fiat', model: 'Tipo'},
  {make: 'Volvo', model: 'S80'},
  {make: 'Nissan', model: 'Almera Tino'},
  {make: 'Nissan', model: 'GT-R'},
  {make: 'Nissan', model: 'King Cab'},
  {make: 'Nissan', model: 'Murano'},
  {make: 'Nissan', model: 'Navara'},
  {make: 'Nissan', model: 'Pixo'},
  {make: 'Nissan', model: 'Primastar Combi'},
  {make: 'Nissan', model: 'Sunny'},
  {make: 'Jeep', model: 'Grand Cherokee'},
  {make: 'Jeep', model: 'Patriot'},
  {make: 'Hyundai', model: 'Elantra'},
  {make: 'Hyundai', model: 'Genesis'},
  {make: 'Hyundai', model: 'Getz'},
  {make: 'Hyundai', model: 'ix35'},
  {make: 'Hyundai', model: 'Tucson'},
  {make: 'Ford', model: 'Fusion'},
  {make: 'Ford', model: 'Transit'},
  {make: 'Chrysler', model: 'Pacifica'},
  {make: 'Kia', model: 'Cerato'},
  {make: 'Audi', model: 'A2'},
  {make: 'Audi', model: 'A4'},
  {make: 'Mercedes-Benz', model: '230 - 300 CE Coupé'},
  {make: 'Mercedes-Benz', model: 'Trieda A'},
  {make: 'Mercedes-Benz', model: 'Trieda C'},
  {make: 'Mercedes-Benz', model: 'CLC'},
  {make: 'Mercedes-Benz', model: 'G Cabrio'},
  {make: 'Mercedes-Benz', model: 'Trieda M'},
  {make: 'Suzuki', model: 'Kizashi'},
  {make: 'Suzuki', model: 'Samurai'},
  {make: 'Suzuki', model: 'SX4'},
  {make: 'Volkswagen', model: 'Polo Variant'},
  {make: 'Smart', model: 'Fortwo coupé'},
  {make: 'Rover', model: '400'},
  {make: 'MINI', model: 'Cooper Cabrio'},
  {make: 'Fiat', model: 'Grande Punto'},
  {make: 'Fiat', model: 'Panda Van'},
  {make: 'Fiat', model: 'Scudo'},
  {make: 'Fiat', model: 'Sedici'},
  {make: 'Fiat', model: 'Uno'},
  {make: 'Daewoo', model: 'Espero'},
  {make: 'Volvo', model: '240'},
  {make: 'Volvo', model: 'S60'},
  {make: 'Volvo', model: 'XC60'},
  {make: 'Volvo', model: 'XC70'},
  {make: 'Nissan', model: 'NV400'},
  {make: 'Nissan', model: 'Patrol GR'},
  {make: 'Nissan', model: 'Qashqai'},
  {make: 'Jeep', model: 'Renegade'},
  {make: 'Jaguar', model: 'XJ8'},
  {make: 'Ford', model: 'Fiesta'},
  {make: 'Ford', model: 'Focus'},
  {make: 'Ford', model: 'Maverick'},
  {make: 'Ford', model: 'Transit kombi'},
  {make: 'Land Rover', model: 'Defender'},
  {make: 'Kia', model: 'Sorento'},
  {make: 'Audi', model: '100 Avant'},
  {make: 'Audi', model: 'A3 Limuzina'},
  {make: 'Audi', model: 'A8 Long'},
  {make: 'Mercedes-Benz', model: '500 - 600 SEC Coupé'},
  {make: 'Mercedes-Benz', model: 'CLK Cabrio'},
  {make: 'Mercedes-Benz', model: 'GLA'},
  {make: 'Suzuki', model: 'Jimny'},
  {make: 'Suzuki', model: 'Wagon R+'},
  {make: 'Volkswagen', model: 'Amarok'},
  {make: 'Volkswagen', model: 'Beetle'},
  {make: 'Volkswagen', model: 'Crafter Kombi'},
  {make: 'Volkswagen', model: 'T4'},
  {make: 'MINI', model: 'Cooper S'},
  {make: 'Fiat', model: '500L'},
  {make: 'Daewoo', model: 'Racer'},
  {make: 'Nissan', model: '200 SX'},
  {make: 'Nissan', model: '350 Z Roadster'},
  {make: 'Nissan', model: 'Juke'},
  {make: 'Nissan', model: 'NV200'},
  {make: 'Nissan', model: 'Tiida'},
  {make: 'Nissan', model: 'Trade'},
  {make: 'Jeep', model: 'Wrangler'},
  {make: 'Jaguar', model: 'XJR'},
  {make: 'Infiniti', model: 'EX'},
  {make: 'Infiniti', model: 'G'},
  {make: 'Ford', model: 'Escort kombi'},
  {make: 'Ford', model: 'Grand C-Max'},
  {make: 'Ford', model: 'Mondeo Combi'},
  {make: 'Ford', model: 'Tourneo Connect'},
  {make: 'Dodge', model: 'Stealth'},
  {make: 'Land Rover', model: 'Discovery Sport'},
  {make: 'Kia', model: 'Avella'},
  {make: 'Audi', model: '80'},
  {make: 'Audi', model: 'S5/S5 Cabriolet'},
  {make: 'Mercedes-Benz', model: '310 Van'},
  {make: 'Mercedes-Benz', model: '260 - 560 SE'},
  {make: 'Mercedes-Benz', model: 'CL'},
  {make: 'Mercedes-Benz', model: 'CLK Coupé'},
  {make: 'Mercedes-Benz', model: 'E T'},
  {make: 'Volkswagen', model: 'Crafter'},
  {make: 'Volkswagen', model: 'T5 Caravelle'},
  {make: 'Fiat', model: 'Marea'},
  {make: 'Fiat', model: '500'},
  {make: 'Volvo', model: '460'},
  {make: 'Volvo', model: 'C30'},
  {make: 'Nissan', model: 'Pickup'},
  {make: 'Nissan', model: 'Pulsar'},
  {make: 'Jeep', model: 'Cherokee'},
  {make: 'Infiniti', model: 'M'},
  {make: 'Hyundai', model: 'Atos Prime'},
  {make: 'Hyundai', model: 'i30'},
  {make: 'Hyundai', model: 'i40'},
  {make: 'Ford', model: 'F-250'},
  {make: 'Land Rover', model: 'Range Rover Sport'},
  {make: 'Kia', model: 'Besta'},
  {make: 'Kia', model: 'Cee`d SW'},
  {make: 'Kia', model: 'Pride'},
  {make: 'Audi', model: 'A8'},
  {make: 'Audi', model: 'S3/S3 Sportback'},
  {make: 'Audi', model: 'TT Roadster'},
  {make: 'Saab', model: '9-3'},
  {make: 'Saab', model: '9-5 SportCombi'},
  {make: 'Mercedes-Benz', model: '190 D'},
  {make: 'Mercedes-Benz', model: '200 D'},
  {make: 'Mercedes-Benz', model: 'C'},
  {make: 'Mercedes-Benz', model: 'CLS'},
  {make: 'Suzuki', model: 'Ignis'},
  {make: 'Volkswagen', model: 'Golf Cabrio'},
  {make: 'Volkswagen', model: 'Passat Variant Van'},
  {make: 'Fiat', model: 'Coupé'},
  {make: 'Fiat', model: 'Panda'},
  {make: 'Fiat', model: 'Scudo Van'},
  {make: 'Fiat', model: 'Talento'},
  {make: 'Volvo', model: 'V70'},
  {make: 'Nissan', model: 'Leaf'},
  {make: 'Jeep', model: 'Compass'},
  {make: 'Jaguar', model: 'F-Pace'},
  {make: 'Jaguar', model: 'F-Type'},
  {make: 'Jaguar', model: 'XJ12'},
  {make: 'Jaguar', model: 'XK'},
  {make: 'Jaguar', model: 'XKR'},
  {make: 'Infiniti', model: 'G Coupé'},
  {make: 'Hyundai', model: 'i40 CW'},
  {make: 'Hyundai', model: 'Matrix'},
  {make: 'Ford', model: 'Sierra'},
  {make: 'Chrysler', model: '300 C Touring'},
  {make: 'Dodge', model: 'Charger'},
  {make: 'Kia', model: 'Pro Cee`d'},
  {make: 'Kia', model: 'Rio Combi'},
  {make: 'Kia', model: 'Sephia'},
  {make: 'Audi', model: 'A3 Cabriolet'},
  {make: 'Audi', model: 'S7'},
  {make: 'Saab', model: '900 C'},
  {make: 'Mercedes-Benz', model: '190 E'},
  {make: 'Mercedes-Benz', model: 'C T'},
  {make: 'Mercedes-Benz', model: 'GLE'},
  {make: 'Suzuki', model: 'Swift'},
  {make: 'Volkswagen', model: 'Caddy'},
  {make: 'Volkswagen', model: 'LT'},
  {make: 'Volkswagen', model: 'Passat Variant'},
  {make: 'Fiat', model: 'Freemont'},
  {make: 'Fiat', model: 'Strada'},
  {make: 'Daewoo', model: 'Lublin'},
  {make: 'Daewoo', model: 'Tacuma'},
  {make: 'Nissan', model: 'Pathfinder'},
  {make: 'Nissan', model: 'Terrano'},
  {make: 'Jaguar', model: 'Daimler'},
  {make: 'Jaguar', model: 'XK8 Convertible'},
  {make: 'Infiniti', model: 'FX'},
  {make: 'Infiniti', model: 'QX'},
  {make: 'Hyundai', model: 'Galloper'},
  {make: 'Hyundai', model: 'i20'},
  {make: 'Hyundai', model: 'Terracan'},
  {make: 'Ford', model: 'Aerostar'},
  {make: 'Ford', model: 'Puma'},
  {make: 'Chrysler', model: 'LHS'},
  {make: 'Chrysler', model: 'Stratus'},
  {make: 'Dodge', model: 'Nitro'},
  {make: 'Kia', model: 'Shuma'},
  {make: 'Audi', model: 'A3 Sportback'},
  {make: 'Audi', model: 'RS5'},
  {make: 'Audi', model: 'S8'},
  {make: 'Audi', model: 'TTS'},
  {make: 'Saab', model: '900 C Turbo'},
  {make: 'Mercedes-Benz', model: 'Citan'},
  {make: 'Suzuki', model: 'Grand Vitara XL-7'},
  {make: 'Volkswagen', model: 'Bora'},
  {make: 'Volkswagen', model: 'CC'},
  {make: 'Volkswagen', model: 'T5'},
  {make: 'BMW', model: 'M4'},
  {make: 'BMW', model: 'Rad 5 Touring'},
  {make: 'BMW', model: 'Rad 1 Cabrio'},
  {make: 'BMW', model: 'Rad 2 Coupé'},
  {make: 'BMW', model: 'Rad 3 Touring'},
  {make: 'BMW', model: 'Rad 4 Gran Coupé'},
  {make: 'Toyota', model: 'Auris'},
  {make: 'Toyota', model: 'Aygo'},
  {make: 'Toyota', model: 'GT86'},
  {make: 'Mazda', model: '323'},
  {make: 'Mazda', model: '323 Combi'},
  {make: 'Mazda', model: 'Demio'},
  {make: 'Subaru', model: 'XV'},
  {make: 'Honda', model: 'CR-Z'},
  {make: 'Honda', model: 'Legend'},
  {make: 'Chevrolet', model: 'Corvette'},
  {make: 'Chevrolet', model: 'Lacetti'},
  {make: 'Chevrolet', model: 'Tacuma'},
  {make: 'Škoda', model: 'Citigo'},
  {make: 'Alfa Romeo', model: '146'},
  {make: 'Opel', model: 'Frontera'},
  {make: 'Citroën', model: 'Berlingo'},
  {make: 'Peugeot', model: '307'},
  {make: 'Peugeot', model: '307 SW'},
  {make: 'Peugeot', model: '308 CC'},
  {make: 'Peugeot', model: '407'},
  {make: 'Renault', model: 'Kadjar'},
  {make: 'Renault', model: 'Mégane'},
  {make: 'Seat', model: 'Exeo ST'},
  {make: 'BMW', model: 'Rad 3 Compact'},
  {make: 'Toyota', model: 'Avensis'},
  {make: 'Mazda', model: '323 Coupé'},
  {make: 'Subaru', model: 'SVX'},
  {make: 'Subaru', model: 'Tribeca'},
  {make: 'Chevrolet', model: 'Aveo'},
  {make: 'Chevrolet', model: 'Evanda'},
  {make: 'Škoda', model: 'Felicia'},
  {make: 'Škoda', model: 'Felicia Combi'},
  {make: 'Alfa Romeo', model: '159 Sportwagon'},
  {make: 'Opel', model: 'Astra caravan'},
  {make: 'Opel', model: 'Vectra'},
  {make: 'Citroën', model: 'C2'},
  {make: 'Citroën', model: 'Evasion'},
  {make: 'Dacia', model: 'Duster'},
  {make: 'Peugeot', model: '1007'},
  {make: 'Renault', model: 'Laguna Grandtour'},
  {make: 'Renault', model: 'Latitude'},
  {make: 'BMW', model: 'Rad 6'},
  {make: 'BMW', model: 'Rad 2'},
  {make: 'BMW', model: 'Rad 2 Gran Tourer'},
  {make: 'BMW', model: 'Rad 6 Gran Coupé'},
  {make: 'BMW', model: 'X6'},
  {make: 'BMW', model: 'Z4 Roadster'},
  {make: 'Mazda', model: '2'},
  {make: 'Mazda', model: 'B-Fighter'},
  {make: 'Mazda', model: 'MPV'},
  {make: 'Mazda', model: 'Xedox 6'},
  {make: 'Subaru', model: 'Impreza Wagon'},
  {make: 'Subaru', model: 'Legacy Wagon'},
  {make: 'Honda', model: 'Civic Tourer'},
  {make: 'Honda', model: 'CR-V'},
  {make: 'Porsche', model: '911 Targa'},
  {make: 'Chevrolet', model: 'Camaro'},
  {make: 'Škoda', model: 'Favorit'},
  {make: 'Škoda', model: 'Fabia'},
  {make: 'Škoda', model: 'Fabia Sedan'},
  {make: 'Alfa Romeo', model: '4C'},
  {make: 'Citroën', model: 'C5 Tourer'},
  {make: 'Citroën', model: 'C8'},
  {make: 'Citroën', model: 'Saxo'},
  {make: 'Renault', model: 'Captur'},
  {make: 'Seat', model: 'Toledo'},
  {make: 'BMW', model: 'Rad 3'},
  {make: 'BMW', model: 'Rad 4 Cabrio'},
  {make: 'BMW', model: 'Rad 5 GT'},
  {make: 'BMW', model: 'Rad 7'},
  {make: 'Toyota', model: 'Prius'},
  {make: 'Lexus', model: 'GS'},
  {make: 'Mitsubishi', model: 'Galant Combi'},
  {make: 'Mitsubishi', model: 'Grandis'},
  {make: 'Mazda', model: '3'},
  {make: 'Mazda', model: '6'},
  {make: 'Subaru', model: 'Legacy'},
  {make: 'Honda', model: 'Civic Type R'},
  {make: 'Porsche', model: 'Macan'},
  {make: 'Chevrolet', model: 'Suburban'},
  {make: 'Alfa Romeo', model: '147'},
  {make: 'Opel', model: 'Insignia kombi'},
  {make: 'Citroën', model: 'C4 Grand Picasso'},
  {make: 'Dacia', model: 'Dokker'},
  {make: 'Dacia', model: 'Logan MCV'},
  {make: 'Peugeot', model: '107'},
  {make: 'Peugeot', model: '4008'},
  {make: 'Peugeot', model: '508'},
  {make: 'Peugeot', model: 'Bipper'},
  {make: 'Renault', model: 'Mégane Scénic'},
  {make: 'Seat', model: 'Ibiza'},
  {make: 'Seat', model: 'Ibiza ST'},
  {make: 'BMW', model: 'X4'},
  {make: 'Toyota', model: 'Urban Cruiser'},
  {make: 'Toyota', model: 'Verso'},
  {make: 'Toyota', model: 'Yaris'},
  {make: 'Lexus', model: 'IS 250 C'},
  {make: 'Mitsubishi', model: 'Eclipse'},
  {make: 'Mitsubishi', model: 'L300'},
  {make: 'Mazda', model: '121'},
  {make: 'Mazda', model: 'CX-3'},
  {make: 'Mazda', model: 'Premacy'},
  {make: 'Honda', model: 'CR-X'},
  {make: 'Honda', model: 'Insight'},
  {make: 'Porsche', model: 'Boxster'},
  {make: 'Chevrolet', model: 'Matiz'},
  {make: 'Chevrolet', model: 'Monte Carlo'},
  {make: 'Škoda', model: 'Roomster'},
  {make: 'Škoda', model: 'Rapid'},
  {make: 'Škoda', model: 'Superb Combi'},
  {make: 'Opel', model: 'Antara'},
  {make: 'Opel', model: 'Movano'},
  {make: 'Citroën', model: 'DS4'},
  {make: 'Dacia', model: 'Lodgy'},
  {make: 'Peugeot', model: '5008'},
  {make: 'Peugeot', model: 'RCZ'},
  {make: 'Renault', model: 'Kangoo Express'},
  {make: 'Renault', model: 'Mégane Combi'},
  {make: 'Renault', model: 'Zoé'},
  {make: 'Seat', model: 'Arosa'},
  {make: 'BMW', model: 'X5'},
  {make: 'Toyota', model: 'Avensis Combi'},
  {make: 'Toyota', model: 'Picnic'},
  {make: 'Toyota', model: 'Tundra'},
  {make: 'Lexus', model: 'SC 430'},
  {make: 'Mitsubishi', model: '3000 GT'},
  {make: 'Mitsubishi', model: 'ASX'},
  {make: 'Mitsubishi', model: 'Pajero Wagon'},
  {make: 'Mazda', model: '626'},
  {make: 'Mazda', model: 'RX-7'},
  {make: 'Subaru', model: 'Levorg'},
  {make: 'Honda', model: 'Accord'},
  {make: 'Honda', model: 'Jazz'},
  {make: 'Honda', model: 'Prelude'},
  {make: 'Porsche', model: 'Cayman'},
  {make: 'Porsche', model: 'Panamera'},
  {make: 'Chevrolet', model: 'Kalos'},
  {make: 'Chevrolet', model: 'Lumina'},
  {make: 'Alfa Romeo', model: 'Giulia'},
  {make: 'Opel', model: 'Campo'},
  {make: 'Opel', model: 'Vivaro'},
  {make: 'Citroën', model: 'C4'},
  {make: 'Citroën', model: 'C4 Cactus'},
  {make: 'Citroën', model: 'Xantia'},
  {make: 'Peugeot', model: '306'},
  {make: 'Renault', model: 'Mégane CC'},
  {make: 'Renault', model: 'Mégane Coupé'},
  {make: 'BMW', model: 'i3'},
  {make: 'BMW', model: 'Rad 2 Active Tourer'},
  {make: 'BMW', model: 'Z4'},
  {make: 'Toyota', model: 'Celica'},
  {make: 'Toyota', model: 'Highlander'},
  {make: 'Toyota', model: 'Hilux'},
  {make: 'Lexus', model: 'IS'},
  {make: 'Lexus', model: 'LS'},
  {make: 'Mitsubishi', model: 'Colt'},
  {make: 'Mitsubishi', model: 'Lancer Evo'},
  {make: 'Subaru', model: 'Outback'},
  {make: 'Porsche', model: '911 Carrera'},
  {make: 'Porsche', model: '944'},
  {make: 'Chevrolet', model: 'HHR'},
  {make: 'Chevrolet', model: 'Lacetti SW'},
  {make: 'Škoda', model: 'Fabia Combi'},
  {make: 'Škoda', model: 'Yeti'},
  {make: 'Alfa Romeo', model: 'GT'},
  {make: 'Peugeot', model: '207 SW'},
  {make: 'Peugeot', model: '4007'},
  {make: 'Peugeot', model: '605'},
  {make: 'Renault', model: 'Clio'},
  {make: 'Renault', model: 'Fluence'},
  {make: 'Seat', model: 'Cordoba Vario'},
  {make: 'BMW', model: 'M5'},
  {make: 'Volkswagen', model: 'Touran'},
  {make: 'Toyota', model: 'Corolla sedan'},
  {make: 'Toyota', model: 'Starlet'},
  {make: 'Lexus', model: 'RX 300'},
  {make: 'Lexus', model: 'RX 400h'},
  {make: 'Lexus', model: 'RX 450h'},
  {make: 'Mitsubishi', model: 'Lancer Sportback'},
  {make: 'Honda', model: 'Civic Aerodeck'},
  {make: 'Chevrolet', model: 'Equinox'},
  {make: 'Chevrolet', model: 'Malibu'},
  {make: 'Alfa Romeo', model: '156'},
  {make: 'Alfa Romeo', model: '166'},
  {make: 'Alfa Romeo', model: 'Spider'},
  {make: 'Opel', model: 'Calibra'},
  {make: 'Opel', model: 'Cascada'},
  {make: 'Opel', model: 'Insignia'},
  {make: 'Opel', model: 'Meriva'},
  {make: 'Opel', model: 'Zafira'},
  {make: 'Citroën', model: 'C4 Coupé'},
  {make: 'Citroën', model: 'DS5'},
  {make: 'Peugeot', model: '207 CC'},
  {make: 'Renault', model: 'Espace'},
  {make: 'Renault', model: 'Laguna'},
  {make: 'BMW', model: 'Rad 4'},
  {make: 'BMW', model: 'Z3'},
  {make: 'Toyota', model: 'Camry'},
  {make: 'Toyota', model: 'Land Cruiser'},
  {make: 'Toyota', model: 'Sequoia'},
  {make: 'Toyota', model: 'Yaris Verso'},
  {make: 'Lexus', model: 'IS 200'},
  {make: 'Lexus', model: 'RC F'},
  {make: 'Mitsubishi', model: 'Outlander'},
  {make: 'Honda', model: 'HR-V'},
  {make: 'Porsche', model: '911 Turbo'},
  {make: 'Chevrolet', model: 'Captiva'},
  {make: 'Chevrolet', model: 'Trax'},
  {make: 'Alfa Romeo', model: '155'},
  {make: 'Alfa Romeo', model: '159'},
  {make: 'Opel', model: 'Agila'},
  {make: 'Citroën', model: 'C3 Picasso'},
  {make: 'Peugeot', model: '308 SW'},
  {make: 'Peugeot', model: '607'},
  {make: 'Renault', model: 'Grand Modus'},
  {make: 'Renault', model: 'Koleos'},
  {make: 'BMW', model: 'Rad 3 GT'},
  {make: 'BMW', model: 'M6'},
  {make: 'Toyota', model: 'Avensis Van Verso'},
  {make: 'Toyota', model: 'RAV4'},
  {make: 'Lexus', model: 'NX'},
  {make: 'Mitsubishi', model: 'Carisma'},
  {make: 'Mitsubishi', model: 'L200'},
  {make: 'Mitsubishi', model: 'Lancer Combi'},
  {make: 'Mitsubishi', model: 'Space Star'},
  {make: 'Mazda', model: 'B2500'},
  {make: 'Subaru', model: 'Forester'},
  {make: 'Subaru', model: 'Tribeca B9'},
  {make: 'Honda', model: 'Civic'},
  {make: 'Porsche', model: '924'},
  {make: 'Chevrolet', model: 'Cruze SW'},
  {make: 'Chevrolet', model: 'Tahoe'},
  {make: 'Alfa Romeo', model: 'GTV'},
  {make: 'Alfa Romeo', model: 'Crosswagon'},
  {make: 'Opel', model: 'Signum'},
  {make: 'Citroën', model: 'Xsara'},
  {make: 'Dacia', model: 'Logan Van'},
  {make: 'Peugeot', model: '205 Cabrio'},
  {make: 'Peugeot', model: '307 CC'},
  {make: 'Peugeot', model: '405'},
  {make: 'Renault', model: 'Talisman'},
  {make: 'Seat', model: 'Alhambra'},
  {make: 'BMW', model: 'Rad 6 Coupé'},
  {make: 'Toyota', model: 'Corolla Combi'},
  {make: 'Lexus', model: 'CT'},
  {make: 'Lexus', model: 'LX'},
  {make: 'Mitsubishi', model: 'Colt CC'},
  {make: 'Mitsubishi', model: 'Pajero Pinin Wagon'},
  {make: 'Mazda', model: 'MX-5'},
  {make: 'Mazda', model: 'MX-6'},
  {make: 'Subaru', model: 'Justy'},
  {make: 'Porsche', model: '911 Carrera Cabrio'},
  {make: 'Chevrolet', model: 'Epica'},
  {make: 'Alfa Romeo', model: 'Giulietta'},
  {make: 'Opel', model: 'Astra cabrio'},
  {make: 'Opel', model: 'Kadett'},
  {make: 'Opel', model: 'Vectra Caravan'},
  {make: 'Citroën', model: 'C-Zero'},
  {make: 'Citroën', model: 'C4 Aircross'},
  {make: 'Citroën', model: 'C4 Sedan'},
  {make: 'Peugeot', model: '207'},
  {make: 'Peugeot', model: '309'},
  {make: 'Peugeot', model: '807'},
  {make: 'Renault', model: 'Clio Grandtour'},
  {make: 'Renault', model: 'Mascott'},
  {make: 'Seat', model: 'Leon ST'},
  {make: 'BMW', model: 'Rad 1 Coupé'},
  {make: 'BMW', model: 'Rad 3 Cabrio'},
  {make: 'BMW', model: 'Rad 6 Cabrio'},
  {make: 'Toyota', model: '4-Runner'},
  {make: 'Toyota', model: 'Corolla'},
  {make: 'Toyota', model: 'Corolla Verso'},
  {make: 'Lexus', model: 'GX'},
  {make: 'Lexus', model: 'IS-F'},
  {make: 'Mitsubishi', model: 'Pajero Sport'},
  {make: 'Mazda', model: 'MX-3'},
  {make: 'Mazda', model: 'RX-8'},
  {make: 'Subaru', model: 'BRZ'},
  {make: 'Subaru', model: 'Impreza'},
  {make: 'Subaru', model: 'Legacy Outback'},
  {make: 'Honda', model: 'FR-V'},
  {make: 'Chevrolet', model: 'Nubira'},
  {make: 'Chevrolet', model: 'Orlando'},
  {make: 'Opel', model: 'Astra coupé'},
  {make: 'Opel', model: 'Mokka'},
  {make: 'Opel', model: 'Vivaro Kombi'},
  {make: 'Citroën', model: 'C-Elissée'},
  {make: 'Citroën', model: 'C5 Break'},
  {make: 'Citroën', model: 'Jumper'},
  {make: 'Dacia', model: 'Logan'},
  {make: 'Peugeot', model: '206'},
  {make: 'Peugeot', model: '308'},
  {make: 'Peugeot', model: '508 SW'},
  {make: 'Peugeot', model: '806'},
  {make: 'Renault', model: 'Grand Scenic'},
  {make: 'Renault', model: 'Kangoo'},
  {make: 'Seat', model: 'Altea'},
  {make: 'BMW', model: 'M3'},
  {make: 'BMW', model: 'i8'},
  {make: 'BMW', model: 'Rad 8 Coupé'},
  {make: 'BMW', model: 'X3'},
  {make: 'Toyota', model: 'Carina'},
  {make: 'Toyota', model: 'Paseo'},
  {make: 'Toyota', model: 'Supra'},
  {make: 'Lexus', model: 'RX'},
  {make: 'Mitsubishi', model: 'L200 Pick up Allrad'},
  {make: 'Mitsubishi', model: 'Pajero'},
  {make: 'Mitsubishi', model: 'Pajeto Pinin'},
  {make: 'Mazda', model: '323 F'},
  {make: 'Mazda', model: '6 Combi'},
  {make: 'Škoda', model: 'Octavia'},
  {make: 'Opel', model: 'Corsa'},
  {make: 'Dacia', model: 'Sandero'},
  {make: 'Dacia', model: 'Solenza'},
  {make: 'Peugeot', model: '2008'},
  {make: 'Peugeot', model: '205'},
  {make: 'Peugeot', model: '206 SW'},
  {make: 'Renault', model: 'Express'},
  {make: 'Seat', model: 'Cordoba'},
  {make: 'BMW', model: 'X1'},
  {make: 'BMW', model: 'Rad 3 Coupé'},
  {make: 'BMW', model: 'Rad 5'},
  {make: 'Toyota', model: 'Hiace Van'},
  {make: 'Toyota', model: 'MR2'},
  {make: 'Mitsubishi', model: 'Fuso canter'},
  {make: 'Mitsubishi', model: 'L200 Pick up'},
  {make: 'Mitsubishi', model: 'Lancer'},
  {make: 'Mazda', model: '5'},
  {make: 'Honda', model: 'Integra'},
  {make: 'Chevrolet', model: 'Alero'},
  {make: 'Chevrolet', model: 'Cruze'},
  {make: 'Alfa Romeo', model: '145'},
  {make: 'Alfa Romeo', model: 'MiTo'},
  {make: 'Renault', model: 'Talisman Grandtour'},
  {make: 'Renault', model: 'Thalia'},
  {make: 'Renault', model: 'Twingo'},
  {make: 'Seat', model: 'Mii'},
  {make: 'BMW', model: 'Z3 Roadster'},
  {make: 'Toyota', model: 'FJ Cruiser'},
  {make: 'Toyota', model: 'Hiace'},
  {make: 'Lexus', model: 'GS 300'},
  {make: 'Mazda', model: 'BT'},
  {make: 'Honda', model: 'Accord Tourer'},
  {make: 'Honda', model: 'Civic Coupé'},
  {make: 'Porsche', model: '997'},
  {make: 'Chevrolet', model: 'Spark'},
  {make: 'Škoda', model: 'Rapid Spaceback'},
  {make: 'Alfa Romeo', model: '156 Sportwagon'},
  {make: 'Alfa Romeo', model: 'Brera'},
  {make: 'Citroën', model: 'C-Crosser'},
  {make: 'Citroën', model: 'C1'},
  {make: 'Citroën', model: 'C3'},
  {make: 'Citroën', model: 'C5'},
  {make: 'Citroën', model: 'Jumpy'},
  {make: 'Citroën', model: 'Nemo'},
  {make: 'Peugeot', model: '407 SW'},
  {make: 'Renault', model: 'Mégane Grandtour'},
  {make: 'Renault', model: 'Scénic'},
  {make: 'Renault', model: 'Wind'},
  {make: 'Seat', model: 'Altea XL'},
  {make: 'Seat', model: 'Leon'},
  {make: 'BMW', model: 'Rad 1'},
  {make: 'BMW', model: 'Z3 Coupé'},
  {make: 'Mitsubishi', model: 'Galant'},
  {make: 'Mazda', model: '626 Combi'},
  {make: 'Mazda', model: 'CX-5'},
  {make: 'Mazda', model: 'CX-7'},
  {make: 'Mazda', model: 'CX-9'},
  {make: 'Honda', model: 'Accord Coupé'},
  {make: 'Honda', model: 'City'},
  {make: 'Porsche', model: 'Cayenne'},
  {make: 'Škoda', model: 'Octavia Combi'},
  {make: 'Škoda', model: 'Superb'},
  {make: 'Alfa Romeo', model: '164'},
  {make: 'Opel', model: 'Ampera'},
  {make: 'Opel', model: 'Astra'},
  {make: 'Opel', model: 'Omega'},
  {make: 'Citroën', model: 'C6'},
  {make: 'Citroën', model: 'DS3'},
  {make: 'Peugeot', model: '106'},
  {make: 'Peugeot', model: '108'},
  {make: 'Peugeot', model: '206 CC'},
  {make: 'Peugeot', model: '406'},
  {make: 'Renault', model: 'Grand Espace'},
  {make: 'Seat', model: 'Exeo'},
  {make: 'Seat', model: 'Inca'},
  {make: 'Tesla', model: 'Model 3'},
  {make: 'Tesla', model: 'Model S'},
  {make: 'Tesla', model: 'Model X'},
  {make: 'Tesla', model: 'Model Y'},
  {make: 'Fisker', model: 'Karma'},
  {make: 'Fisker', model: 'Revero'},
  {make: 'Fisker', model: 'Sunset'},
  {make: 'Fisker', model: 'Surf'},
  {make: 'Fisker', model: 'Atlantic'},
  {make: 'Fisker', model: 'Ocean'},
  {make: 'Lambo', model: 'Aventador'},
  {make: 'Lambo', model: 'Huracán'},
  {make: 'Lambo', model: 'Urus'},
  {make: 'Lambo', model: 'Countach'},
  {make: 'Lambo', model: 'Gallardo'},
  {make: 'Lambo', model: 'Diablo'},
  {make: 'Lambo', model: 'Miura'},
  {make: 'Lambo', model: 'Murciélago'},
  {make: 'Ferrari', model: '458'},
  {make: 'Ferrari', model: '458 Spider'},
  {make: 'Ferrari', model: 'F40'},
  {make: 'Ferrari', model: 'Testatossa'},
  {make: 'Ferrari', model: '599'},
  {make: 'Ferrari', model: '250'},
  {make: 'Ferrari', model: '360'},
  {make: 'Ferrari', model: 'F430'},
  {make: 'Ferrari', model: 'F12'},
  {make: 'Ferrari', model: 'Enzo'},
  {make: 'Ferrari', model: 'Mondial'},
  {make: 'Ferrari', model: '308 GTB/GTS'},
  {make: 'Ferrari', model: '166'},
  {make: 'Ferrari', model: 'Berlinetta'},
  {make: 'Ferrari', model: 'Boxster'},
  {make: 'Ferrari', model: 'Luso'},
  {make: 'Ferrari', model: 'Luso T'},
  {make: 'Ferrari', model: 'Roma'},
  {make: 'Ferrari', model: 'Portofino'},
  {make: 'Ferrari', model: 'California'},
  {make: 'Ferrari', model: 'LaFerrari'},
  {make: 'Ferrari', model: '812'},
  {make: 'Ferrari', model: 'SF90'},
  {make: 'Ferrari', model: 'Stradale'},
  {make: 'Ferrari', model: 'SF90 Spider'},
  {make: 'Ferrari', model: '296 GTB'},
  {make: 'Ferrari', model: 'F8 Spider'},
  {make: 'Bugatti', model: 'EB110 GT'},
  {make: 'Bugatti', model: 'EB118 Coupe'},
  {make: 'Bugatti', model: 'Veyron'},
  {make: 'Bugatti', model: 'Veyron EB16.4'},
  {make: 'Bugatti', model: 'Veyron Grand Sport'},
  {make: 'Bugatti', model: 'Veyron Super Sport'},
  {make: 'Bugatti', model: 'Veyron Grand Sport Vitesse'},
  {make: 'Bugatti', model: 'Chiron'},
  {make: 'Acura', model: 'TLX'},
  {make: 'Acura', model: 'RL'},
  {make: 'Acura', model: 'TL'},
  {make: 'Acura', model: 'RDX'},
  {make: 'Acura', model: 'ZDX'},
  {make: 'Acura', model: 'Legend'},
  {make: 'Acura', model: 'ILX'},
  {make: 'Acura', model: 'MDX'},
  {make: 'Acura', model: 'CL'},
  {make: 'Acura', model: 'RLX'},
  {make: 'Acura', model: 'CSX'},
  {make: 'Acura', model: 'EL'},
  {make: 'Acura', model: 'NSX'},
  {make: 'Acura', model: 'SLX'},
  {make: 'Acura', model: 'TSX'},
  {make: 'Acura', model: 'Vigor'},
  {make: 'Acura', model: 'Precision'},
  {make: 'Acura', model: 'GSX'},
  {make: 'Acura', model: 'Stealth'},
  {make: 'Acura', model: '2+1'},
  {make: 'Acura', model: 'Advanced Sports Car'},
  {make: 'Acura', model: 'Advanced Sedan'},
  {make: 'Acura', model: 'Integra'},
  {make: 'Lotus', model: 'Elise'},
  {make: 'Lotus', model: 'Evora'},
  {make: 'Lotus', model: 'Evija'},
  {make: 'Lotus', model: 'Emria'},
  {make: 'Lotus', model: 'Eletre'},
  {make: 'Lotus', model: 'Mark I'},
  {make: 'Lotus', model: 'Mark II'},
  {make: 'Lotus', model: 'Mark III'},
  {make: 'Lotus', model: 'Mark IV'},
  {make: 'Lotus', model: 'Mark V'},
  {make: 'Lotus', model: 'Mark VI'},
  {make: 'Lotus', model: 'Seven'},
  {make: 'Lotus', model: 'Mark VIII'},
  {make: 'Lotus', model: 'Mark IX'},
  {make: 'Lotus', model: 'Mark X'},
  {make: 'Lotus', model: 'Eleven'},
  {make: 'Lotus', model: '12', order: 'first'},
  {make: 'Lotus', model: '135', order: 'last'},
  {make: 'Lotus', model: '26R'},
  {make: 'Lotus', model: '56B'},
  {make: 'Lotus', model: 'Elite'},
  {make: 'Lotus', model: 'Elan'},
  {make: 'Lotus', model: 'Europa'},
  {make: 'Lotus', model: 'Eclat'},
  {make: 'Lotus', model: 'Espirit'},
  {make: 'Lotus', model: '340R'},
  {make: 'Lotus', model: 'Elise GT1'},
  {make: 'Buick', model: 'Buick'},
  {make: 'General Motors', model: 'General Motors'},
  {make: 'Lexus', model: 'Lexus'},
  {make: 'RX 350', model: 'RX 350'}
];
