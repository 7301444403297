import {useRef} from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import {useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import arrowForwardFill from '@iconify/icons-eva/arrow-forward-fill';
// material
import {useTheme, styled} from '@mui/material/styles';
import {Box, Button, Card, CardContent, Paper, Stack, Typography} from '@mui/material';
// hooks
import useAuth from 'src/hooks/useAuth';
// utils
import {formatPhoneNumber} from 'src/utils/stringFormat';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import {CarouselControlsArrowsBasic2} from 'src/components/carousel/controls';
import Label from 'src/components/Label';
// constants
import {getOrgStatusLabel} from 'src/constants/dropdown';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
  overflow: 'hidden',
  position: 'relative',
  '&:before, &:after': {
    top: 0,
    left: 0,
    zIndex: 8,
    width: 48,
    content: "''",
    height: '100%',
    display: 'none',
    position: 'absolute',
    [theme.breakpoints.up(480)]: {
      display: 'block'
    }
  },
  '&:after': {
    right: 0,
    left: 'auto',
    transform: 'scaleX(-1)'
  }
}));

// ----------------------------------------------------------------------
function RenderStatus(key) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <Label
      variant={isLight ? 'ghost' : 'filled'}
      color={key === 'active' ? 'success' : key === 'pending' ? 'warning' : 'error'}
      sx={{textTransform: 'capitalize', mx: 'auto'}}
    >
      {getOrgStatusLabel(key)}
    </Label>
  );
}

CarouselItem.propTypes = {
  item: PropTypes.object
};

function CarouselItem({item}) {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {state} = useLocation();
  const {members} = useSelector((state) => state.familyGroup);

  const memberSelected = members.filter((m) => m.show);
  const isAdvisor = user.role === 'Advisor';
  const {id, name, website, primaryPhone, status, user: owner} = item;

  const handleClick = () => {
    if (isAdvisor || memberSelected?.length > 0) {
      const route = `${PATH_DASHBOARD.root}/org/entity/${id}/${owner}`;
      navigate(route, {state});
    } else {
      const route = `${PATH_DASHBOARD.root}/org/entity/${id}`;
      navigate(route, {state});
    }
  };

  return (
    <Paper
      sx={{
        m: 1,
        borderRadius: 2
      }}
    >
      <Card>
        <CardContent>
          <Stack direction="row" sx={{alignItems: 'center', justifyContent: 'space-between', mb: 1}}>
            <Typography variant="h4">{name}</Typography>
            <Typography variant="body1">{RenderStatus(status)}</Typography>
          </Stack>
          <Typography variant="body1">{website}</Typography>
          <Typography variant="body1">{formatPhoneNumber(primaryPhone)}</Typography>
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleClick}
            sx={{
              mt: 2,
              opacity: 0.72,
              alignItems: 'center',
              display: 'inline-flex',
              transition: (theme) => theme.transitions.create('opacity'),
              '&:hover': {opacity: 1}
            }}
          >
            View Details <Box component={Icon} icon={arrowForwardFill} sx={{width: 16, height: 16, ml: 1}} />
          </Button>
        </CardContent>
      </Card>
    </Paper>
  );
}

export default function Gallery({data}) {
  const carouselRef = useRef();
  const theme = useTheme();

  const settings = {
    slidesToShow: 3,
    infinite: data.length > 3,
    centerMode: true,
    centerPadding: '60px',
    rtl: Boolean(theme.direction === 'rtl'),
    responsive: [
      {
        breakpoint: 1024,
        settings: {slidesToShow: 2, infinite: data.length > 2}
      },
      {
        breakpoint: 480,
        settings: {slidesToShow: 1, infinite: data.length > 1, centerPadding: '0'}
      }
    ]
  };

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  return (
    <RootStyle>
      <Slider ref={carouselRef} {...settings}>
        {data.map((item) => (
          <CarouselItem key={item.id} item={item} />
        ))}
      </Slider>
      <CarouselControlsArrowsBasic2 onNext={handleNext} onPrevious={handlePrevious} />
    </RootStyle>
  );
}
