import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  data: []
};

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // GET Files
    getSearchResult(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getSearchData(query) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/common/globalSearch?search=${query}`);
      dispatch(slice.actions.getSearchResult(response.data));

      return response.data;
    } catch (err) {
      dispatch(slice.actions.stopLoading());
      return err;
    }
  };
}
