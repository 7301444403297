import {useNavigate, useLocation} from 'react-router-dom';
import {useSnackbar} from 'notistack';
// mui
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
// hooks
import useAuth from 'src/hooks/useAuth';
// util
import {fCurrency} from 'src/utils/formatNumber';
import {fDate} from 'src/utils/formatTime';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import UserAvatar from 'src/components/UserAvatar';
import StatusLabel from 'src/components/_dashboard/insurance/common/StatusLabel';
// constants
import {getInRESubtypeLabel} from 'src/constants/dropdown';
import {getInsuranceLabel} from 'src/constants/tab';
import useColumnSettings from 'src/hooks/useColumnSettings';
import {useSelector} from 'react-redux';
// ----------------------------------------------------------------------

export default function DataGridCustom({data}) {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {state} = useLocation();
  const {enqueueSnackbar} = useSnackbar();

  const rows = [...data];

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show);

  const userObj = {
    field: 'user',
    headerName: 'User',
    width: 80,
    renderCell: (params) => {
      return <UserAvatar userId={params['row'].user} />;
    }
  };

  const columns = [
    {
      field: 'policyNumber',
      headerName: 'Policy Number',
      width: 200
    },
    {
      field: 'nickName',
      headerName: 'Policy Name',
      width: 180
    },
    {
      field: 'effDate',
      headerName: 'Effective Date',
      width: 180,
      renderCell: (params) => {
        const data = params.row.effDate;
        return fDate(data);
      }
    },
    {
      field: 'expDate',
      headerName: 'Expiration Date',
      width: 180,
      renderCell: (params) => {
        const data = params.row.expDate;
        return fDate(data);
      }
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 140,
      renderCell: (params) => {
        const type = params.row.type;
        return getInsuranceLabel(type);
      }
    },
    {
      field: 'policyType',
      headerName: 'Sub Type',
      width: 140,
      renderCell: (params) => {
        const policyType = params.row.policyType;
        return getInRESubtypeLabel(policyType);
      }
    },
    {
      field: 'premium',
      type: 'number',
      headerName: 'Premium',
      width: 160,
      renderCell: (params) => {
        const price = params.row.premium;
        return fCurrency(price);
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 160,
      renderCell: (params) => {
        const status = params.row.status;
        return <StatusLabel status={status} />;
      }
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 150
    }
  ];

  if (user.role === 'Advisor') columns.splice(0, 0, userObj);

  const {useColumnState} = useColumnSettings();
  const [columnSettings, setColumnSettings] = useColumnState('divestedInsurance');

  const handleRowClick = (params) => {
    const selectedID = params.row.id;
    const type = params.row.type;

    if (!type || !selectedID) {
      enqueueSnackbar('Policy type is missing.', {variant: 'error'});
      return;
    }

    if (user.role === 'Advisor' || memberSelected.length > 0) {
      const route = `${PATH_DASHBOARD.root}/insurance/${type}/${selectedID}/${params.row.user}`;
      navigate(route, {state});
    } else {
      const route = `${PATH_DASHBOARD.root}/insurance/${type}/${selectedID}`;
      navigate(route, {state});
    }
  };

  return (
    <DataGrid
      autoHeight
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      rows={rows}
      columns={columns}
      columnVisibilityModel={columnSettings}
      onColumnVisibilityModelChange={setColumnSettings}
      onRowClick={handleRowClick}
      pagination
      pageSize={5}
      rowsPerPageOptions={[5]}
      components={{
        Toolbar: GridToolbar
      }}
    />
  );
}
