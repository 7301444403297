import React from 'react';
import PropTypes from 'prop-types';
// mui
import {Box, Card, CardHeader, CardContent, Grid, Stack} from '@mui/material';
// components
import EmptyContent from 'src/components/EmptyContent';
import LCChart from '../common/LCChart';
import DoubleBarChart from './common/DoubleBarChart';
import BoardItem from './common/BoardItem';
import Gallery from './components/Claims/Gallery';
import Table from './components/Claims/Table';
import ComingSoonCover from 'src/components/ComingSoonCover';
// constants
import {INITIAL_CHARTS_DATA} from 'src/constants/claims';
import {IN_TYPE, CLAIM_TYPE} from 'src/constants/dropdown';
// utils
import {showOnlyActiveLabels} from 'src/utils/showOnlyActiveLabels';
import FullScreenChart from '../common/FullScreenChart';
import {isNullOrEmpty} from 'src/utils/checkEmpty';
// ----------------------------------------------------------------------

Claims.propTypes = {
  mode: PropTypes.string,
  view: PropTypes.string,
  data: PropTypes.array,
  chartData: PropTypes.object
};

export default function Claims({mode, view = 'show', data = [], chartData = INITIAL_CHARTS_DATA}) {
  if (!Object.keys(chartData).length) chartData = INITIAL_CHARTS_DATA;
  const categories = chartData.claimsInExperiencePeriod?.map((exp) => exp.year) || [];
  const claimData = ['count', 'value'].map((item) => ({
    name: item,
    data: chartData.claimsInExperiencePeriod?.map((subItem) => subItem[item] || 0) || []
  }));
  const claimsByTypeData = CLAIM_TYPE.map((item) => chartData.claimsByType[item.value] || 0);
  const claimsByClassData = IN_TYPE.map((item) => chartData.claimsByClass[item.value] || 0);
  const claimByTypeLabels = CLAIM_TYPE.map((item) => item.label);
  const claimByClassDataLabels = IN_TYPE.map((item) => item.label);
  const customChartData = [
    showOnlyActiveLabels(claimByTypeLabels, claimsByTypeData),
    showOnlyActiveLabels(claimByClassDataLabels, claimsByClassData)
  ];

  const renderData = () =>
    mode === 'gallery' ? (
      <Gallery data={data} />
    ) : (
      <Box sx={{minHeight: 360}}>
        <Table data={data} />
      </Box>
    );

  return (
    <Stack spacing={2}>
      {view === 'show' && (
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{m: 1, height: 'calc(100% - 12px)', display: 'flex', flexDirection: 'column'}}>
                <CardHeader title="Claims In Experience Period" />
                <Box sx={{flex: 1}}>
                  {isNullOrEmpty(claimData) && <ComingSoonCover noData={true} style={{marginTop: 35}} />}
                  <Box height={'100%'}>
                    <FullScreenChart>
                      <DoubleBarChart label="annual" categories={categories} data={claimData} />
                    </FullScreenChart>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Stack sx={{m: 1, height: 'calc(100% - 12px)', justifyContent: 'space-between'}}>
                <BoardItem label="Total Premium" data={chartData.totalPremium} currency />
                <BoardItem label="Total claims" data={chartData.totalClaims} />
                <BoardItem label="Total Loss Retained" data={chartData.totalLossRetained} currency />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Stack sx={{m: 1, height: 'calc(100% - 12px)', justifyContent: 'space-between'}}>
                <BoardItem label="Aggregate claims paid" data={chartData.aggregateClaimsPaid} currency />
                <BoardItem label="Lifetime Loss Ratio" data={chartData.lifetimeLossRatio} digit />
                <BoardItem hidden />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{m: 1, height: 'calc(100% - 12px)', display: 'flex', flexDirection: 'column'}}>
                <CardHeader title="Claims by type" />
                <Box sx={{flex: 1}}>
                  {isNullOrEmpty(customChartData[0].customDataChart) && (
                    <ComingSoonCover noData={true} style={{marginTop: 35}} />
                  )}
                  <Box>
                    <LCChart label={customChartData[0].customLabelsChart} data={customChartData[0].customDataChart} />
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{m: 1, height: 'calc(100% - 12px)', display: 'flex', flexDirection: 'column'}}>
                <CardHeader title="Claims by class" />
                <Box sx={{flex: 1}}>
                  {isNullOrEmpty(customChartData[1].customDataChart) && (
                    <ComingSoonCover noData={true} style={{marginTop: 35}} />
                  )}
                  <Box>
                    <LCChart label={customChartData[1].customLabelsChart} data={customChartData[1].customDataChart} />
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Stack>
      )}
      <Card>
        <CardContent>{data.length > 0 ? renderData() : <EmptyContent />}</CardContent>
      </Card>
    </Stack>
  );
}
