import {useMemo} from 'react';
// mui
import {Autocomplete, Checkbox, TextField} from '@mui/material';
// hooks
import useAuth from 'src/hooks/useAuth';
// redux
import {useSelector} from 'src/redux/store';
// utils
import {getFullName} from 'src/utils/createAvatar';
// ----------------------------------------------------------------------

const SingleContactSelect = ({value, label, handleChange}) => {
  const {user} = useAuth();
  const {isLoading: cLoading, contacts} = useSelector((state) => state.contact);

  const contactList = useMemo(
    () =>
      [
        {
          id: 'self',
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email
        }
      ].concat(contacts),
    [contacts, user]
  );

  return (
    <Autocomplete
      fullWidth
      disableClearable
      loading={cLoading}
      options={contactList}
      getOptionLabel={(option) => getFullName(option, true)}
      value={contactList.find((item) => item.id === value)}
      onChange={(e, value) => handleChange(value.id)}
      renderOption={(props, option, {selected}) => {
        return (
          <li {...props} key={option.id}>
            <Checkbox checked={selected} />
            {getFullName(option, true)}
          </li>
        );
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default SingleContactSelect;
