import React, {useState} from 'react';
import PropTypes from 'prop-types';
// mui
import {Box, Card, CardHeader, CardContent, FormControlLabel, Grid, Stack, Switch} from '@mui/material';
//utils
import {showOnlyActiveLabels} from 'src/utils/showOnlyActiveLabels';
import {isNullOrEmpty} from 'src/utils/checkEmpty';
// components
import EmptyContent from 'src/components/EmptyContent';
import LCChart from '../common/LCChart';
import Gallery from './components/Collection/Gallery';
import Table from './components/Collection/Table';
import ComingSoonCover from 'src/components/ComingSoonCover';
// ----------------------------------------------------------------------

Collection.propTypes = {
  mode: PropTypes.string,
  view: PropTypes.string,
  data: PropTypes.array
};

const labels = [
  'Jewelry',
  'Fine Art',
  'Wine & Spirits',
  'Musical Instruments',
  'Coins & Collectibles',
  'Furs',
  'Silver & Porcelain',
  'Other'
];

export default function Collection({mode, view = 'show', data = [], chartData = {}}) {
  const [viewAll, setViewAll] = useState(false);

  const getValidData = () => {
    return viewAll ? data : data.filter((item) => item.assetStatus !== 'divested');
  };

  const chart1Data = [
    chartData.totalOfCollections?.jewelrySize || 0,
    chartData.totalOfCollections?.fineArtSize || 0,
    chartData.totalOfCollections?.wineSpiritsSize || 0,
    chartData.totalOfCollections?.musicalInstrumentsSize || 0,
    chartData.totalOfCollections?.coinsCollectiblesSize || 0,
    chartData.totalOfCollections?.fursSize || 0,
    chartData.totalOfCollections?.silverPorcelainSize || 0,
    chartData.totalOfCollections?.otherSize || 0
  ];
  const chart2Data = [
    chartData.totalValueByClass?.jewelry || 0,
    chartData.totalValueByClass?.fineArt || 0,
    chartData.totalValueByClass?.wine_Spirits || 0,
    chartData.totalValueByClass?.musicalInstruments || 0,
    chartData.totalValueByClass?.coins_Collectibles || 0,
    chartData.totalValueByClass?.furs || 0,
    chartData.totalValueByClass?.silver_Porcelain || 0,
    chartData.totalValueByClass?.other || 0
  ];
  const customChartData = [showOnlyActiveLabels(labels, chart1Data), showOnlyActiveLabels(labels, chart2Data)];

  const renderData = () =>
    mode === 'gallery' ? (
      <Gallery data={data} />
    ) : (
      <Box sx={{minHeight: 360}}>
        <FormControlLabel
          sx={{position: 'absolute', top: '46px', right: '46px', zIndex: 999}}
          control={<Switch size="small" checked={viewAll} onChange={(event) => setViewAll(event.target.checked)} />}
          label={viewAll?'View Active':'View All'}
          labelPlacement="start"
        />
        <Table data={getValidData()} />
      </Box>
    );

  return (
    <Stack spacing={2}>
      {view === 'show' && (
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{m: 1, display: 'flex', flexDirection: 'column'}}>
              {isNullOrEmpty(customChartData?.[0]?.customDataChart) && (
                <ComingSoonCover noData={true} style={{marginTop: 25}} />
              )}

              <CardHeader title="Total Collection Size" />
              <Box sx={{flex: 1}}>
                <LCChart
                  type="quantity"
                  label={customChartData[0].customLabelsChart}
                  data={customChartData[0].customDataChart}
                />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{m: 1, display: 'flex', flexDirection: 'column'}}>
              {isNullOrEmpty(customChartData?.[1]?.customDataChart) && (
                <ComingSoonCover noData={true} style={{marginTop: 25}} />
              )}

              <CardHeader title="Total Collection Value" />
              <Box sx={{flex: 1}}>
                <LCChart label={customChartData[1].customLabelsChart} data={customChartData[1].customDataChart} />
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
      <Card>
        <CardContent>{data.length > 0 ? renderData() : <EmptyContent />}</CardContent>
      </Card>
    </Stack>
  );
}
