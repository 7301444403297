import PropTypes from 'prop-types';
import {useNavigate, useLocation} from 'react-router-dom';
// material
import {useTheme} from '@mui/material/styles';
import {Box, Rating, Typography} from '@mui/material';
import {DataGrid, GridToolbar, getGridNumericColumnOperators} from '@mui/x-data-grid';
// hooks
import useAuth from 'src/hooks/useAuth';
import useColumnSettings from 'src/hooks/useColumnSettings';
// util
import {fCurrency} from 'src/utils/formatNumber';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import Label from 'src/components/Label';
import UserAvatar from 'src/components/UserAvatar';
// constants
import {getOrgStatusLabel, getProfLabel} from 'src/constants/dropdown';
import {useSelector} from 'react-redux';
// ----------------------------------------------------------------------

function RenderStatus(key) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  return (
    <Label
      variant={isLight ? 'ghost' : 'filled'}
      color={key === 'active' ? 'success' : key === 'pending' ? 'warning' : 'error'}
      sx={{textTransform: 'capitalize', mx: 'auto'}}
    >
      {getOrgStatusLabel(key)}
    </Label>
  );
}

RatingInputValue.propTypes = {
  applyValue: PropTypes.func.isRequired,
  item: PropTypes.shape({
    columnField: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    operatorValue: PropTypes.string,
    value: PropTypes.any
  }).isRequired
};

function RatingInputValue({item, applyValue}) {
  return (
    <Box sx={{p: 1, height: 1, alignItems: 'flex-end', display: 'flex'}}>
      <Rating
        size="small"
        precision={0.5}
        placeholder="Filter value"
        value={Number(item.value)}
        onChange={(event, newValue) => {
          applyValue({...item, value: newValue});
        }}
      />
    </Box>
  );
}

// ----------------------------------------------------------------------

export default function DataGridCustom({data}) {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {state} = useLocation();
  const rows = [...data];

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show);

  const userObj = {
    field: 'user',
    headerName: 'User',
    width: 80,
    renderCell: (params) => {
      return <UserAvatar userId={params['row'].user} />;
    }
  };

  let columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 120
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => {
        const type = params.row.status;
        return RenderStatus(type);
      }
    },
    {
      field: 'website',
      headerName: 'Website',
      width: 160,
      renderCell: (params) => {
        const website = params.getValue(params.id, 'website');
        return (
          <Typography variant="body2" sx={{textDecoration: 'underline'}} noWrap>
            {website}
          </Typography>
        );
      }
    },
    {
      field: 'compensation',
      type: 'number',
      headerName: 'Compensation',
      width: 140,
      renderCell: (params) => {
        const price = params.row.compensation;
        return fCurrency(price);
      }
    },
    {
      field: 'indemnity',
      headerName: 'Indemnity',
      width: 140,
      renderCell: (params) => {
        return params.row.indemnity ? 'YES' : 'NO';
      }
    },
    {
      field: 'indemnityReviewed',
      headerName: 'Indemnity Reviewed',
      width: 160,
      renderCell: (params) => {
        return params.row.indemnityReviewed ? 'YES' : 'NO';
      }
    },
    {
      field: 'profitStatus',
      type: 'boolean',
      headerName: 'Profit Status',
      width: 160,
      renderCell: (params) => {
        const key = params.getValue(params.id, 'profitStatus');

        return getProfLabel(key);
      }
    },
    {
      field: 'note',
      headerName: 'Note'
    }
  ];

  if (user.role === 'Advisor') columns.splice(0, 0, userObj);

  const ratingColumn = columns.find((column) => column.field === 'indemnityReviewed');
  const ratingColIndex = columns.findIndex((col) => col.field === 'indemnityReviewed');

  const ratingFilterOperators = getGridNumericColumnOperators().map((operator) => ({
    ...operator,
    InputComponent: RatingInputValue
  }));

  columns[ratingColIndex] = {
    ...ratingColumn,
    filterOperators: ratingFilterOperators
  };

  const {useColumnState} = useColumnSettings();
  const [columnSettings, setColumnSettings] = useColumnState('boardsOrg');

  const handleRowClick = (params) => {
    const selectedID = params.row.id;

    if (user.role === 'Advisor' || memberSelected.length > 0) {
      const route = `${PATH_DASHBOARD.root}/org/board/${selectedID}/${params.row.user}`;
      navigate(route, {state});
    } else {
      const route = `${PATH_DASHBOARD.root}/org/board/${selectedID}`;
      navigate(route, {state});
    }
  };

  return (
    <DataGrid
      autoHeight
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      rows={rows}
      columns={columns}
      columnVisibilityModel={columnSettings}
      onColumnVisibilityModelChange={setColumnSettings}
      onRowClick={handleRowClick}
      pagination
      pageSize={5}
      rowsPerPageOptions={[5]}
      components={{
        Toolbar: GridToolbar
      }}
    />
  );
}
