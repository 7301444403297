import React from 'react';
import PropTypes from 'prop-types';
import {Box, Card, CardContent, Grid, Stack} from '@mui/material';

import EmptyContent from 'src/components/EmptyContent';
import LabelWidget from 'src/components/_dashboard/common/LabelWidget';
import Gallery from './components/Clubs/Gallery';
import Table from './components/Clubs/Table';

Clubs.propTypes = {
  mode: PropTypes.string,
  view: PropTypes.string,
  data: PropTypes.array
};

export default function Clubs({mode, view = 'show', data = [], chartData}) {
  const renderData = () =>
    mode === 'gallery' ? (
      <Gallery data={data} />
    ) : (
      <Box sx={{minHeight: 360}}>
        <Table data={data} />
      </Box>
    );

  return (
    <Stack spacing={2}>
      {view === 'show' && (
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{m: 1}} spacing={2}>
              <LabelWidget label="Total Number of Clubs" data={chartData.totalNumber} />
              <LabelWidget label="Total Annual Expense" data={chartData.totalAnnualExpenses} type="currency" />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{m: 1}} spacing={2}>
              <LabelWidget label="Total Number of States" data={chartData.totalNumberOfStates} />
              <LabelWidget label="Total Club Dues" data={chartData.totalClubDues} type="currency" />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{m: 1}} spacing={2}>
              <LabelWidget label="Total Bond Value" data={chartData.totalBondValue} type="currency" />
            </Stack>
          </Grid>
        </Grid>
      )}
      <Card>
        <CardContent>{data.length > 0 ? renderData() : <EmptyContent />}</CardContent>
      </Card>
    </Stack>
  );
}
