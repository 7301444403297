import {CLAIM_TYPE} from './dropdown';

export const LEDGER_TYPE = [
  {value: 'contact', label: 'Contact'},
  {value: 'asset', label: 'Asset'},
  {value: 'policy', label: 'Policy'},
  {value: 'entity', label: 'Organization'},
  {value: 'claim', label: 'Claim'},
  {value: 'event', label: 'Event'}
];

export const LEDGER_SUBTYPE = {
  asset: [
    {
      label: 'Real Estate',
      value: 'realEstate'
    },
    {
      label: 'Collections',
      value: 'collection'
    },
    {
      label: 'Automobiles',
      value: 'automobile'
    },
    {
      label: 'Watercraft',
      value: 'watercraft'
    },
    {
      label: 'Aircraft',
      value: 'aircraft'
    },
    {
      label: 'Equine',
      value: 'equine'
    }
  ],
  policy: [
    {
      label: 'Real Estate',
      value: 'realEstate'
    },
    {
      label: 'Collections',
      value: 'collection'
    },
    {
      label: 'Automobiles',
      value: 'automobile'
    },
    {
      label: 'Watercraft',
      value: 'watercraft'
    },
    {
      label: 'Aircraft',
      value: 'aircraft'
    },
    {
      label: 'Equine',
      value: 'equine'
    },
    {
      label: 'Umbrella',
      value: 'umbrella'
    },
    {
      label: 'Misc',
      value: 'misc'
    }
  ],
  entity: [
    {
      label: 'Boards',
      value: 'board'
    },
    {
      label: 'Entities',
      value: 'entity'
    },
    {
      label: 'Trusts & Estates',
      value: 'trust'
    },
    {
      label: 'Clubs',
      value: 'club'
    }
  ],
  claim: CLAIM_TYPE
};

export const valuesWithSubTypes = Object.keys(LEDGER_SUBTYPE);

export const getAssetLabel = (key) => {
  const item = LEDGER_SUBTYPE.asset.find((item) => item.value === key);

  return !item ? '' : item.label;
};

export const ledgerLabelHelpers = {
  asset: (item) => item.nickName,
  policy: (item) => item.nickName || item.policyName,
  entity: (item) => item.nickName || item.name,
  contact: (item) => (item.firstName || item.lastName ? `${item.firstName} ${item.lastName}` : item.email),
  claim: (item) => item.nickName || item.policyName || item.policyNumber,
  event: (item) => item.title || item.id
};
