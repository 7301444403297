// mui
import {Checkbox, Stack, Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {useTheme} from '@mui/material/styles';
// hooks
import useColumnSettings from 'src/hooks/useColumnSettings';
// utils
import {fCurrency} from 'src/utils/formatNumber';
// constants
import {getInsuranceLabel} from 'src/constants/tab';
// components
import Label from 'src/components/Label';
import StatusLabel from 'src/components/_dashboard/insurance/common/StatusLabel';
// ----------------------------------------------------------------------

const CanopyPolicyTable = ({data = [], handleSelect, selectedList = []}) => {
  function RenderStatus(label) {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    return (
      <Label variant={isLight ? 'ghost' : 'filled'} color={label === 'active' ? 'success' : 'error'}>
        {label}
      </Label>
    );
  }

  const common = [
    {
      field: 'policyNumber',
      headerName: 'Policy Number',
      width: 240,
      renderCell: (params) => {
        const isDuplicated = params.row.isDuplicated;
        const data = params.row.policyNumber;

        return (
          <Stack direction="row" spacing={1}>
            {isDuplicated && <StatusLabel status="duplicated" />}
            <Typography>{data}</Typography>
          </Stack>
        );
      }
    },
    {
      field: 'name',
      headerName: 'Policy Name',
      width: 220
    },
    {
      field: 'type',
      headerName: 'Policy Type',
      width: 180,
      renderCell: (params) => {
        const type = params.row.type;
        return getInsuranceLabel(type);
      }
    }
  ];
  const columns = handleSelect
    ? [
        {
          field: 'select',
          headerName: 'Select',
          width: 70,
          sortable: false,
          filterable: false,
          renderCell: (params) => {
            const isSelected = !!selectedList.find((item) => item.canopyPolicyId === params?.row?.policyId);

            return <Checkbox checked={isSelected} onClick={() => handleSelect(params?.row?.policyId, isSelected)} />;
          }
        },
        ...common,
        {
          field: 'effDate',
          headerName: 'Effective Date',
          width: 130,
          renderCell: (params) => {
            const effDate = params.row.effDate;
            return <Typography variant="body2">{new Date(effDate).toLocaleDateString()}</Typography>;
          }
        },
        {
          field: 'expDate',
          headerName: 'Expiration Date',
          width: 130,
          renderCell: (params) => {
            const expDate = params.row.expDate;
            return <Typography variant="body2">{new Date(expDate).toLocaleDateString()}</Typography>;
          }
        },
        {
          field: 'premium',
          headerName: 'Premium',
          width: 100,
          renderCell: (params) => fCurrency((params.row.premium || 0) / 100)
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 100,
          align: 'center',
          renderCell: (params) => RenderStatus(params.row.status)
        }
      ]
    : common;

  const {useColumnState} = useColumnSettings();
  const [columnSettings, setColumnSettings] = useColumnState('canopy-policy');

  const getRowId = (row) => {
    return `${row?.policyId}-${row?.name}`;
  };

  return (
    <DataGrid
      autoHeight
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      rows={data}
      getRowId={getRowId}
      columns={columns}
      columnVisibilityModel={columnSettings}
      onColumnVisibilityModelChange={setColumnSettings}
      pagination
      pageSize={10}
      rowsPerPageOptions={[10]}
    />
  );
};

export default CanopyPolicyTable;
