// mui
import {useTheme} from '@mui/material/styles';
import {Stack} from '@mui/material';
// component
import Label from 'src/components/Label';
// ----------------------------------------------------------------------

const StatusLabel = ({status, text = ''}) => {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <Stack sx={{justifyContent: 'center'}}>
      <Label
        variant={isLight ? 'ghost' : 'filled'}
        color={status === 'active' || status === 'open' ? 'success' : status === 'pending' ? 'warning' : 'error'}
        sx={{mx: 'auto'}}
      >
        {text || status}
      </Label>
    </Stack>
  );
};

export default StatusLabel;
