import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';

import {MFab} from 'src/components/@material-extend';
// ----------------------------------------------------------------------

Fab.propTypes = {
  caption: PropTypes.string,
  handleClick: PropTypes.func,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  right: PropTypes.number,
  color: PropTypes.string
};

function Fab({caption, handleClick, icon = 'add', disabled = false, color = 'primary', right = 40}) {
  const renderIcon = () => {
    switch (icon) {
      case 'add':
        return <AddIcon />;
      case 'edit':
        return <EditIcon />;
      case 'delete':
        return <DeleteIcon />;
      case 'refresh':
        return <RefreshIcon />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{position: 'fixed', bottom: 20, right, zIndex: 99}}>
      <MFab variant="extended" color={color} onClick={handleClick} disabled={disabled}>
        {renderIcon()}
        {caption}
      </MFab>
    </Box>
  );
}

export default Fab;
