import {useState} from 'react';
// mui
import {Box, Button, Card, CircularProgress, Stack, TextField, Typography} from '@mui/material';
// ----------------------------------------------------------------------

const CarrierSelector = ({loading, providers, handleSetProvider, handleClose}) => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <>
      {/* search */}
      <TextField
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search ..."
        size="small"
        sx={{mx: 2, mb: 1, boxShadow: 1, borderRadius: 1}}
        variant="outlined"
        disabled={loading}
      />

      {/* providers list */}
      <Stack
        sx={{
          height: 370,
          width: 370,
          overflow: 'auto',
          px: 2,
          pb: 2
        }}
      >
        {!!providers?.length ? (
          providers
            ?.filter?.((p) => (!!searchTerm ? p?.name?.toLowerCase?.()?.includes?.(searchTerm?.toLowerCase?.()) : true))
            ?.map?.((p, index) => (
              <Card
                key={`${index} ${p?.carrier_id}`}
                style={{
                  borderRadius: 6,
                  marginTop: 6,
                  cursor: 'pointer',
                  padding: '0px',
                  minHeight: 106
                }}
                onClick={() => handleSetProvider(p)}
              >
                <Stack direction="row" sx={{alignItems: 'center', px: 3, py: 1}}>
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      backgroundColor: p.color,
                      borderRadius: '50%'
                    }}
                  />
                  <Stack
                    sx={{
                      p: 1,
                      ml: 2,
                      minHeight: 90
                    }}
                    justifyContent={'center'}
                  >
                    <Typography variant="subtitle1" style={{textTransform: 'capitalize'}}>
                      {p?.name}
                    </Typography>
                    <Typography variant="body2" sx={{opacity: 0.6}}>
                      {p?.homepageUrl}
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
            ))
        ) : (
          <CircularProgress sx={{m: 'auto'}} />
        )}
      </Stack>
      <Stack direction="row" sx={{my: 2, justifyContent: 'flex-end', px: 4}}>
        <Button onClick={handleClose}>Close</Button>
      </Stack>
    </>
  );
};

export default CarrierSelector;
