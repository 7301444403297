// material
import {Card, Typography, Box} from '@mui/material';
import {useTheme} from '@mui/material/styles';
// utils
import {fShortenNumber, fCurrency} from 'src/utils/formatNumber';
// assets
import {BookingIllustration} from 'src/assets';

// ----------------------------------------------------------------------

export default function LabelWidget({data, label, type, show = false, sx}) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 2, 2, 3),
        ...sx
      }}
    >
      <div>
        <Typography variant="h3">{type === 'currency' ? fCurrency(data) : fShortenNumber(data)}</Typography>
        <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
          {label}
        </Typography>
      </div>
      {show && (
        <Box
          sx={{
            width: 120,
            height: 120,
            lineHeight: 0,
            borderRadius: '50%',
            bgcolor: 'background.neutral'
          }}
        >
          <BookingIllustration />
        </Box>
      )}
    </Card>
  );
}
