import React, {useState} from 'react';
import PropTypes from 'prop-types';
// mui
import {Box, Card, CardContent, FormControlLabel, Grid, Stack, Switch} from '@mui/material';
// components
import EmptyContent from 'src/components/EmptyContent';
import LabelWidget from 'src/components/_dashboard/common/LabelWidget';
import Gallery from './components/Aircraft/Gallery';
import Table from './components/Aircraft/Table';
// ----------------------------------------------------------------------

Aircraft.propTypes = {
  mode: PropTypes.string,
  view: PropTypes.string,
  data: PropTypes.array
};

export default function Aircraft({mode, view = 'show', data = [], chartData}) {
  const [viewAll, setViewAll] = useState(false);

  const getValidData = () => {
    return viewAll ? data : data.filter((item) => item.assetStatus !== 'divested');
  };

  const renderData = () =>
    mode === 'gallery' ? (
      <Gallery data={data} />
    ) : (
      <Box sx={{minHeight: 360}}>
        <FormControlLabel
          sx={{position: 'absolute', top: '46px', right: '46px', zIndex: 999}}
          control={<Switch size="small" checked={viewAll} onChange={(event) => setViewAll(event.target.checked)} />}
          label={viewAll?'View Active':'View All'}
          labelPlacement="start"
        />
        <Table data={getValidData()} />
      </Box>
    );

  return (
    <Stack spacing={2}>
      {view === 'show' && (
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{m: 1}} spacing={1}>
              <LabelWidget label="Total number of Aircraft" data={chartData.totalNumberOfVehicles} />
              <LabelWidget label="Total States with Hangars" data={chartData.totalStatesWithHangars} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{m: 1}} spacing={1}>
              <LabelWidget
                label="Total market value of fleet"
                data={chartData.totalMarketValueOfFleet}
                type="currency"
              />
              <LabelWidget
                label="Total insurance value of fleet"
                data={chartData.totalInsuranceValueOfFleet}
                type="currency"
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{m: 1}} spacing={1}>
              <LabelWidget label="Total number of Pilots" data={chartData.totalNumberOfPilots} />
              <LabelWidget
                label="Total Cost of Aviation Insurance"
                data={chartData.totalCostOfAviationInsurance}
                type="currency"
              />
            </Stack>
          </Grid>
        </Grid>
      )}
      <Card>
        <CardContent>{data.length > 0 ? renderData() : <EmptyContent />}</CardContent>
      </Card>
    </Stack>
  );
}
