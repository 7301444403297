import {useRef} from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import {useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import arrowForwardFill from '@iconify/icons-eva/arrow-forward-fill';
// mui
import {alpha, useTheme, styled} from '@mui/material/styles';
import {Button, Box, Paper, Typography, CardContent} from '@mui/material';
// hooks
import useAuth from 'src/hooks/useAuth';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// utils
import {fCurrency} from 'src/utils/formatNumber';
// components
import {CarouselControlsArrowsBasic2} from 'src/components/carousel/controls';
import DUMMY from './asset/dummy.jpeg';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  overflow: 'hidden',
  position: 'relative'
});

const CarouselImgStyle = styled('img')(({theme}) => ({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  transition: theme.transitions.create('all')
}));

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.object
};

function CarouselItem({item}) {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {state} = useLocation();
  const {members} = useSelector((state) => state.familyGroup);

  const {id, image, purDate, soldDate, salePrice, originalBasis, type, user: owner} = item;
  const memberSelected = members.filter((m) => m.show);
  const isAdvisor = user.role === 'Advisor';

  const handleClick = () => {
    if (isAdvisor || memberSelected?.length > 0) {
      const route = `${PATH_DASHBOARD.root}/asset/${type}/${id}/${owner}`;
      navigate(route, {state});
    } else {
      const route = `${PATH_DASHBOARD.root}/asset/${type}/${id}`;
      navigate(route, {state});
    }
  };

  return (
    <Paper
      sx={{
        mx: 1,
        borderRadius: 2,
        overflow: 'hidden',
        paddingTop: '300px',
        position: 'relative',
        '&:hover img': {
          width: '120%',
          height: '120%'
        }
      }}
    >
      <CarouselImgStyle alt={id} src={image || DUMMY} />
      <CardContent
        sx={{
          bottom: 0,
          zIndex: 9,
          width: '100%',
          textAlign: 'left',
          position: 'absolute',
          color: 'common.white',
          backgroundImage: (theme) =>
            `linear-gradient(to top, ${theme.palette.grey[900]} 0%,${alpha(theme.palette.grey[900], 0)} 100%)`
        }}
      >
        <Typography variant="h4">Purchase: {purDate}</Typography>
        <Typography variant="h4">Sold: {soldDate}</Typography>
        <Typography variant="body1">SALE: {fCurrency(salePrice)}</Typography>
        <Typography variant="body1">ORIGINAL: {fCurrency(originalBasis)}</Typography>
        <Button
          color="inherit"
          variant="outlined"
          onClick={handleClick}
          sx={{
            mt: 2,
            opacity: 0.72,
            alignItems: 'center',
            display: 'inline-flex',
            transition: (theme) => theme.transitions.create('opacity'),
            '&:hover': {opacity: 1}
          }}
        >
          View Asset
          <Box component={Icon} icon={arrowForwardFill} sx={{width: 16, height: 16, ml: 1}} />
        </Button>
      </CardContent>
    </Paper>
  );
}

export default function Gallery({data}) {
  const carouselRef = useRef();
  const theme = useTheme();

  const settings = {
    slidesToShow: 3,
    infinite: data.length > 3,
    centerMode: true,
    centerPadding: '60px',
    rtl: Boolean(theme.direction === 'rtl'),
    responsive: [
      {
        breakpoint: 1024,
        settings: {slidesToShow: 2, infinite: data.length > 2}
      },
      {
        breakpoint: 480,
        settings: {slidesToShow: 1, infinite: data.length > 1, centerPadding: '0'}
      }
    ]
  };

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  return (
    <RootStyle>
      <Slider ref={carouselRef} {...settings}>
        {data.map((item) => (
          <CarouselItem key={item.id} item={item} />
        ))}
      </Slider>
      <CarouselControlsArrowsBasic2 onNext={handleNext} onPrevious={handlePrevious} />
    </RootStyle>
  );
}
