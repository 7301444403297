import PropTypes from 'prop-types';
// material
import {styled} from '@mui/material/styles';
import {Box, Switch, Typography} from '@mui/material';

// ----------------------------------------------------------------------

SwitchWithLabels.propTypes = {
  sx: PropTypes.object,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  value: PropTypes.bool,
  handleChange: PropTypes.func.isRequired
};

const RowStyle = styled('div')(({theme}) => ({
  display: 'inline-flex;',
  alignItems: 'center',
  textTransform: 'capitalize',
  justifyContent: 'space-between',
  color: theme.palette.common.white,
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(1)
  }
}));

export default function SwitchWithLabels({sx, label = '', onLabel = 'On', offLabel = 'Off', value, handleChange}) {
  return (
    <Box sx={{...sx}}>
      <Typography variant="body1">{label}</Typography>
      <RowStyle>
        <Typography variant="body2">{offLabel}</Typography>
        <Switch size="small" checked={value} onChange={(event) => handleChange(event.target.checked)} />
        <Typography variant="body2">{onLabel}</Typography>
      </RowStyle>
    </Box>
  );
}
