import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  initiated: false,
  shared: {
    realEstate: false,
    collection: false,
    automobile: false,
    watercraft: false,
    aircraft: false,
    equine: false,
    umbrella: false,
    misc: false,
    claim: false
  },
  error: null,
  chartData: {
    general: {},
    realEstate: {},
    collection: {},
    automobile: {},
    watercraft: {},
    aircraft: {},
    equine: {},
    umbrella: {},
    misc: {},
    claim: {}
  },
  insurance: {
    general: [],
    realEstate: [],
    collection: [],
    automobile: [],
    watercraft: [],
    aircraft: [],
    equine: [],
    umbrella: [],
    misc: [],
    claim: []
  }
};

const slice = createSlice({
  name: 'insurance',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET INSURANCE
    setInsurance(state, action) {
      state.isLoading = false;
      state.initiated = true;
      let newInsurance = {};
      let chartData = {};
      for (const [key, value] of Object.entries(action.payload)) {
        newInsurance[key] = [...value.data, ...state.insurance[key].filter((item) => item.shared)];
        chartData[key] = value.topChartData;
      }

      state.insurance = newInsurance;
      state.chartData = chartData;
    },

    // GET Real Estate INSURANCE
    setREInsurance(state, action) {
      state.isLoading = false;
      state.chartData.realEstate = action.payload.topChartData;
      state.insurance.realEstate = [
        ...action.payload.data,
        ...state.insurance.realEstate.filter((item) => item.shared)
      ];
    },

    // GET Shared Real Estate INSURANCE
    setSharedREInsurance(state, action) {
      state.shared.realEstate = true;
      state.insurance.realEstate = [...state.insurance.realEstate.filter((item) => !item.shared), ...action.payload];
    },

    // GET Collection INSURANCE
    setCOInsurance(state, action) {
      state.isLoading = false;
      state.chartData.collection = action.payload.topChartData;
      state.insurance.collection = [
        ...action.payload.data,
        ...state.insurance.collection.filter((item) => item.shared)
      ];
    },

    // GET Shared Collection INSURANCE
    setSharedCOInsurance(state, action) {
      state.shared.collection = true;
      state.insurance.collection = [...state.insurance.collection.filter((item) => !item.shared), ...action.payload];
    },

    // GET Automobile INSURANCE
    setAMInsurance(state, action) {
      state.isLoading = false;
      state.chartData.automobile = action.payload.topChartData;
      state.insurance.automobile = [
        ...action.payload.data,
        ...state.insurance.automobile.filter((item) => item.shared)
      ];
    },

    // GET Shared Automobile INSURANCE
    setSharedAMInsurance(state, action) {
      state.shared.automobile = true;
      state.insurance.automobile = [...state.insurance.automobile.filter((item) => !item.shared), ...action.payload];
    },

    // GET Watercraft INSURANCE
    setWAInsurance(state, action) {
      state.isLoading = false;
      state.chartData.watercraft = action.payload.topChartData;
      state.insurance.watercraft = [
        ...action.payload.data,
        ...state.insurance.watercraft.filter((item) => item.shared)
      ];
    },

    // GET Shared Watercraft INSURANCE
    setSharedWAInsurance(state, action) {
      state.shared.watercraft = true;
      state.insurance.watercraft = [...state.insurance.watercraft.filter((item) => !item.shared), ...action.payload];
    },

    // GET Aircraft INSURANCE
    setAIInsurance(state, action) {
      state.isLoading = false;
      state.chartData.aircraft = action.payload.topChartData;
      state.insurance.aircraft = [...action.payload.data, ...state.insurance.aircraft.filter((item) => item.shared)];
    },

    // GET Shared Aircraft INSURANCE
    setSharedAIInsurance(state, action) {
      state.shared.aircraft = true;
      state.insurance.aircraft = [...state.insurance.aircraft.filter((item) => !item.shared), ...action.payload];
    },

    // GET Equine INSURANCE
    setEQInsurance(state, action) {
      state.isLoading = false;
      state.chartData.equine = action.payload.topChartData;
      state.insurance.equine = [...action.payload.data, ...state.insurance.equine.filter((item) => item.shared)];
    },

    // GET Shared Equine INSURANCE
    setSharedEQInsurance(state, action) {
      state.shared.equine = true;
      state.insurance.equine = [...state.insurance.equine.filter((item) => !item.shared), ...action.payload];
    },

    // GET Umbrella INSURANCE
    setUMInsurance(state, action) {
      state.isLoading = false;
      state.chartData.umbrella = action.payload.topChartData;
      state.insurance.umbrella = [...action.payload.data, ...state.insurance.umbrella.filter((item) => item.shared)];
    },

    // GET Shared Umbrella INSURANCE
    setSharedUMInsurance(state, action) {
      state.shared.umbrella = true;
      state.insurance.umbrella = [...state.insurance.umbrella.filter((item) => !item.shared), ...action.payload];
    },

    // GET Misc INSURANCE
    setMISCInsurance(state, action) {
      state.isLoading = false;
      state.chartData.misc = action.payload.topChartData || {};
      state.insurance.misc = [...action.payload.data, ...state.insurance.misc.filter((item) => item.shared)];
    },

    // GET Shared Misc INSURANCE
    setSharedMISCInsurance(state, action) {
      state.shared.misc = true;
      state.insurance.misc = [...state.insurance.misc.filter((item) => !item.shared), ...action.payload];
    },

    setClaimInsurance(state, action) {
      state.isLoading = false;
      state.chartData.claim = action.payload.topChartData;
      state.insurance.claim = action.payload.data;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getSharedInsurance(tab) {
  return async (dispatch) => {
    try {
      if (tab === 'realEstate') {
        const shared = await axios.get('/shared/insurance/real-estate');

        dispatch(slice.actions.setSharedREInsurance(shared.data));
      } else if (tab === 'collection') {
        const shared = await axios.get('/shared/insurance/collection');

        dispatch(slice.actions.setSharedCOInsurance(shared.data));
      } else if (tab === 'automobile') {
        const shared = await axios.get('/shared/insurance/automobile');

        dispatch(slice.actions.setSharedAMInsurance(shared.data));
      } else if (tab === 'watercraft') {
        const shared = await axios.get('/shared/insurance/watercraft');

        dispatch(slice.actions.setSharedWAInsurance(shared.data));
      } else if (tab === 'aircraft') {
        const shared = await axios.get('/shared/insurance/aircraft');

        dispatch(slice.actions.setSharedAIInsurance(shared.data));
      } else if (tab === 'umbrella') {
        const shared = await axios.get('/shared/insurance/umbrella');

        dispatch(slice.actions.setSharedUMInsurance(shared.data));
      } else if (tab === 'misc') {
        const shared = await axios.get('/shared/insurance/misc');

        dispatch(slice.actions.setSharedMISCInsurance(shared.data));
      } else if (tab === 'equine') {
        const shared = await axios.get('/shared/insurance/equine');

        dispatch(slice.actions.setSharedEQInsurance(shared.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllInsurance() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const insuranceResponse = await axios.get('/insurance/all');

      dispatch(slice.actions.setInsurance(insuranceResponse.data));
      return insuranceResponse.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function addInsurance(data) {
  return async (dispatch) => {
    const payload = {
      ...data
    };
    const tab = payload.type;
    dispatch(slice.actions.startLoading());
    try {
      if (tab === 'realEstate') {
        const result = await axios.post('/insurance/real-estate', payload);
        const response = await axios.get('/insurance/real-estate');
        const insurance = response.data;

        dispatch(slice.actions.setREInsurance(insurance));
        return result;
      } else if (tab === 'collection') {
        const result = await axios.post('/insurance/collection', payload);
        const response = await axios.get('/insurance/collection');
        const insurance = response.data;

        dispatch(slice.actions.setCOInsurance(insurance));
        return result;
      } else if (tab === 'automobile') {
        const result = await axios.post('/insurance/automobile', payload);
        const response = await axios.get('/insurance/automobile');
        const insurance = response.data;

        dispatch(slice.actions.setAMInsurance(insurance));
        return result;
      } else if (tab === 'watercraft') {
        const result = await axios.post('/insurance/watercraft', payload);
        const response = await axios.get('/insurance/watercraft');
        const insurance = response.data;

        dispatch(slice.actions.setWAInsurance(insurance));
        return result;
      } else if (tab === 'aircraft') {
        const result = await axios.post('/insurance/aircraft', payload);
        const response = await axios.get('/insurance/aircraft');
        const insurance = response.data;

        dispatch(slice.actions.setAIInsurance(insurance));
        return result;
      } else if (tab === 'umbrella') {
        const result = await axios.post('/insurance/umbrella', payload);
        const response = await axios.get('/insurance/umbrella');
        const insurance = response.data;

        dispatch(slice.actions.setUMInsurance(insurance));
        return result;
      } else if (tab === 'misc') {
        const result = await axios.post('/insurance/misc', payload);
        const response = await axios.get('/insurance/misc');
        const insurance = response.data;

        dispatch(slice.actions.setMISCInsurance(insurance));
        return result;
      } else if (tab === 'equine') {
        const result = await axios.post('/insurance/equine', payload);
        const response = await axios.get('/insurance/equine');
        const insurance = response.data;

        dispatch(slice.actions.setEQInsurance(insurance));
        return result;
      } else if (tab === 'claim') {
        const result = await axios.post('/insurance/claim', payload);
        const response = await axios.get('/insurance/claim');
        const insurance = response.data;

        dispatch(slice.actions.setClaimInsurance(insurance));
        return result;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function updateInsurance(data, userId, isFamilyGroup) {
  return async (dispatch) => {
    const payload = {
      ...data
    };
    const tab = payload.type;
    dispatch(slice.actions.startLoading());
    try {
      let result;
      let response;
      let insurance = {
        data: [],
        topChartData: []
      };
      if (tab === 'realEstate') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/insurance/real-estate?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/insurance/real-estate?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.put('/insurance/real-estate', payload);
          response = await axios.get('/insurance/real-estate');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setREInsurance(insurance));
        return result?.data;
      } else if (tab === 'collection') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/insurance/collection?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/insurance/collection?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.put('/insurance/collection', payload);
          response = await axios.get('/insurance/collection');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setCOInsurance(insurance));
        return result?.data;
      } else if (tab === 'automobile') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/insurance/automobile?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/insurance/automobile?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.put('/insurance/automobile', payload);
          response = await axios.get('/insurance/automobile');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setAMInsurance(insurance));
        return result?.data;
      } else if (tab === 'watercraft') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/insurance/watercraft?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/insurance/watercraft?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.put('/insurance/watercraft', payload);
          response = await axios.get('/insurance/watercraft');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setWAInsurance(insurance));
        return result?.data;
      } else if (tab === 'aircraft') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/insurance/aircraft?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/insurance/aircraft?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.put('/insurance/aircraft', payload);
          response = await axios.get('/insurance/aircraft');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setAIInsurance(insurance));
        return result?.data;
      } else if (tab === 'equine') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/insurance/equine?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/insurance/equine?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.put('/insurance/equine', payload);
          response = await axios.get('/insurance/equine');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setEQInsurance(insurance));
        return result?.data;
      } else if (tab === 'umbrella') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/insurance/umbrella?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/insurance/umbrella?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.put('/insurance/umbrella', payload);
          response = await axios.get('/insurance/umbrella');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setUMInsurance(insurance));
        return result?.data;
      } else if (tab === 'misc') {
        if (!!userId) {
          result = await axios.put(`/advisor/insurance/misc?userId=${userId}&isFamilyGroup=${isFamilyGroup}`, payload);
          response = await axios.get(
            `/advisor/insurance/misc?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.put('/insurance/misc', payload);
          response = await axios.get('/insurance/misc');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setMISCInsurance(insurance));
        return result?.data;
      } else if (tab === 'claim') {
        if (!!userId) {
          result = await axios.put(`/advisor/insurance/claim?userId=${userId}&isFamilyGroup=${isFamilyGroup}`, payload);
          response = await axios.get(
            `/advisor/insurance/claim?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.put('/insurance/claim', payload);
          response = await axios.get('/insurance/claim');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setClaimInsurance(insurance));
        return result?.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function deleteInsurance(data) {
  return async (dispatch) => {
    const payload = {
      ...data
    };
    const {tab, id, userId, isFamilyGroup} = payload;
    dispatch(slice.actions.startLoading());
    try {
      let response;
      let result;
      let insurance = {
        data: [],
        topChartData: []
      };
      if (tab === 'realEstate') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/insurance/real-estate?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/insurance/real-estate?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.delete(`/insurance/real-estate/${id}`);
          response = await axios.get('/insurance/real-estate');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setREInsurance(insurance));
        return result?.data;
      } else if (tab === 'collection') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/insurance/collection?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/insurance/collection?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.delete(`/insurance/collection/${id}`);
          response = await axios.get('/insurance/collection');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setCOInsurance(insurance));
        return result?.data;
      } else if (tab === 'automobile') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/insurance/automobile?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/insurance/automobile?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.delete(`/insurance/automobile/${id}`);
          response = await axios.get('/insurance/automobile');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setAMInsurance(insurance));
        return result?.data;
      } else if (tab === 'watercraft') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/insurance/watercraft?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/insurance/watercraft?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.delete(`/insurance/watercraft/${id}`);
          response = await axios.get('/insurance/watercraft');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setWAInsurance(insurance));
        return result?.data;
      } else if (tab === 'aircraft') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/insurance/aircraft?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/insurance/aircraft?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.delete(`/insurance/aircraft/${id}`);
          response = await axios.get('/insurance/aircraft');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setAIInsurance(insurance));
        return result?.data;
      } else if (tab === 'umbrella') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/insurance/umbrella?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/insurance/umbrella?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.delete(`/insurance/umbrella/${id}`);
          response = await axios.get('/insurance/umbrella');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setUMInsurance(insurance));
        return result?.data;
      } else if (tab === 'equine') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/insurance/equine?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/insurance/equine?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.delete(`/insurance/equine/${id}`);
          response = await axios.get('/insurance/equine');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setEQInsurance(insurance));
        return result?.data;
      } else if (tab === 'misc') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/insurance/misc?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/insurance/misc?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.delete(`/insurance/misc/${id}`);
          response = await axios.get('/insurance/misc');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setMISCInsurance(insurance));
        return result?.data;
      } else if (tab === 'claim') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/insurance/claim?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/insurance/claim?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          insurance.data.push(response.data);
        } else {
          result = await axios.delete(`/insurance/claim/${id}`);
          response = await axios.get('/insurance/claim');
          insurance = {...response.data};
        }

        dispatch(slice.actions.setClaimInsurance(insurance));
        return result?.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
