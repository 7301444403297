import PropTypes from 'prop-types';
// material
import {styled} from '@mui/material/styles';
import {Card, Typography, Stack, Avatar} from '@mui/material';
import {LoadingButton} from '@mui/lab';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({theme}) => ({
  maxWidth: 768,
  width: '100%',
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  padding: theme.spacing(2),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(3)
  }
}));

// ----------------------------------------------------------------------

InviteCard.propTypes = {
  invite: PropTypes.object,
  onAcceptClick: PropTypes.func
};

export default function InviteCard({invite, onAcceptClick, accepted = null}) {
  const name = `${invite?.firstName ?? ''}  ${invite?.lastName ?? ''}`;

  return (
    <RootStyle>
      <Stack direction={'row'} alignItems={'center'}>
        <Avatar sx={{bgcolor: 'background.neutral', mr: 2}}>
          {<img alt={name} src="/static/icons/ic_notification_mail.svg" />}
        </Avatar>

        <Stack>
          <Typography variant="h5">{`Contact Invitation`}</Typography>
          <Stack direction={'row'} mt={2}>
            <Typography variant="body1" sx={{color: 'text.secondary', fontWeight: 'bold', width: 70}}>
              Name :
            </Typography>
            <Typography variant="body1">{name}</Typography>
          </Stack>

          <Stack direction={'row'} mt={1}>
            <Typography variant="body1" sx={{color: 'text.secondary', fontWeight: 'bold', width: 70}}>
              Email :
            </Typography>
            <Typography variant="body1">{invite?.email}</Typography>
          </Stack>

          <Stack direction={'row'} mt={1}>
            <Typography variant="body1" sx={{color: 'text.secondary', fontWeight: 'bold', width: 130}}>
              Contact Type :
            </Typography>
            <Typography variant="body1">{invite?.contactType}</Typography>
          </Stack>
        </Stack>
      </Stack>

      <LoadingButton
        fullWidth
        size="small"
        variant="contained"
        color="primary"
        onClick={() => onAcceptClick(invite)}
        sx={{width: 'fit-content', ml: 'auto', mt: 5}}
        loading={invite.id === accepted}
        disabled={accepted !== null}
      >
        Accept Invite
      </LoadingButton>
    </RootStyle>
  );
}
