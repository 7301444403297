export const PULL_STATUS = {
  STARTED: 'STARTED',
  QUEUED: 'QUEUED',
  PROCESSING: 'PROCESSING',
  IDENTITY_VERIFICATION_OPTIONS: 'IDENTITY_VERIFICATION_OPTIONS',
  IDENTITY_VERIFICATION: 'IDENTITY_VERIFICATION',
  GETTING_CONSUMERS: 'GETTING_CONSUMERS',
  PULLING_DATA: 'PULLING_DATA',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  PROVIDER_ERROR: 'PROVIDER_ERROR',
  SUCCESS: 'SUCCESS'
};
