import React, {useCallback} from 'react';
// mui
import {Checkbox} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
// hook
import useColumnSettings from 'src/hooks/useColumnSettings';
// components
import StatusLabel from 'src/components/_dashboard/insurance/common/StatusLabel';
// ----------------------------------------------------------------------

const RETable = React.memo(({data = [], selectedList = [], handleSelect}) => {
  const common = [
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => {
        const dupLevel = params.row.dupLevel;

        return !!dupLevel ? <StatusLabel status={dupLevel} text="duplicated" /> : null;
      }
    },
    {
      field: 'customVehicleType',
      headerName: 'Type',
      width: 150
    },
    {
      field: 'year',
      headerName: 'Year',
      width: 120
    },
    {
      field: 'make',
      headerName: 'Make',
      width: 120
    },
    {
      field: 'amModel',
      headerName: 'Model',
      width: 120
    },
    {
      field: 'idNumber',
      headerName: 'VIN',
      width: 180
    }
  ];
  const columns = !!handleSelect
    ? [
        {
          field: 'select',
          headerName: 'Select',
          width: 70,
          sortable: false,
          filterable: false,
          renderCell: (params) => {
            const isSelected = !!selectedList.find((item) => item.canopyAssetId === params?.row?.canopyAssetId);

            return (
              <Checkbox checked={isSelected} onClick={() => handleSelect(params?.row?.canopyAssetId, isSelected)} />
            );
          }
        },
        ...common
      ]
    : common;

  const {useColumnState} = useColumnSettings();
  const [columnSettings, setColumnSettings] = useColumnState('canopy-asset-automobile');

  const getRowId = useCallback((row) => row?.canopyAssetId, []);

  return (
    <DataGrid
      autoHeight
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      rows={data}
      getRowId={getRowId}
      columns={columns}
      columnVisibilityModel={columnSettings}
      onColumnVisibilityModelChange={setColumnSettings}
      pagination
      pageSize={10}
      rowsPerPageOptions={[10]}
    />
  );
});

export default RETable;
