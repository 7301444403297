import React, {useState} from 'react';
import PropTypes from 'prop-types';
// mui
import {Box, Card, CardHeader, CardContent, FormControlLabel, Grid, Stack, Switch} from '@mui/material';
// utils
import {isNullOrEmpty} from 'src/utils/checkEmpty';
// components
import EmptyContent from 'src/components/EmptyContent';
import LabelWidget from 'src/components/_dashboard/common/LabelWidget';
import LCChart from '../common/LCChart';
import Gallery from './components/Equine/Gallery';
import Table from './components/Equine/Table';
import ComingSoonCover from 'src/components/ComingSoonCover';
// ----------------------------------------------------------------------

Equine.propTypes = {
  mode: PropTypes.string,
  view: PropTypes.string,
  data: PropTypes.array
};

export default function Equine({mode, view = 'show', data = [], chartData}) {
  const [viewAll, setViewAll] = useState(false);

  const getValidData = () => {
    return viewAll ? data : data.filter((item) => item.assetStatus !== 'divested');
  };

  const renderData = () =>
    mode === 'gallery' ? (
      <Gallery data={data} />
    ) : (
      <Box sx={{minHeight: 360}}>
        <FormControlLabel
          sx={{position: 'absolute', top: '46px', right: '46px', zIndex: 999}}
          control={<Switch size="small" checked={viewAll} onChange={(event) => setViewAll(event.target.checked)} />}
          label={viewAll?'View Active':'View All'}
          labelPlacement="start"
        />
        <Table data={getValidData()} />
      </Box>
    );

  return (
    <Stack spacing={2}>
      {view === 'show' && (
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{m: 1, height: 'calc(100% - 16px)', justifyContent: 'space-between'}} spacing={2}>
              <LabelWidget label="Total market value" data={chartData.totalMarketValue} type="currency" />
              <LabelWidget label="Total cost basis" data={chartData.totalCostBasis} type="currency" />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{m: 1, display: 'flex', flexDirection: 'column'}}>
              <CardHeader title="Total number of horses" />
              <Box sx={{flex: 1}}>
                {isNullOrEmpty(chartData?.totalNumberOfHorses) && (
                  <ComingSoonCover noData={true} style={{marginTop: 25}} />
                )}
                <LCChart
                  type="quantity"
                  label={Object.keys(chartData.totalNumberOfHorses || {})}
                  data={Object.values(chartData.totalNumberOfHorses || {})}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
      <Card>
        <CardContent>{data.length > 0 ? renderData() : <EmptyContent />}</CardContent>
      </Card>
    </Stack>
  );
}
