export function formatPhoneNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '').slice(-10);

  return cleaned.length > 6
    ? cleaned.replace(/^(\d{3})(\d{3})(\d*)/, '$1-$2-$3')
    : cleaned.length > 3
    ? cleaned.replace(/^(\d{3})(\d*)/, '$1-$2')
    : cleaned;
}

export function getCleanPhoneNumber(phoneString) {
  return ('' + phoneString)
    .replace(/^(\+1|)?(\d{*})/, '$2')
    .replace(/\D/g, '')
    .substring(0, 10);
}

export function getFullAddress(...args) {
  return args.filter((item) => !!item).join(', ');
}

export function getFormattedAddress(address) {
  if (address) {
    const {street, apt, city, state, country, zipCode} = address;
    return getFullAddress(street, apt, city, state, country, zipCode);
  }
}
