// mui
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// components
import CanopyPolicyTable from './CanopyPolicyTable';
import CanopyClaimTable from './CanopyClaimTable';
import RETable from './canopyAssetTable/RETable';
import AUTable from './canopyAssetTable/AUTable';
// ----------------------------------------------------------------------

const ConfirmDialog = ({
  loading = false,
  policyData = [],
  assetData = [],
  claimData = [],
  selectedList = [],
  selectedAssetList = [],
  selectedClaimList = [],
  handleClose,
  handleConfirm
}) => {
  const dupPolicyData = policyData.filter((item) =>
    selectedList.some((sItem) => !!sItem.opticusPolicyId && sItem.canopyPolicyId === item.policyId)
  );
  const dupClaimData = claimData.filter((item) =>
    selectedClaimList.some((sItem) => !!sItem.opticusClaimId && sItem.canopyPolicyId === item.canopyPolicyId)
  );
  const dupAssetREData = assetData.filter(
    (item) =>
      item.type === 'realEstate' &&
      selectedAssetList.some((sItem) => !!sItem.opticusAssetId && sItem.canopyAssetId === item.canopyAssetId)
  );
  const dupAssetAUData = assetData.filter(
    (item) =>
      item.type === 'automobile' &&
      selectedAssetList.some((sItem) => !!sItem.opticusAssetId && sItem.canopyAssetId === item.canopyAssetId)
  );

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>{'Overwrite?'}</DialogTitle>
      <DialogContent>
        <Box sx={{my: 1}}>
          <Typography>There are some duplicated data with existing content. Are you sure to overwrite?</Typography>
        </Box>
        <Typography variant="h6" sx={{color: (theme) => theme.palette.grey[500]}}>
          Policy
        </Typography>
        <CanopyPolicyTable data={dupPolicyData} />
        {!!dupAssetREData.length && (
          <>
            <Typography variant="h6" sx={{color: (theme) => theme.palette.grey[500]}}>
              Asset/Real Estate
            </Typography>
            <RETable data={dupAssetREData} />
          </>
        )}
        {!!dupAssetAUData.length && (
          <>
            <Typography variant="h6" sx={{color: (theme) => theme.palette.grey[500]}}>
              Asset/Automobile
            </Typography>
            <AUTable data={dupAssetAUData} />
          </>
        )}
        {!!dupClaimData.length && (
          <>
            <Typography variant="h6" sx={{color: (theme) => theme.palette.grey[500]}}>
              Claim
            </Typography>
            <CanopyClaimTable data={dupClaimData} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => handleConfirm(selectedList, selectedAssetList, selectedClaimList)}
          autoFocus
          variant="contained"
          color="error"
        >
          Overwrite
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
