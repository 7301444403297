import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {capitalCase} from 'change-case';
// mui
import {Card, CircularProgress, Stack, Typography} from '@mui/material';
// api
import {getInsDetail} from 'src/api';
// hooks
import useAuth from 'src/hooks/useAuth';
// constants
import {getIcon} from 'src/utils/items';
// ----------------------------------------------------------------------

InsuranceDetails.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  userId: PropTypes.string
};

InsuranceDetails.defaultProps = {
  userId: ''
};

export default function InsuranceDetails({type, id, userId}) {
  const navigate = useNavigate();
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const [insuranceDetail, setInsuranceDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const icon = getIcon('policy', type);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getInsDetail(type, id, userId, !isAdvisor);
        if (isMounted) {
          if (data && data.policyNumber && (type === 'claim' || data.status === 'active')) {
            setInsuranceDetail(`${capitalCase(type)} - ${data.policyNumber}`);
          }
        }
      } catch (err) {
        console.log('Insurance-Card: ', err);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    if (!!id && !!type) fetchData();

    return () => {
      isMounted = false;
    };
  }, [id, userId, type, isAdvisor]);

  const handleClick = () => {
    navigate(!userId ? `/dashboard/insurance/${type}/${id}` : `/dashboard/insurance/${type}/${id}/${userId}`);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return !type || !insuranceDetail ? null : (
    <Card sx={{cursor: 'pointer', px: 2, py: 1}} onClick={handleClick}>
      <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
        {icon}
        <Typography>{insuranceDetail}</Typography>
      </Stack>
    </Card>
  );
}
