export function getInsStatusByDate(date, values, type) {
  const currentDate = new Date();
  const effectiveDate = type === 'effDate' ? date : new Date(values.effDate);
  const expirationDate = type === 'effDate' ? values.expDate : date;

  if (currentDate >= effectiveDate) {
    if (type === 'effDate') {
      if (new Date(values.expDate) >= currentDate) {
        return 'active';
      } else {
        return 'dormant';
      }
    } else {
      if (!date || currentDate <= expirationDate) {
        return 'active';
      } else {
        return 'dormant';
      }
    }
  } else {
    return 'pending';
  }
}
