import PropTypes from 'prop-types';
// mui
import {Box} from '@mui/material';
// hook
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

LogoWithName.propTypes = {
  className: PropTypes.string,
  blackMode: PropTypes.bool
};

LogoWithName.defaultProps = {
  blackMode: false
};

function LogoWithName({className, blackMode, ...other}) {
  const {themeMode} = useSettings();

  const renderImage = () => {
    if (blackMode) {
      return blackMode ? '/static/brand/logo_white.png' : '/static/brand/logo_black.png';
    } else {
      return themeMode === 'dark' ? '/static/brand/logo_white.png' : '/static/brand/logo_black.png';
    }
  };

  return <Box component="img" alt="logo" src={renderImage()} height={40} className={className} {...other} />;
}

export default LogoWithName;
