import {Icon} from '@iconify/react';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import searchFill from '@iconify/icons-eva/search-fill';
// mui
import {styled, alpha} from '@mui/material/styles';
import {Box, Input, Slide, InputAdornment, ClickAwayListener} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// redux
import {getSearchData} from 'src/redux/slices/search';
import {useDispatch} from 'src/redux/store';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import {MIconButton} from 'src/components/@material-extend';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({theme}) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleSearch = async () => {
    setLoading(true);
    await dispatch(getSearchData(query));
    setLoading(false);
    setOpen(false);
    setQuery('');
    navigate(PATH_DASHBOARD.search);
  };

  const handleClose = () => setOpen(false);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <MIconButton onClick={handleOpen}>
            <Icon icon={searchFill} width={20} height={20} />
          </MIconButton>
        )}
        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{color: 'text.disabled', width: 20, height: 20}} />
                </InputAdornment>
              }
              sx={{mr: 1, fontWeight: 'fontWeightBold'}}
            />
            <LoadingButton type="submit" variant="contained" loading={loading} onClick={handleSearch}>
              Search
            </LoadingButton>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
