import React, {useRef, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
// mui
import {alpha, styled} from '@mui/material/styles';
import {
  Button,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  CircularProgress,
  TextField,
  Switch
} from '@mui/material';
import {TreeItem, TreeView, treeItemClasses} from '@mui/lab';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ChevronRight} from '@mui/icons-material';
// redux
import {useDispatch, useSelector} from 'src/redux/store';
import {addFavoriteClient, getClients, updateClients} from 'src/redux/slices/client';
// utils
import {getFullName} from 'src/utils/createAvatar';
import {searchByName} from 'src/utils/searchByName';
// components
import UserAvatar from 'src/components/UserAvatar';
import MenuPopover from 'src/components/MenuPopover';
import EmptyContent from 'src/components/EmptyContent';
import {MAvatar} from 'src/components/@material-extend';

// ----------------------------------------------------------------------

const StyledTreeItem = styled(TreeItem)(({theme}) => ({
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2)
    }
  }
}));

const AccountStyle = styled('div')(({theme}) => ({
  height: '76px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

function ClientPopover() {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const {pathname} = useLocation();
  const {initiated, isLoading, list: allClients} = useSelector((state) => state.client);

  const [showFavorites, setShowFavorites] = useState(false);
  const [searchTerm, setsearchTerm] = useState('');
  const list = allClients
    ?.filter?.((client) => searchByName(searchTerm, client) && (showFavorites === true ? client?.isFavorite : true))
    .sort((a, b) => {
      if (b.isFavorite) {
        if (a.isFavorite) {
          return a.lastName > b.lastName ? 1 : -1;
        } else {
          return 1;
        }
      }

      return a.lastName > b.lastName ? 1 : -1;
    });

  // NOTE: list after grouping
  const groupedList = {};
  list.forEach((client) => {
    if (client?.familyGroup?.length === 0) {
      const groupName = 'No Family Group';
      const groupId = 'no-family-group';

      if (!groupedList[groupId]) {
        groupedList[groupId] = {groupName, clients: []};
      }
      groupedList[groupId].clients.push(client);
    } else {
      client?.familyGroup?.forEach((group) => {
        const groupName = group.groupName;
        const groupId = group.id;

        if (!groupedList[groupId]) {
          groupedList[groupId] = {groupName, clients: []};
        }
        groupedList[groupId].clients.push(client);
      });
    }
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!initiated) dispatch(getClients());
  }, [dispatch, initiated]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getShowStatus = (id) => {
    const client = list.find((item) => item.id === id);
    return client && client.show;
  };

  const getAllShowStatus = () => {
    return list.every((item) => item.show);
  };

  const handleConnectedClient = (flag, id) => {
    let newClients = allClients.map((item) => (item.id === id ? {...item, show: flag} : item));
    dispatch(updateClients(newClients));
  };

  const handleAllClient = (flag) => {
    if (!allClients?.length) return;
    let newClients = allClients.map((item) => ({...item, show: flag}));
    dispatch(updateClients(newClients));
  };

  const activeClients = list.filter((item) => item?.show);

  // get group status
  const getGroupShowStatus = (id) => {
    let groupClients = list.filter((client) => client?.familyGroup?.some?.((group) => group.id === id));
    return groupClients.every((item) => item.show);
  };

  // handle group
  const handleGroup = (flag, id) => {
    let newClients = allClients?.map((client) => {
      const hasGroup = client?.familyGroup?.some?.((group) => group.id === id);
      return hasGroup ? {...client, show: flag} : {...client};
    });

    dispatch(updateClients(newClients));
  };

  // manage name length
  const manageName = (str, len = 20) => {
    return str.length > len ? str.slice(0, len) + '...' : str;
  };

  // handle favorite
  const handleFavoriteClick = (flag, id) => {
    let newClients = allClients.map((item) => (item.id === id ? {...item, isFavorite: flag} : item));
    let clientsData = {clients: newClients?.map((m) => ({id: m?.id, isFavorite: m?.isFavorite}))};
    dispatch(addFavoriteClient(clientsData, newClients));
  };

  const isOnEditPage = pathname.includes('edit');

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={handleOpen}
        disabled={isOnEditPage}
        sx={{
          mt: 0,
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '12px',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[800], 0.2)
            }
          })
        }}
      >
        <AccountStyle>
          {activeClients.length === 1 ? (
            <Stack direction="row">
              <UserAvatar userId={activeClients[0].id} />
              <Box sx={{ml: 2, textAlign: 'left'}}>
                <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                  {activeClients[0]?.firstName} {activeClients[0]?.lastName}
                </Typography>
                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                  Client
                </Typography>
              </Box>
            </Stack>
          ) : (
            <Stack direction="row">
              <MAvatar color="primary" sx={{boxShadow: '0px 2px 10px 2px rgb(0 0 0 / 30%)', border: '1px solid white'}}>
                {activeClients.length}
              </MAvatar>
              <Box sx={{ml: 2, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                  clients selected
                </Typography>
              </Box>
            </Stack>
          )}
          <ExpandMoreIcon sx={{color: 'grey'}} />
        </AccountStyle>
      </Button>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{width: 240, overflowX: 'hidden', overflowY: 'auto', maxHeight: 550}}
      >
        {isLoading ? (
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100px'}}>
            <CircularProgress />
          </Box>
        ) : !allClients || !allClients.length ? (
          <EmptyContent title="You are not connected to any client." />
        ) : (
          <>
            <Box sx={{my: 1.5, px: 2.5}}>
              <Typography variant="subtitle1" noWrap color="gray">
                Connected Clients
              </Typography>
            </Box>

            {/* divider */}
            <Divider sx={{mt: 1}} />

            {/* favorites */}
            <FormControlLabel
              labelPlacement={'start'}
              defaultValue={false}
              control={<Switch />}
              label={<Typography variant="overline">Favorites</Typography>}
              checked={showFavorites}
              onChange={(e) => {
                handleAllClient(false);
                setShowFavorites(e.target.checked);
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                pr: 2,
                py: 0.5
              }}
            />

            {/* divider */}
            <Divider sx={{mt: 0}} />

            {/* search */}
            <TextField
              value={searchTerm}
              onChange={(e) => setsearchTerm(e.target.value)}
              placeholder="Search client ..."
              size="small"
              sx={{m: 1, borderRadius: 1}}
              variant="outlined"
            />

            {/* divider */}
            <Divider />

            {/* select all */}
            {list?.length > 0 ? (
              <MenuItem sx={{typography: 'body2', py: 1, px: 2.5}}>
                <FormControlLabel
                  control={
                    <Checkbox checked={getAllShowStatus()} onChange={(e) => handleAllClient(e.target.checked)} />
                  }
                  label="Select / Deselect All"
                />
              </MenuItem>
            ) : (
              <EmptyContent title="No client found." />
            )}

            {!showFavorites ? (
              <>
                {/* family group tree */}
                <TreeView
                  aria-label="controlled"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={['root']}
                  defaultExpandIcon={<ChevronRight />}
                  defaultEndIcon={''}
                  sx={{maxHeight: 400, flexGrow: 1, overflowY: 'auto', width: '100%'}}
                >
                  {
                    // groups
                    Object.keys(groupedList).filter?.((key) => key !== 'no-family-group').length > 0 &&
                      Object.keys(groupedList)?.map?.(
                        (group) =>
                          group !== 'no-family-group' && (
                            <StyledTreeItem
                              sx={{borderTop: '1px solid rgba(145, 158, 171, 0.24)'}}
                              key={group}
                              nodeId={group}
                              label={
                                <FormControlLabel
                                  sx={{py: 1, px: 0}}
                                  control={
                                    <Checkbox
                                      checked={getGroupShowStatus(group)}
                                      onChange={(e) => handleGroup(e.target.checked, group)}
                                    />
                                  }
                                  label={manageName(groupedList[group].groupName)}
                                />
                              }
                            >
                              {
                                // clients
                                groupedList[group].clients.map((client) => (
                                  <StyledTreeItem
                                    sx={{
                                      backgroundColor: 'rgba(145, 158, 171, 0.2)',
                                      borderTop: '1px solid rgba(145, 158, 171, 0.24)',
                                      paddingLeft: 1.5
                                    }}
                                    key={group + client.id}
                                    nodeId={group + client.id}
                                    label={
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                          pr: 1.5,
                                          pl: 1.3
                                        }}
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={getShowStatus(client.id)}
                                              onChange={(e) => handleConnectedClient(e.target.checked, client.id)}
                                            />
                                          }
                                          label={manageName(getFullName(client), 12)}
                                        />
                                        <Tooltip title={client.email || 'no email'} arrow>
                                          <Box
                                            onClick={() => handleFavoriteClick(!client?.isFavorite, client?.id)}
                                            sx={{
                                              padding: '0px !important',
                                              margin: '0px !important',
                                              width: '20px',
                                              height: '20px'
                                            }}
                                          >
                                            {!!client?.isFavorite ? (
                                              <FavoriteIcon sx={{color: '#ed5353'}} fontSize="small" />
                                            ) : (
                                              <FavoriteBorderOutlinedIcon sx={{color: 'grey'}} fontSize="small" />
                                            )}
                                          </Box>
                                        </Tooltip>
                                      </Box>
                                    }
                                  />
                                ))
                              }
                            </StyledTreeItem>
                          )
                      )
                  }
                </TreeView>

                {/* ungrouped clients */}
                {groupedList['no-family-group']?.clients?.length > 0 &&
                  groupedList['no-family-group']?.clients?.map?.((client) => (
                    <MenuItem
                      key={client.id}
                      sx={{
                        typography: 'body2',
                        py: 1,
                        pr: 2.5,
                        pl: 0.6,
                        borderTop: '1px solid rgba(145, 158, 171, 0.24);'
                      }}
                    >
                      <FormControlLabel
                        sx={{marginRight: 'auto', marginLeft: 2.3}}
                        control={
                          <Checkbox
                            checked={getShowStatus(client.id)}
                            onChange={(e) => handleConnectedClient(e.target.checked, client.id)}
                          />
                        }
                        label={manageName(getFullName(client))}
                      />
                      <Tooltip title={client.email || 'no email'} arrow>
                        <Box
                          onClick={() => handleFavoriteClick(!client?.isFavorite, client?.id)}
                          sx={{
                            padding: '0px !important',
                            margin: '0px !important',
                            width: '20px',
                            height: '20px'
                          }}
                        >
                          {!!client?.isFavorite ? (
                            <FavoriteIcon sx={{color: '#ed5353'}} fontSize="small" />
                          ) : (
                            <FavoriteBorderOutlinedIcon sx={{color: 'grey'}} fontSize="small" />
                          )}
                        </Box>
                      </Tooltip>
                    </MenuItem>
                  ))}
              </>
            ) : (
              list.length > 0 &&
              list?.map?.((client) => (
                <MenuItem
                  key={client.id}
                  sx={{
                    typography: 'body2',
                    py: 1,
                    pr: 2.5,
                    pl: 0.6,
                    borderTop: '1px solid rgba(145, 158, 171, 0.24);'
                  }}
                >
                  <FormControlLabel
                    sx={{marginRight: 'auto', marginLeft: 2.3}}
                    control={
                      <Checkbox
                        checked={getShowStatus(client.id)}
                        onChange={(e) => handleConnectedClient(e.target.checked, client.id)}
                      />
                    }
                    label={manageName(getFullName(client))}
                  />
                  <Tooltip title={client.email || 'no email'} arrow>
                    <Box
                      onClick={() => handleFavoriteClick(!client?.isFavorite, client?.id)}
                      sx={{
                        padding: '0px !important',
                        margin: '0px !important',
                        width: '20px',
                        height: '20px'
                      }}
                    >
                      {!!client?.isFavorite ? (
                        <FavoriteIcon sx={{color: '#ed5353'}} fontSize="small" />
                      ) : (
                        <FavoriteBorderOutlinedIcon sx={{color: 'grey'}} fontSize="small" />
                      )}
                    </Box>
                  </Tooltip>
                </MenuItem>
              ))
            )}
          </>
        )}
      </MenuPopover>
    </>
  );
}

export default React.memo(ClientPopover);
