import {useFormikContext} from 'formik';
import {useSnackbar} from 'notistack';
import {useEffect} from 'react';

const FormikErrorNotification = () => {
  const {isValid, isValidating, isSubmitting} = useFormikContext();
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      enqueueSnackbar('Please provide required fields and fix issues!', {variant: 'error'});
    }
  }, [isSubmitting, isValid, isValidating, enqueueSnackbar]);

  return null;
};

export default FormikErrorNotification;
