import React from 'react';
import PropTypes from 'prop-types';
// mui
import {Box, Card, CardContent, Stack} from '@mui/material';
// components
import EmptyContent from 'src/components/EmptyContent';
import Gallery from './components/Dormant/Gallery';
import Table from './components/Dormant/Table';
import {cloneDeep, flatten} from 'lodash';
// ----------------------------------------------------------------------

Dormant.propTypes = {
  mode: PropTypes.string,
  view: PropTypes.string,
  data: PropTypes.object
};

export default function Dormant({mode, data = {}}) {
  const x = cloneDeep(data);
  delete x.claim;

  const filteredData = flatten(Object.values(x)).filter((item) => item.status !== 'active');

  const renderData = () =>
    mode === 'gallery' ? (
      <Gallery data={filteredData} />
    ) : (
      <Box sx={{minHeight: 360}}>
        <Table data={filteredData} />
      </Box>
    );

  return (
    <Stack spacing={2}>
      <Card>
        <CardContent>{filteredData.length > 0 ? renderData() : <EmptyContent />}</CardContent>
      </Card>
    </Stack>
  );
}
