// mui
import {Checkbox, Stack, Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {useTheme} from '@mui/material/styles';
// hook
import useColumnSettings from 'src/hooks/useColumnSettings';
// utils
import {fCurrency} from 'src/utils/formatNumber';
// components
import Label from 'src/components/Label';
import StatusLabel from 'src/components/_dashboard/insurance/common/StatusLabel';
// ----------------------------------------------------------------------

const CanopyClaimTable = ({data = [], handleSelect, selectedList = [], insuranceList = []}) => {
  function RenderStatus(label) {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    return (
      <Label variant={isLight ? 'ghost' : 'filled'} color={label === 'closed' ? 'success' : 'error'}>
        {label}
      </Label>
    );
  }

  const common = [
    {
      field: 'carrierIdentifier',
      headerName: 'Claim Number',
      width: 240,
      renderCell: (params) => {
        const isDuplicated = params.row.isDuplicated;
        const data = params.row.carrierIdentifier;

        return (
          <Stack direction="row" spacing={1}>
            {isDuplicated && <StatusLabel status="duplicated" />}
            <Typography>{data}</Typography>
          </Stack>
        );
      }
    },
    {
      field: 'dateOccured',
      headerName: 'Occured',
      width: 120,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const occured = params.row.dateOccured;
        return <Typography variant="body2">{new Date(occured).toLocaleDateString()}</Typography>;
      }
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 280
    },
    {
      field: 'payout',
      headerName: 'Payout',
      width: 100,
      renderCell: (params) => fCurrency((params.row.payout || 0) / 100)
    }
  ];
  const columns = handleSelect
    ? [
        {
          field: 'select',
          headerName: 'Select',
          width: 70,
          sortable: false,
          filterable: false,
          renderCell: (params) => {
            const isSelected = !!selectedList.find((item) => item.canopyClaimId === params?.row?.canopyClaimId);

            return (
              <Checkbox checked={isSelected} onClick={() => handleSelect(params?.row?.canopyClaimId, isSelected)} />
            );
          }
        },
        ...common,
        {
          field: 'status',
          headerName: 'Status',
          width: 100,
          align: 'center',
          renderCell: (params) => RenderStatus(params.row.status)
        },
        {
          field: 'policyId',
          headerName: 'Policy Name',
          width: 220,
          renderCell: (params) => {
            const policy = insuranceList?.find?.((i) => i?.policyId === params.row.canopyPolicyId)?.name || '';

            return <Typography>{policy}</Typography>;
          }
        }
      ]
    : common;

  const {useColumnState} = useColumnSettings();
  const [columnSettings, setColumnSettings] = useColumnState('canopy-claim');
  const getRowId = (row) => row.canopyClaimId;

  return (
    <DataGrid
      autoHeight
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      rows={data}
      getRowId={getRowId}
      columns={columns}
      columnVisibilityModel={columnSettings}
      onColumnVisibilityModelChange={setColumnSettings}
      pagination
      pageSize={10}
      rowsPerPageOptions={[10]}
    />
  );
};

export default CanopyClaimTable;
