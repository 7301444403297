import {merge} from 'lodash';
import ReactApexChart from 'react-apexcharts';
//
import {fCurrency, fNumber} from 'src/utils/formatNumber';
import BaseOptionChart from './BaseOptionChart';
// mui
import {Box} from '@mui/material';
import {isNullOrEmpty} from 'src/utils/checkEmpty';

// ----------------------------------------------------------------------

const CHART_DATA = [
  {
    name: 'Series 1',
    data: [1200, 500, 100]
  },
  {
    name: 'Series 2',
    data: [1000, 3100, 1600]
  }
];

const CHART_CATEGORIES = ['2019', '2020', '2023'];

export default function DoubleBarChart({categories, data}) {
  const isDataEmpty = isNullOrEmpty(data);
  const [inputData, inputCategories] = isDataEmpty ? [CHART_DATA, CHART_CATEGORIES] : [data, categories];

  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: {bar: {columnWidth: '16%'}},
    stroke: {show: false},
    xaxis: {
      categories: inputCategories
    },
    yaxis: [
      {
        min: 0,
        max: Math.max(8, ...data[0]?.data) + 2,
        title: {
          text: 'Count'
        },
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true
        },
        tooltip: {}
      },
      {
        opposite: true,
        title: {
          text: 'Amount'
        },
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true
        },
        labels: {
          formatter: (val) => '$' + fNumber(val)
        }
      }
    ],
    tooltip: {
      y: {
        formatter: (val, {seriesIndex}) => (seriesIndex === 0 ? val : fCurrency(val))
      }
    },
    legend: {show: false}
  });

  return (
    <Box sx={{flex: 1, ...(isDataEmpty && {filter: 'grayscale(90%)', opacity: 0.5})}}>
      <ReactApexChart type="bar" series={[...inputData]} options={chartOptions} height={'100%'} />
    </Box>
  );
}
