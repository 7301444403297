import React from 'react';
// mui
import {Box, Container, Stack} from '@mui/material';
// components
import Page from 'src/components/Page';
import EmptyContent from 'src/components/EmptyContent';
// ----------------------------------------------------------------------

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log({error});
    console.log({info});
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Page title="Proposals | Opticus">
          <Container>
            <Stack spacing={2}>
              <Box sx={{height: '360px'}}>
                <EmptyContent
                  title="Something went wrong"
                  description="You can report this issue to the development team"
                  icon={<img width="120px" src="/static/warning.png" alt="warning" />}
                />
              </Box>
            </Stack>
          </Container>
        </Page>
      );
    }

    return this.props.children;
  }
}
