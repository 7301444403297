import {useState} from 'react';
import {useSnackbar} from 'notistack';
// mui
import {Box, Dialog, DialogActions, DialogTitle, DialogContent, Divider, TextField, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// api
import {confirmCode} from 'src/api/canopy';
// ----------------------------------------------------------------------
const CanopyVerifyCode = ({pullInfo = {}, data = {}, provider = {}, handleNext = () => {}}) => {
  const {enqueueSnackbar} = useSnackbar();

  const [open, setOpen] = useState(true);
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      const res = await confirmCode({...pullInfo, mfaValue: code});
      if (res.data?.pullId) {
        setOpen(false);
        handleNext();
      } else {
        enqueueSnackbar('Verify option submit failed.', {variant: 'error'});
        setLoading(false);
      }
    } catch (err) {
      console.log('Verify option Error:', err);
      setLoading(false);
    }
  };

  const getContent = () => {
    switch (data.mfa_input_type) {
      case 'EMAIL_OTP':
        return (
          <>
            <Typography>You should receive an email with your code at this email address:</Typography>
            <Typography variant="h6">{data.mfa_email}</Typography>
          </>
        );
      case 'SMS_OTP':
        return (
          <>
            <Typography>You should receive a text message with your code at this number:</Typography>
            <Typography variant="h6">{data.mfa_cellphone}</Typography>
          </>
        );
      case 'VOICE_OTP':
        return (
          <>
            <Typography>You should receive a call with your code at this number:</Typography>
            <Typography variant="h6">{data.mfa_cellphone}</Typography>
          </>
        );
      case 'SECURITY_QUESTION':
        return (
          <>
            <Typography>Please answer the following security question:</Typography>
            <Typography variant="h6">{data.mfa_security_question}</Typography>
          </>
        );
      case 'CARRIER_PIN':
        return (
          <>
            <Typography>{`Enter your "${provider.name}" PIN:`}</Typography>
          </>
        );
      case 'OTHER_OTP':
        return (
          <>
            <Typography>You should receive a code via:</Typography>
            <Typography variant="h6">{data.mfa_input}</Typography>
          </>
        );
      default:
        return 'Enter your verficiation code.';
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Enter your security code</DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{mb: 4}}>{getContent()}</Box>
        <TextField fullWidth label="Code" value={code} onChange={(e) => setCode(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} onClick={handleClick} variant="contained">
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CanopyVerifyCode;
