export const showOnlyActiveLabels = (labels, data) => {
  let chartArrLabels = [];
  let chartArrData = [];

  data.forEach((item, index) => {
    if (!!item) {
      chartArrLabels.push(labels[index]);
      chartArrData.push(item);
    }
  });

  return {customLabelsChart: chartArrLabels, customDataChart: chartArrData};
};
