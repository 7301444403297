import {useMemo} from 'react';
// mui
import {Autocomplete, Checkbox, TextField} from '@mui/material';
// hooks
import useAuth from 'src/hooks/useAuth';
// redux
import {useSelector} from 'src/redux/store';
// utils
import {getFullName} from 'src/utils/createAvatar';
// ----------------------------------------------------------------------

const ContactOrgSelect = ({value, label, handleChange, multiple = false}) => {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';
  const {isLoading: cLoading, contacts} = useSelector((state) => state.contact);
  const {contacts: advisorContacts, list} = useSelector((state) => state.client);

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show);

  const selectedClient = (isAdvisor ? list : members).find((item) => item.show) || '';

  const contactList = useMemo(() => {
    let data = isAdvisor
      ? [
          {...selectedClient, contactType: 'individual'},
          ...advisorContacts.filter((item) => item.ownerId === selectedClient?.id)
        ]
      : memberSelected.length === 1 && memberSelected?.id !== user.userId
      ? [
          {
            ...contacts.find((contact) => contact.userId === selectedClient?.id),
            contactType: 'individual'
          },
          ...advisorContacts.filter((item) => item.ownerId === selectedClient?.id)
        ]
      : [
          {
            id: 'self',
            userId: 'self',
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            contactType: 'individual'
          },
          ...contacts
        ];

    const advisorContactType = ['individual', 'family'];

    return data
      .filter((item) => advisorContactType.includes(item.contactType))
      .map((item) => ({...item, userId: item.userId || item.id}));
  }, [contacts, user, isAdvisor, advisorContacts, selectedClient, memberSelected]);

  return (
    <Autocomplete
      multiple={multiple}
      fullWidth
      loading={cLoading}
      options={contactList}
      getOptionLabel={(option) => getFullName(option, true)}
      value={
        multiple
          ? contactList.filter((item) => value.includes(item.id))
          : contactList.find((item) => item.id === value) || ''
      }
      onChange={(e, val) => handleChange(val)}
      renderOption={(props, option, {selected}) => {
        return (
          <li {...props} key={option.id}>
            <Checkbox checked={selected} />
            {getFullName(option, true)}
          </li>
        );
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default ContactOrgSelect;
