import {capitalize} from 'lodash';

function getFirstCharacter(name) {
  return capitalize(name && name.charAt(0));
}

export function getFullName(profile, unique = false) {
  if (!profile) return '';

  const fName = [profile?.firstName, profile?.lastName].filter((item) => !!item).join(' ');
  const fNameWithEmail = [fName, profile?.email].filter((item) => !!item).join(' - ');

  return unique ? fNameWithEmail : fName;
}

export default function createAvatar(name) {
  return {
    name: getFirstCharacter(name?.firstName ?? '') + getFirstCharacter(name?.lastName ?? ''),
  };
}
