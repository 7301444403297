import {useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
// mui
import {Box, Dialog, DialogContent, DialogContentText, DialogTitle, Stack} from '@mui/material';
// api
import {getCarriers} from 'src/api/canopy';
// components
import CanopyLogin from './CanopyLogin';
import CarrierSelector from './CarrierSelector';
// ----------------------------------------------------------------------

const CanopyModal = ({isOpen, handleReset, setPullInfo}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  //----------------------------------

  // selected carrier info
  const [provider, setProvider] = useState(null);

  // handle provider from list
  const handleSetProvider = (p) => {
    setProvider(p);
  };

  // close all
  const handleCloseAll = () => {
    handleReset();
    setProvider(null);
  };

  // ---------------------------------------------------

  // get providers list
  useEffect(() => {
    const getProvidersList = async () => {
      try {
        setLoading(true);

        const {data} = await getCarriers();
        if (!data) {
          enqueueSnackbar('Cannopy providers fetch error.', {variant: 'error'});
        }
        setProviders(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        enqueueSnackbar('Cannopy providers fetch error.', {variant: 'error'});
      }
    };

    getProvidersList();
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog open={isOpen} onClose={handleCloseAll}>
      <DialogTitle variant="h5">Carrier Connect</DialogTitle>
      <DialogContent>
        <DialogContentText style={{maxWidth: 250}}>
          {!provider ? (
            'Select insurance provider you want to add.'
          ) : (
            <Box component={'span'}>
              Enter your login credentials for <strong>{provider?.name}</strong> service.
            </Box>
          )}
        </DialogContentText>
      </DialogContent>
      {!provider ? (
        <CarrierSelector
          loading={loading}
          providers={providers}
          handleSetProvider={handleSetProvider}
          handleClose={handleCloseAll}
        />
      ) : (
        <Stack
          sx={{
            minHeight: 200,
            px: 4
          }}
        >
          <CanopyLogin
            config={provider}
            handleSetProvider={handleSetProvider}
            setPullInfo={(token) => setPullInfo({token, provider})}
          />
        </Stack>
      )}
    </Dialog>
  );
};

export default CanopyModal;
