import React from 'react';
// utils
import {CRIMES, HAZARD_FEATURES} from 'src/constants/dropdown';
// mui
import {Stack, Typography, IconButton, Grid, Collapse, Slider} from '@mui/material';
import {styled} from '@mui/material/styles';
// mui icons
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function HazardDataDisplay({hazard_data, handleRemoveHazardData, display_type}) {
  // expand
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // expand more
  const ExpandMore = styled((props) => {
    const {expand, ...other} = props;

    return <IconButton {...other} />;
  })(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  // display function
  const display = (type, value, unit) => {
    return value === undefined ? (
      ''
    ) : (
      <Stack direction={'row'}>
        <Typography sx={{minWidth: '75px', fontWeight: 'bold', color: '#919EAB'}} variant="body2">
          {type}:&nbsp;
        </Typography>
        <Typography variant="body2">
          {value === null || value === ''
            ? 'No significant risk'
            : type === 'Distance' || type === 'Duration'
            ? `${value} ${unit ?? ''}`
            : value}
        </Typography>
      </Stack>
    );
  };

  // display crime function
  const displayCrime = (value) => {
    return value === undefined ? (
      ''
    ) : (
      <Stack>
        {display('Score', value?.score)}
        {display('Text', value?.text)}

        <Stack direction={'row'} sx={{marginTop: '16px'}}>
          <Typography
            variant="subtitle2"
            sx={{marginBottom: '0px', marginTop: '8px', cursor: 'default', color: '#919EAB'}}
          >
            All Crimes
          </Typography>
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded}>
            <ExpandMoreIcon />
          </ExpandMore>
        </Stack>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Stack>
            {CRIMES.map((crime, index) => (
              <Stack key={index}>
                <Typography variant="subtitle2" sx={{marginBottom: '0px', marginTop: '8px', cursor: 'default'}}>
                  {crime.label}
                </Typography>
                {display('Score', hazard_data[crime.value]?.score)}
                {display('Text', hazard_data[crime.value]?.text)}
              </Stack>
            ))}
          </Stack>
        </Collapse>
      </Stack>
    );
  };

  // display scale function
  const displayScale = (value) => {
    return value === undefined ? (
      ''
    ) : (
      <Stack direction={'row'} mb={1.5} mx={3}>
        {value !== null && value !== '' && (
          <Slider
            aria-label="Always visible"
            defaultValue={0}
            step={1}
            marks={[
              {
                value: 0,
                label: 'F'
              },
              {
                value: 25,
                label: 'D'
              },
              {
                value: 50,
                label: 'C'
              },
              {
                value: 75,
                label: 'B'
              },
              {
                value: 100,
                label: 'A'
              }
            ]}
            value={
              value === 'F' ? 0 : value === 'D' ? 25 : value === 'C' ? 50 : value === 'B' ? 75 : value === 'A' ? 100 : 0
            }
            sx={{marginRight: '10px'}}
            disabled
          />
        )}
      </Stack>
    );
  };

  return (
    <Stack spacing={2}>
      <Stack direction={'row'} sx={{display: 'flex', alignItems: 'center'}}>
        {display_type !== 'Detail' && <Typography variant="h6">Hazard Data</Typography>}

        {Object.keys(hazard_data).length !== 0 && display_type !== 'Detail' && (
          <IconButton
            onClick={() => handleRemoveHazardData()}
            sx={{
              height: 32,
              width: 32,
              marginLeft: 5
            }}
          >
            <DeleteIcon color="error" sx={{height: 20}} />
          </IconButton>
        )}
      </Stack>
      {HAZARD_FEATURES.map((hazard_type, index) => (
        <Stack key={index}>
          <Typography sx={{fontWeight: 'bold', marginBottom: '10px', color: '#919EAB'}} variant="body2">
            {hazard_type.label}
          </Typography>
          <Grid container>
            {hazard_type.values.map((feat, i) => (
              <Grid item xs={6} md={display_type === 'Detail' ? 6 : 4} key={i}>
                <Stack
                  sx={{
                    padding: '5px',
                    height: '100%'
                  }}
                >
                  <Stack
                    sx={{
                      padding: '12px',
                      borderRadius: '10px',
                      boxShadow: 3,
                      height: '100%'
                    }}
                  >
                    <Typography variant="subtitle2" sx={{marginBottom: '8px', cursor: 'default'}}>
                      {feat.label}
                    </Typography>
                    {feat.value === 'crime' ? (
                      displayCrime(hazard_data[feat.value])
                    ) : (
                      <>
                        {displayScale(hazard_data[feat.value]?.score)}
                        {display('Scale', hazard_data[feat.value]?.scale)}
                        {display('Class', hazard_data[feat.value]?.class)}
                        {display('Score', hazard_data[feat.value]?.score)}
                        {display('Text', hazard_data[feat.value]?.text)}
                        {display('Distance', hazard_data[feat.value]?.distance, hazard_data[feat.value]?.distance_unit)}
                        {display('Duration', hazard_data[feat.value]?.duration, hazard_data[feat.value]?.duration_unit)}
                        {display('Name', hazard_data[feat.value]?.name)}
                        {display('Site', hazard_data[feat.value]?.sitename)}
                        {display('Total', hazard_data[feat.value]?.total)}
                      </>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      ))}
    </Stack>
  );
}
