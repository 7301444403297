import {isEmpty} from 'lodash';
// -------------------------------------------
export const getDataEntries = (response) => {
  let entries = {};
  let topChart = {};
  for (const property in response) {
    entries[property] = response[property].data;
    topChart[property] = response[property].topChartData;
  }

  return [entries, topChart];
};

export const filterDataByClient = (clientsData, list) => {
  const showClients = list.filter((client) => client.show).map((item) => item.id);

  let Obj = {};
  Object.keys(clientsData).forEach((key) => {
    Obj[key] = clientsData[key].filter((item) => showClients.includes(item.user));
  });

  return Obj;
};

export const filterChartByClient = (chartData, list) => {
  const showClients = list.filter((client) => client.show).map((item) => item.id);
  const aggregateChartData = (result, item) => {
    let newResult = {...result};
    if (isEmpty(result)) {
      return item;
    }

    for (const property in item) {
      if (typeof item[property] === 'number') {
        newResult[property] += item[property];
      } else if (typeof item[property] === 'object' && !Array.isArray(item[property])) {
        let newProperty = {...newResult[property]};
        for (const subProperty in item[property]) {
          newProperty[subProperty] =
            parseInt(newProperty[subProperty] || 0, 10) + parseInt(item[property][subProperty] || 0, 10);
        }
        newResult[property] = newProperty;
      } else if (typeof item[property] === 'object') {
        let data = [...newResult[property]];
        item[property].forEach((subItem) => {
          let target = data.find((s) => s.year === subItem.year);
          if (!!target) {
            let newTarget = {...target};
            for (const k in target) {
              if (k !== 'year') newTarget[k] = parseInt(newTarget[k] || 0, 10) + parseInt(subItem[k] || 0, 10);
            }
            data = data.map((subData) => (subData.year === subItem.year ? newTarget : subData));
          } else {
            data.push(subItem);
          }
        });

        newResult[property] = [...data];
      }
    }

    return newResult;
  };

  let Obj = {};
  Object.keys(chartData).forEach((key) => {
    Obj[key] = chartData[key].reduce(
      (result, item) => (showClients.includes(item.userId) ? aggregateChartData(result, item) : result),
      {}
    );
  });

  return Obj;
};

const mergeSameKey = (arr1, arr2) => {
  let result = Array.isArray(arr1) ? [...arr1] : [];

  try {
    arr2.forEach((item) => {
      const targetItem = result.find((rItem) => rItem.year === item.year);
      if (targetItem) {
        targetItem.value = targetItem.value + item.value;
      } else {
        result = [...result, item];
      }
    });

    return result;
  } catch (err) {
    console.log({err});
  }

  return [];
};

export const filterDCByClient = (chartData, list) => {
  const showClients = list.filter((client) => client.show).map((item) => item.id);

  const aggregateChartData = (result, item) => {
    let newResult = {...result};
    if (isEmpty(result)) {
      return item;
    }

    for (const property in item) {
      if (typeof item[property] === 'number') {
        // property: netWorth, liabilityCoverage, ...
        newResult[property] += item[property];
      } else if (typeof item[property] === 'object' && !Array.isArray(item[property])) {
        let newProperty = {...newResult[property]};
        for (const subProperty in item[property]) {
          // subProperty: realEstate, collection, ...
          if (typeof item[property][subProperty] === 'object' && item[property][subProperty] !== null) {
            // InsurancePremiumHistory
            newProperty[subProperty] = mergeSameKey(newProperty[subProperty], item[property][subProperty]);
          } else {
            newProperty[subProperty] =
              parseInt(newProperty[subProperty] || 0, 10) + parseInt(item[property][subProperty] || 0, 10);
          }
        }
        newResult[property] = newProperty;
      }
    }

    return newResult;
  };

  const result = chartData.reduce(
    (result, item) => (showClients.includes(item.userId) ? aggregateChartData(result, item) : result),
    {}
  );

  return result;
};
